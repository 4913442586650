.heading h4 {
  font-size: 24px;
  text-align: center;
  margin: 0.5vw;
  margin-bottom: 1vw;
}
#heading-label {
  font-weight: bold;
}

.filter-labels {
  text-align: center;
  margin: 0px;
  font-size: 17px;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: black;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover {
  cursor: default;
  background-color: white;
}

.react-calendar__tile:enabled:focus {
  cursor: default;
  background-color: white;
}

/** 
 * This selector targets _all_ buttons in the app. Temporarily removing until
 * we can verify its removal doesn't have a major adverse effect on the app.
 */
/* button:disabled {
  color: rgb(170, 170, 170);
} */

.react-calendar__month-view__days__day--active {
  color: black;
}

.react-calendar__tile--active {
  color: black;
}

.react-calendar__tile {
  color: black;
}

.react-calendar__navigation {
  height: 30px;
}

.react-calendar__year-view .react-calendar__tile {
  padding: 0.9em 0.5em;
}

.react-calendar__decade-view .react-calendar__tile {
  padding: 0.8em 0.5em;
}
.react-calendar__century-view .react-calendar__tile {
  padding: 0.8em 0.5em;
}

.react-calendar__month-view .react-calendar__tile {
  padding: 0.3em 0.5em;
}
