.checkout-panel-container {
  position: fixed;
  left: 260px;
  bottom: 24px;

  height: 46px;
  width: calc(100vw - 270px);

  display: flex;
  justify-content: center;
}

.checkout-panel-container .checkout-panel {
  background: #ffffffcc;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid var(--light-green);

  height: 100%;
  width: 1200px;
  min-width: 600px;
  max-width: 1200px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.checkout-panel .shopping-cart-container {
  margin: 5px 0px;
  padding-left: 10px;
  padding-right: 10px;
  border-right: 1.5px solid black;
}

.checkout-panel .changed-status ~ .changed-status {
  border-left: 1.5px solid black;
  line-height: 14px;
}

.checkout-panel .changed-status {
  padding: 0px 15px;
  font-size: 14px;
  font-weight: 500;
}

.checkout-panel .action-buttons {
  display: flex;
  align-items: center;

  margin-left: 16px;
}

.checkout-panel .action-buttons button:not(:last-child) {
  margin-right: 16px;
}

.checkout-panel .big-number {
  font-size: 18px;
  padding: 0px 4px;
}

.location-activation-confirmation-modal .modal-header {
  border-bottom: none;
  padding: 40px 40px 0px 40px;
}

.location-activation-confirmation-modal .modal-header .modal-title {
  font-size: 23px;
  font-weight: 600;
  margin-bottom: 22px;
  color: var(--font-black);
}

.location-activation-confirmation-modal .profitrover-modal-main {
  padding: 0px 40px 0px 40px;
  margin-bottom: 23px;
  font-size: 13px;
  color: var(--font-black);
}

.location-activation-confirmation-modal .profitrover-modal-footer {
  background-color: var(--background-gray);
  padding: 20px 40px;
  display: flex;
  justify-content: flex-end;
}

.location-activation-confirmation-modal .modal-footer button {
  padding-top: 6px;
}
