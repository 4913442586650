@media screen and (max-width: 1600px) {
  .select-container > span > p {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .select-container > span {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 900px) {
  .select-container {
    display: flex;
    flex-flow: row wrap;
  }
  .select-container > span {
    flex: 1 1 0;
    min-width: 50%;
  }
}

@media screen and (min-width: 900px) {
  .select-container {
    display: flex;
    flex-flow: row wrap;
    max-width: 1200px;
  }
  .select-container > span {
    display: inline;
    flex: 0 0 25%;
    min-width: 25%;

    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}
