.data-center {
  text-align: center;
  padding-top: 80px;
  margin: 0% 2%;
}

.file-upload {
  text-align: center;
  margin-top: 1vw;
}

.file-upload > button:not(:last-child) {
  margin-right: 15px;
}

.file-upload-modal {
  margin-top: 15vw;
}
.file-upload-modal-header {
  text-align: center;
  margin-top: 1vw;
}

#file-upload-column {
  text-align: right;
  margin-left: 2vw;
}

.file-upload-modal-buttons {
  text-align: center;
  margin-bottom: 1vw;
  font-size: 16px;
}

.data-config-select-span {
  text-align: center;
  margin-left: 1vw;
}

.filters-main {
  font-size: 16px;
}

.dataset-upload-date {
  width: 180px;
  margin: 1vw;
  margin-top: 3px;
}

#datacenter-apply-filters {
  margin-top: 1.5vw;
  margin-left: 1vw;
  margin-bottom: 1.5vw;
}

.column-header {
  padding-top: 5px;
}

.column-header-center-align {
  text-align: center;
}

.fileNameSelect {
  margin: 0 0 10px;
  color: #555;
  background-color: #fff;
  border-color: #ccc;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
  height: 40px;
  font-size: 14px;
  padding: 6px 10px;
  box-sizing: border-box;
  outline: none;
  font-weight: 400;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  line-height: 1.3;
}

.datacenter-icon {
  cursor: pointer;
  margin: 0px 5px 0px 5px;
}

.datacenter-file-upload-button-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.datacenter-file-upload-button-bar button {
  margin: 12px 6px;
}

.bad-dataset-icon {
  color: var(--error);
}

.bad-dataset-tooltip {
  opacity: 1 !important;
}

.bad-dataset-tooltip .tooltip-inner {
  background-color: var(--background-gray);
  border: 1px solid var(--light-cool-gray);
  box-shadow: 0px 3px 6px #00000029;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  max-width: 100%;
  padding: 10px;
}

.bad-dataset-tooltip .arrow {
  opacity: 1;
}

.bad-dataset-tooltip[x-placement^='bottom'] .arrow:before,
.bad-dataset-tooltip .arrow:before {
  border-bottom-color: var(--light-cool-gray) !important;
}

.bad-dataset-tooltip .arrow::after {
  content: '';
  position: absolute;
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  transform: translateY(1px);
  border-color: transparent;
  border-style: solid;
  border-bottom-color: var(--background-gray);
}
