.subscription-invoices-header {
  text-align: left;
  font-size: 30px;
  letter-spacing: 0px;
  color: #3e3e3e;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 0.5rem;
}

.invoices-table {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: var(--large-container-box-shadow);
  border-radius: 4px;
  margin-top: 0.5rem;
}

table.invoices-table th {
  font-size: 18px;
  font-weight: normal;
  color: #3e3e3e;
  min-width: 250px;
  text-align: center;
  padding: 0.5em;
  border-bottom: 1px solid var(--light-cool-gray);
  border-spacing: 10px;
}

table.invoices-table th.payment-failed:last-of-type {
  text-align: right;
  padding-right: 25px;
}

table.invoices-table td {
  font-size: 14px;
  color: #3e3e3e;
  text-align: center;
  padding: 0.5em;
  border: none;
}

.back-to-subscription-link {
  text-decoration: underline;
  color: var(--light-blue);
  cursor: pointer;
}

.invoice-success-circle-icon {
  color: green;
  margin: 4px;
}

.invoice-success-text {
  font-size: 14px;
  color: #797979;
}

table.invoices-table .invoice-pending-icon {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  margin: 4px;
  border-width: 2px;
  border-color: #797979;
  color: #797979;
  padding: 0.1em 0.12em 0.1em;
}

.invoice-pending-text {
  font-size: 14px;
  color: #797979;
}

.invoice-failed-icon {
  color: #b3280f;
  margin: 4px;
}

.invoice-failed-text {
  font-size: 14px;
  color: #b3280f;
  margin-right: 12px;
}

.pay-invoice-top-level-div {
  display: flex;
  justify-content: flex-end;
  margin-right: 8px;
}

.invoice-action-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 30px;
}

.pay-invoice-button {
  display: flex;
}
