.password-requirements-list {
  list-style: none;
  padding: 0px;

  li {
    padding: 0px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;

    &.notSatisfied {
      opacity: 0.2;
    }

    &.satisfied {
      color: #5fad56;
    }
  }
}
