.user-management-container {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .modal-heading {
    font-weight: bold;
    text-align: center;
    font-size: 20px;
  }

  .footer-plus {
    font-weight: bold;
    background-color: #fff;
    cursor: pointer;
  }

  .event-div {
    margin-left: 7px;
  }

  .input {
    width: 100%;
  }

  .select {
    width: 100%;
    height: 30px;
    text-align: center;
  }

  .disabled {
    opacity: 0.5;
  }

  .icon {
    margin: 0 0.4rem;
    cursor: pointer;
  }

  .icon.disabled {
    cursor: not-allowed;
  }

  .field {
    min-height: 26px;
    width: 100%;
    color: var(--font-black);
    text-align: center;
  }

  .field.disabled {
    opacity: 1;
    color: var(--font-black);
  }

  .rt-td {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.modal-footer {
  border-top: none;
  padding-top: 0.5rem;
}
