.pace-tooltip-container {
  --tooltip-background-color: var(--medium-cool-gray);

  margin: 0;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;

  .pace-tooltip {
    min-width: 150px;
    padding: 0.25rem 0.5rem;
    text-align: center;
    border-radius: 0.25rem;
    background-color: var(--tooltip-background-color);
    color: white;
  }
}
