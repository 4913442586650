@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.point-of-sale-setup {
  .instructions-line-1 {
    font-size: 18px;
    font-weight: 500;
  }

  .instructions-line-2 {
    font-size: 14px;
  }

  .wide {
    @include media-breakpoint-up(xl) {
      // Allow a slighty wider max width than what Bootstrap normally allows
      max-width: 1250px;
    }
  }
}

.point-of-sale-form {
  .missing-class-instructions {
    margin: 9px 0;
  }

  .dont-see-one-text {
    font-size: 14px;
    font-style: italic;
  }

  .contact-us-text {
    font-size: 14px;
    text-decoration: underline;
    color: var(--light-blue);
    margin-left: 5px;
  }

  .actions {
    display: flex;

    button:not(:last-child) {
      margin-right: 20px;
    }
  }

  .other-pos-name {
    width: 450px;
    padding: 0rem 0.5rem;

    border: 1px solid var(--light-cool-gray);
    border-radius: 4px;
    outline: none;

    &:focus {
      box-shadow: 0px 1px 6px var(--light-green-shadow);
    }

    &::placeholder {
      color: var(--light-cool-gray);
    }

    background: white;
    font-size: 1rem;
    height: 2.25rem;
  }

  .item-classes-section.disabled {
    opacity: 0.45;
    cursor: not-allowed;

    td input {
      pointer-events: none;
    }
  }
}

.data-source-selections {
  display: flex;
  flex-wrap: wrap;

  row-gap: 1.5rem;
  column-gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 4rem;
}

.data-source-tile {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  padding: 0.5rem 1.5rem;
  width: 180px;
  height: 80px;

  cursor: pointer;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: var(--large-container-box-shadow);

  transition-property: transform, box-shadow;
  transition-duration: 0.33s;

  &:hover:not(.checked):not(.disabled) {
    box-shadow: 3px 3px 6px #00000020;
    transform: scale(1.075);
  }

  &:focus-within:not(.checked):not(.disabled) {
    transform: scale(1.075);
  }

  &.checked {
    border: 3px solid var(--light-blue);
    box-shadow: 3px 3px 6px #00000029, 3px 3px 6px inset #00000029;
  }

  input[type='radio'] {
    // Hides browser-included "button" element without impacting function
    opacity: 0;
    position: absolute;
  }

  &.disabled {
    cursor: not-allowed;

    svg,
    img {
      cursor: not-allowed;
    }

    svg:first-of-type,
    img:first-of-type {
      // first-of-type prevents this opacity from applying to the "already configured" checkmark icon
      opacity: 0.6;
    }
  }

  .manual-source {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    font-size: 2rem;
  }

  svg,
  img {
    height: 3rem;
    width: 12rem;
    cursor: pointer;
  }
}

.item-classes-pos-table-container {
  width: 1000px;
  background: white;
  padding: 15px;
  margin-top: 20px;

  .item-classes-pos-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    background: white;

    td,
    th {
      border: 1px solid var(--light-cool-gray);
      padding: 10px;
      text-align: center;
      vertical-align: middle;
    }

    th {
      font-size: 14px;
      font-weight: 500;
    }

    tr:first-child th {
      border-top: 0;
    }

    tr:last-child td {
      border-bottom: 0;
    }

    tr {
      td:first-child,
      th:first-child {
        border-left: 0;
      }
    }

    tr {
      td:last-child,
      th:last-child {
        border-right: 0;
      }
    }

    input[type='checkbox'] {
      height: 24px;
      width: 24px;
      accent-color: var(--light-blue);
    }
  }
}
