.ds-config-sample-table-container {
  display: inline-block;
  max-width: 100%;
  overflow: auto;
  box-shadow: 0px 5px 10px #00000029;
  border-radius: 6px;
}

/* Allows us to disable onClick events and hover styling by adding the "disable-events" className */
.ds-config-sample-table-container.disable-events * {
  pointer-events: none;
  cursor: default;
}

table.ds-config-sample-table th {
  transition: background-color 0.25s ease;

  min-width: 80px;
  font-weight: 700;
  text-align: center;
  background: #F5F6FA 0% 0% no-repeat padding-box;

  padding: 0.5em;
  border: none;

  cursor: pointer;
}

table.ds-config-sample-table th.hovered {
  transition: background-color 0.25s ease;
  background-color: #45454515;
}

table.ds-config-sample-table td {
  transition: background-color 0.25s ease;

  text-align: center;
  padding: 0.5em;
  border: none;

  cursor: pointer;
}

table.ds-config-sample-table td.hovered {
  transition: background-color 0.25s ease;
  background-color: #45454515;
}

table.ds-config-sample-table td > div {
  outline: none;
}