:root {
  --large-container-box-shadow: 0px 3px 6px #00000029;
  --small-container-box-shadow: 0px 2px 4px #00000029;

  --focus-gold-shadow: 0px 1px 6px #f6c50cd8;
}

/**
 * These selectors can be useful for changing the color of text according to our theme.
 */

.warning {
  color: var(--error) !important;
}

.info {
  color: var(--light-blue) !important;
}

button.link-like {
  color: var(--light-blue);
  outline: none;

  &:hover {
    text-decoration: underline;
  }
}
