.small-price-tag-icon,
.big-price-tag-icon {
  position: relative;

  display: flex;
  align-items: center;

  --price-tag-outline-color: #b1b3b3d0;
}

.big-price-tag-tip,
.small-price-tag-tip {
  fill: white;
  overflow: visible;
}

.small-price-tag-icon .small-price-tag-content,
.big-price-tag-icon .big-price-tag-content {
  background: white;

  position: relative;
  left: -0.6px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.big-price-tag-icon .big-price-tag-content {
  font-size: 14px;

  min-width: 70px;

  padding: 6px 4px;
  padding-right: 6px;

  border: 0.75px solid var(--price-tag-outline-color);

  border-left-width: 0px;
  border-radius: 0px 3px 3px 0px;
}

.big-price-tag-content.large-symbol {
  min-width: 100px;
}

.small-price-tag-icon .small-price-tag-content {
  font-size: 12px;

  padding: 1px 3px;

  border: 0.8px solid var(--price-tag-outline-color);
  border-left-width: 0px;
  border-radius: 0px 3px 3px 0px;

  white-space: nowrap;
}

.small-price-tag-icon .small-price-tag-content .sense-arrow {
  position: relative;
  margin-right: 2px;
}

.big-price-tag-icon .big-price-tag-content .sense-arrow {
  position: relative;
  margin-left: 1px;
  margin-right: 4px;
}

.small-price-tag-icon .small-price-tag-content .sense-arrow.up {
  bottom: 0px;
}

.small-price-tag-icon .small-price-tag-content .sense-arrow.down {
  bottom: -0.5px;
}
