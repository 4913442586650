.HeatMap100 {
  background-color: rgb(0, 175, 79);
}
.HeatMap99 {
  background-color: rgb(0, 175, 79, 0.99);
}
.HeatMap98 {
  background-color: rgb(0, 175, 79, 0.98);
}
.HeatMap97 {
  background-color: rgb(0, 175, 79, 0.97);
}
.HeatMap96 {
  background-color: rgb(0, 175, 79, 0.96);
}
.HeatMap95 {
  background-color: rgb(0, 175, 79, 0.95);
}
.HeatMap94 {
  background-color: rgb(0, 175, 79, 0.94);
}
.HeatMap93 {
  background-color: rgb(0, 175, 79, 0.93);
}
.HeatMap92 {
  background-color: rgb(0, 175, 79, 0.92);
}
.HeatMap91 {
  background-color: rgb(0, 175, 79, 0.91);
}
.HeatMap90 {
  background-color: rgb(0, 175, 79, 0.9);
}
.HeatMap89 {
  background-color: rgb(0, 175, 79, 0.89);
}
.HeatMap88 {
  background-color: rgb(0, 175, 79, 0.88);
}
.HeatMap87 {
  background-color: rgb(0, 175, 79, 0.87);
}
.HeatMap86 {
  background-color: rgb(0, 175, 79, 0.86);
}
.HeatMap85 {
  background-color: rgb(0, 175, 79, 0.85);
}
.HeatMap84 {
  background-color: rgb(0, 175, 79, 0.84);
}
.HeatMap83 {
  background-color: rgb(0, 175, 79, 0.83);
}
.HeatMap82 {
  background-color: rgb(0, 175, 79, 0.82);
}
.HeatMap81 {
  background-color: rgb(0, 175, 79, 0.81);
}
.HeatMap80 {
  background-color: rgb(0, 175, 79, 0.8);
}
.HeatMap79 {
  background-color: rgb(0, 175, 79, 0.79);
}
.HeatMap78 {
  background-color: rgb(0, 175, 79, 0.78);
}
.HeatMap77 {
  background-color: rgb(0, 175, 79, 0.77);
}
.HeatMap76 {
  background-color: rgb(0, 175, 79, 0.76);
}
.HeatMap75 {
  background-color: rgb(0, 175, 79, 0.75);
}
.HeatMap74 {
  background-color: rgb(0, 175, 79, 0.74);
}
.HeatMap73 {
  background-color: rgb(0, 175, 79, 0.73);
}
.HeatMap72 {
  background-color: rgb(0, 175, 79, 0.72);
}
.HeatMap71 {
  background-color: rgb(0, 175, 79, 0.71);
}
.HeatMap70 {
  background-color: rgb(0, 175, 79, 0.7);
}
.HeatMap69 {
  background-color: rgb(0, 175, 79, 0.69);
}
.HeatMap68 {
  background-color: rgb(0, 175, 79, 0.68);
}
.HeatMap67 {
  background-color: rgb(0, 175, 79, 0.67);
}
.HeatMap66 {
  background-color: rgb(0, 175, 79, 0.66);
}
.HeatMap65 {
  background-color: rgb(0, 175, 79, 0.65);
}
.HeatMap64 {
  background-color: rgb(0, 175, 79, 0.64);
}
.HeatMap63 {
  background-color: rgb(0, 175, 79, 0.63);
}
.HeatMap62 {
  background-color: rgb(0, 175, 79, 0.62);
}
.HeatMap61 {
  background-color: rgb(0, 175, 79, 0.61);
}
.HeatMap60 {
  background-color: rgb(0, 175, 79, 0.6);
}
.HeatMap59 {
  background-color: rgb(0, 175, 79, 0.59);
}
.HeatMap58 {
  background-color: rgb(0, 175, 79, 0.58);
}
.HeatMap57 {
  background-color: rgb(0, 175, 79, 0.57);
}
.HeatMap56 {
  background-color: rgb(0, 175, 79, 0.56);
}
.HeatMap55 {
  background-color: rgb(0, 175, 79, 0.55);
}
.HeatMap55 {
  background-color: rgb(0, 175, 79, 0.55);
}
.HeatMap54 {
  background-color: rgb(0, 175, 79, 0.54);
}
.HeatMap53 {
  background-color: rgb(0, 175, 79, 0.53);
}
.HeatMap52 {
  background-color: rgb(0, 175, 79, 0.52);
}
.HeatMap51 {
  background-color: rgb(0, 175, 79, 0.51);
}
.HeatMap50 {
  background-color: rgb(0, 175, 79, 0.5);
}
.HeatMap49 {
  background-color: rgb(0, 175, 79, 0.49);
}
.HeatMap48 {
  background-color: rgb(0, 175, 79, 0.48);
}
.HeatMap47 {
  background-color: rgb(0, 175, 79, 0.47);
}
.HeatMap46 {
  background-color: rgb(0, 175, 79, 0.46);
}
.HeatMap45 {
  background-color: rgb(0, 175, 79, 0.45);
}
.HeatMap44 {
  background-color: rgb(0, 175, 79, 0.44);
}
.HeatMap43 {
  background-color: rgb(0, 175, 79, 0.43);
}
.HeatMap42 {
  background-color: rgb(0, 175, 79, 0.42);
}
.HeatMap41 {
  background-color: rgb(0, 175, 79, 0.41);
}
.HeatMap40 {
  background-color: rgb(0, 175, 79, 0.4);
}
.HeatMap39 {
  background-color: rgb(0, 175, 79, 0.39);
}
.HeatMap38 {
  background-color: rgb(0, 175, 79, 0.38);
}
.HeatMap37 {
  background-color: rgb(0, 175, 79, 0.37);
}
.HeatMap36 {
  background-color: rgb(0, 175, 79, 0.36);
}
.HeatMap35 {
  background-color: rgb(0, 175, 79, 0.35);
}
.HeatMap34 {
  background-color: rgb(0, 175, 79, 0.34);
}
.HeatMap33 {
  background-color: rgb(0, 175, 79, 0.33);
}
.HeatMap32 {
  background-color: rgb(0, 175, 79, 0.32);
}
.HeatMap31 {
  background-color: rgb(0, 175, 79, 0.31);
}
.HeatMap30 {
  background-color: rgb(0, 175, 79, 0.3);
}
.HeatMap29 {
  background-color: rgb(0, 175, 79, 0.29);
}
.HeatMap28 {
  background-color: rgb(0, 175, 79, 0.28);
}
.HeatMap27 {
  background-color: rgb(0, 175, 79, 0.27);
}
.HeatMap26 {
  background-color: rgb(0, 175, 79, 0.26);
}
.HeatMap25 {
  background-color: rgb(0, 175, 79, 0.25);
}
.HeatMap24 {
  background-color: rgb(0, 175, 79, 0.24);
}
.HeatMap23 {
  background-color: rgb(0, 175, 79, 0.23);
}
.HeatMap22 {
  background-color: rgb(0, 175, 79, 0.22);
}
.HeatMap21 {
  background-color: rgb(0, 175, 79, 0.21);
}
.HeatMap20 {
  background-color: rgb(0, 175, 79, 0.2);
}
.HeatMap19 {
  background-color: rgb(0, 175, 79, 0.19);
}
.HeatMap18 {
  background-color: rgb(0, 175, 79, 0.18);
}
.HeatMap17 {
  background-color: rgb(0, 175, 79, 0.17);
}
.HeatMap16 {
  background-color: rgb(0, 175, 79, 0.16);
}
.HeatMap15 {
  background-color: rgb(0, 175, 79, 0.15);
}
.HeatMap14 {
  background-color: rgb(0, 175, 79, 0.14);
}
.HeatMap13 {
  background-color: rgb(0, 175, 79, 0.13);
}
.HeatMap12 {
  background-color: rgb(0, 175, 79, 0.12);
}
.HeatMap11 {
  background-color: rgb(0, 175, 79, 0.11);
}
.HeatMap10 {
  background-color: rgb(0, 175, 79, 0.1);
}
.HeatMap09 {
  background-color: rgb(0, 175, 79, 0.09);
}
.HeatMap08 {
  background-color: rgb(0, 175, 79, 0.08);
}
.HeatMap07 {
  background-color: rgb(0, 175, 79, 0.07);
}
.HeatMap06 {
  background-color: rgb(0, 175, 79, 0.06);
}
.HeatMap05 {
  background-color: rgb(0, 175, 79, 0.05);
}
.HeatMap04 {
  background-color: rgb(0, 175, 79, 0.04);
}
.HeatMap03 {
  background-color: rgb(0, 175, 79, 0.03);
}
.HeatMap02 {
  background-color: rgb(0, 175, 79, 0.02);
}
.HeatMap01 {
  background-color: rgb(0, 175, 79, 0.01);
}
