.formula-suggestion {
  text-decoration: underline;
  color: #397fe5;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 14px;
}

.formula-container {
  --left-radius: 2px;
  --right-radius: 4px;
}

.formula-container {
  display: inline-flex;
  box-shadow: 0px 2px 8px #0000004e;
  border-radius: var(--left-radius) var(--right-radius) var(--right-radius) var(--left-radius);
  margin-bottom: 15px;
}

.formula-container > * {
  height: 36px;
  border: none;
  margin: 0;
}

.formula-container > input {
  flex-grow: 1;
  padding: 0px 8px;
  border-top-left-radius: var(--left-radius);
  border-bottom-left-radius: var(--left-radius);
}

.formula-container input:placeholder-shown {
  font-style: italic;
  font-size: 14px;
  color: #3e3e3e;
  opacity: 0.4;
}

.formula-container > button {
  width: 76px;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
}

.formula-container > button:first-of-type {
  margin-left: auto;
}

.formula-container > button:last-child {
  border-top-right-radius: var(--right-radius);
  border-bottom-right-radius: var(--right-radius);
}
