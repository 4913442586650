.anomaly-comparisons {
  max-height: 228px;
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  min-width: 100%;
  width: 0px;

  .heading {
    margin: 0.75rem 0px;
    margin-bottom: 0.5rem;
    color: var(--font-gray);
    font-weight: 500;
  }

  .checkbox-rows {
    overflow-y: auto;
    flex-grow: 1;
    max-height: 200px;
    padding-bottom: 10px;

    // Prevent clipping of "focus outline" on checkboxes
    padding-left: 6px;
    margin-left: -6px;
  }

  .checkbox-legend-row {
    display: flex;
    outline: none;

    &:not(:last-of-type) {
      margin-bottom: 6px;
    }

    input[type='checkbox'] {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }

    label {
      display: none;
    }

    .legend-box {
      cursor: pointer;

      margin: auto 8px;
      height: 8px;
      min-height: 8px;
      min-width: 14px;
      width: 14px;
    }

    .comparison-label {
      cursor: pointer;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
