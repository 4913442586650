.notification-center {
  width: min(50vw, 675px);
  height: calc(100vh - 80px);

  display: flex;
  flex-direction: column;

  position: fixed;
  top: 70px;

  background: white;
  overflow: auto;

  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #d0d0d0;
  border-radius: 4px;

  z-index: 1031;
}

.notification-center * {
  color: var(--font-black);
  --header-height: 55px;
}

.notification-center:not(.open) {
  right: calc(-1 * min(50vw, 675px));
  opacity: 0;
  transition: right 0.25s ease-out, opacity 0.1s ease-in-out;
}

.notification-center.open {
  right: 10px;
  opacity: 1;
  transition: right 0.25s ease-out, opacity 0.2s ease-in;
  overflow-y: hidden;
}

.notification-center .header {
  height: var(--header-height);
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 20px 15px 0 10px;
  margin-left: 5px;
}

.notification-center .header .unread-header-toggle {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 5px;
}

.notification-center .header .unread-header-toggle h2 {
  font-size: 12px;
  margin: 0px;
  margin-right: 5px;
}

.unread-header-toggle .react-toggle-track {
  border: 1px solid #ffffff;
  background-color: var(--background-gray);
  box-shadow: 0px 1px 2px #00000040;
}

.unread-header-toggle .react-toggle--checked .react-toggle-track {
  background-color: var(--light-green-hover);
}

/* The next two style classes are related to the circle on react-toggle */
.unread-header-toggle .react-toggle .react-toggle-thumb {
  border-color: #ffffff;
  background-color: var(--light-cool-gray);
}

.unread-header-toggle .react-toggle--checked .react-toggle-thumb {
  border-color: #ffffff;
  background-color: var(--light-green);
}

/* Toggle disabled, on hover effect */
.unread-header-toggle .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--background-gray);
}

/* Toggle enabled, on hover effect */
.unread-header-toggle .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--light-green-hover);
}

/* Removes box-shadow from circle slider in toggle */
.unread-header-toggle .react-toggle--focus .react-toggle-thumb {
  box-shadow: unset;
}

.unread-header-toggle .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: unset;
}

.notification-center .header,
.notification-center .nav {
  user-select: none;
}

.notification-center .notifications-area {
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  max-height: calc(100% - var(--header-height));
}

.notification-center .notifications-area .nc-tab {
  border: none;
  color: lightgray;
  padding: 0px;
  font-size: 16px;
  font-weight: 600;
}

.notification-center .notifications-area .nc-tab:first-of-type {
  margin-right: 30px;
}

.notification-center .notifications-area .nc-tab.active {
  border-bottom: 2px solid black;
  color: black;
}

.notification-center .notifications-area nav.nav.nav-tabs {
  margin: 0px 15px;
  height: 26px;
}
.notification-center .notifications-area .tab-content {
  flex: 1;
  flex-grow: 1;
  overflow-y: auto;
}

.notification-center .notifications-list-container {
  padding: 20px 0px;
}

.notification-center .today-notifications-container {
  margin-bottom: 25px;
}

.notification-center .today-notifications-title {
  display: flex;
  align-content: center;
}

.notification-center .older-notifications-title {
  display: flex;
  align-content: center;
}

.notification-center .mark-read-text {
  margin-left: auto;
  color: var(--medium-cool-gray);
  font: normal normal 300 14px/16px URWDIN;
  cursor: pointer;
}

.notification-center .mark-read-text:hover {
  text-decoration: underline;
  color: var(--font-black);
}

.notification-center .today-notifications-title,
.notification-center .older-notifications-title {
  font-size: 14px;
  font-weight: 600;
  padding: 0px 15px;
}

.notification-center .notification {
  display: block;
  background-color: #f2fbfe;
  padding-top: 10px;
  padding-bottom: 10px;

  /* Leaves a gap between notifications that will not have (cursor: pointer) */
  margin-bottom: 10px;
}

.notification-center .notification.clickable {
  cursor: pointer;
}

.notification-center .notification.clickable .notification-body:hover {
  color: var(--light-blue);
  text-decoration: underline;
}

.notification-center .notification.read * {
  color: #797979;
}

.notification-center .notification.read {
  background-color: unset;
}

.notification-center .notification-details {
  display: flex;
  width: 35%;
  justify-content: flex-end;
  margin-right: 15px;
}

.notification-center .notification-timestamp {
  font-size: 14px;
  font-weight: 400;
  text-align: right;
  color: #797979;
  margin-right: 10px;
}

.notification-center .notification-read-status-container {
  background-color: #ffffff;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #ddddddb0;
  outline: none;

  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 2px #00000029;
}

.notification-center .notifications-area .notification.unread:hover .notification-read-status-container {
  background-color: var(--background-gray);
  box-shadow: var(--small-container-box-shadow);
}

.notification-read-status-container .notification-read-indicator {
  background-color: var(--light-green);
  border-radius: 50%;
  height: 8px;
  width: 10px;
  margin-top: 0.5px;
}

.notification-center .notification-title {
  font-size: 14px;
  font-weight: 600;
  padding: 0px 15px;
  margin-bottom: 5px;
  width: 60%;
}

.notification-center .notification-body {
  font-size: 14px;
  font-weight: 400;
  padding: 0px 15px;
}

.notification-center .unread-alerts-number {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  font-size: 11px;
  font-weight: 400;
  border-radius: 50%;
  border: 1px solid #c72d14;
  position: absolute;
  top: 52px;
  left: 58px;
  box-shadow: var(--large-container-box-shadow);
}

.notification-center .unread-info-number {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  font-size: 11px;
  font-weight: 400;
  border-radius: 50%;
  border: 1px solid var(--light-green);
  position: absolute;
  top: 52px;
  left: 117px;
  box-shadow: var(--large-container-box-shadow);
}

.notification-center .footer-line {
  width: 33%;
  border-top-width: 1.5px;
  margin-top: 25px;
}
