.default-profitrover-tooltip {
  --tooltip-background-color: var(--medium-cool-gray);

  &.tooltip.show {
    opacity: 1;
  }

  .tooltip-inner {
    background-color: var(--tooltip-background-color);
    color: white;
  }

  &.tooltip.bs-tooltip-top > .arrow::before {
    border-top-color: var(--tooltip-background-color);
  }
  &.tooltip.bs-tooltip-right > .arrow::before {
    border-right-color: var(--tooltip-background-color);
  }
  &.tooltip.bs-tooltip-bottom > .arrow::before {
    border-bottom-color: var(--tooltip-background-color);
  }
  &.tooltip.bs-tooltip-left > .arrow::before {
    border-left-color: var(--tooltip-background-color);
  }
}
