.secure-data-banner {
  display: flex;
  align-items: center;

  height: 48px;
  width: 584px;
  margin-bottom: 2rem;

  background: transparent linear-gradient(180deg, #ffffff 0%, #d8d8d8 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;

  font-size: 14px;
  font-weight: 500;
  color: var(--mid-green);

  .secure-data-badge {
    margin-top: 10px;
  }
}
