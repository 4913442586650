.pick-location-to-activate-modal-dialog {
  max-width: 768px;

  .pick-location-to-activate-modal-container {
    padding: 0px 24px 0px 24px;

    .profitrover-logo {
      width: 180px;
      padding: 0px 5px 0px 5px;
      vertical-align: baseline;
    }

    .header-instructions {
      font-size: 18px;
      color: var(--font-black);
    }

    .select-location-table {
      width: 400px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 15px;
      margin-bottom: 10px;

      .table-header {
        border-radius: 4px 4px 0px 0px;
        background: var(--mid-green);
        font-size: 18px;
        color: white;
        padding-left: 10px;
        display: flex;
        column-gap: 50px;
      }

      .table-content {
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 4px;
        font-size: 14px;
        color: var(--font-black);
        max-height: 320px;
        overflow-y: auto;

        .location-option-row {
          margin: 0px 10px 0px 10px;
          padding: 5px 0px 5px 0px;
        }

        .location-option-row:not(:last-of-type) {
          border-bottom: 1px solid #d9d9d9;
        }

        .location-option-radio {
          margin-right: 90px;
        }
      }
    }

    .explanation {
      font-size: 14px;
      color: var(--font-black);
    }
  }

  .pick-location-to-activate-modal-footer {
    display: flex;
    justify-content: right;
  }
}
