div.d3-svg-container svg .numeric {
  font-family: Arial;
}

/* 
 * Removing pointer events from D3 svg elements to prevent unintended mouseOut events from triggering 
 */
div.d3-svg-container svg * {
  pointer-events: none;
}

div.d3-svg-container.eo-price-dim-preview-bar svg * {
  pointer-events: auto !important;
}
