.industry-setup {
  .prompt {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 6px;
    letter-spacing: 0.18px;
  }

  .simple-questions {
    .side-by-side {
      display: flex;

      .labelled-field {
        flex: 1;

        &:not(:last-child) {
          margin-right: 26px;
        }
      }

      label {
        letter-spacing: 0.14px;
        font-size: 14px;
        color: var(--font-black);
      }
    }
  }

  --input-padding: 12px;
  input.text-field,
  input.PhoneInputInput {
    letter-spacing: 0.18px;
    font-size: 0.875rem;
    color: var(--font-black);
    border: 1px solid var(--light-cool-gray);
    border-radius: 4px;
    height: 34px;
    width: 100%;
    padding-left: var(--input-padding);
    padding-right: var(--input-padding);

    &:active,
    &:focus {
      outline: none;
      box-shadow: 0px 1px 6px var(--light-green-shadow);
    }

    &::placeholder {
      color: var(--font-gray);
    }
  }

  input.text-field,
  .PhoneInput {
    margin-bottom: 42px;
  }

  input.text-field.invalid,
  .PhoneInput.invalid input.PhoneInputInput {
    box-shadow: 0px 1px 6px #b3270f98;
  }

  .dropdown-area {
    margin-bottom: 40px;
  }
}
