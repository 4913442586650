.address-details-modal-dialog {
  max-width: 920px;

  .address-details-modal-container {
    .step-icon {
      margin-left: 1rem;
    }

    .header-line-1 {
      font-size: 24px;
      font-weight: 500;
      color: var(--font-black);
    }

    .header-line-2 {
      font-size: 18px;
      color: var(--font-black);
    }

    .location-edit-table {
      background: #ffffff;
      margin-top: 2rem;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 4px;
    }

    .location-edit-table textarea {
      border: none;
      width: 100%;
      background-color: transparent;
      height: 26px;
      margin-bottom: 1px;
      padding-left: 5px;
      font-size: 14px;
      resize: none;
      overflow-y: hidden;
      display: block;
    }

    .location-edit-table textarea.has-no-value {
      height: 26px;
      border: 1px solid #707070;
      border-radius: 4px;
    }

    .location-edit-table textarea:focus {
      height: 26px;
      border: 1px solid #707070;
      border-radius: 4px;
      box-shadow: 0px 1px 8px #ffc500d9;
    }

    .location-edit-table textarea:focus-visible {
      outline: none;
    }
  }

  .address-details-modal-footer {
    display: flex;
    justify-content: space-between;

    .footer-left {
      display: flex;
      justify-content: left;
    }

    .footer-right {
      display: flex;
      justify-content: right;
    }
  }
}
