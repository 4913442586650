@import '../../../sass/variables';

.integration-file-upload {
  height: calc(100% - $guided-setup-progress-bar-height);
  overflow: auto;

  .content {
    margin-top: 3.5rem;

    .instructions-column {
      flex: 1;
      margin-right: 2rem;
    }

    .file-upload-base {
      flex: 1;

      .sticky {
        position: sticky;
        // Hand-picked to make both cards align to the correct position before/after upload
        top: 13rem;
      }
    }
  }

  .instructions-container {
    background-color: white;
    padding: 1rem;

    .header-image {
      background-color: #adadad4c;

      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.75rem;
    }

    ol {
      li {
        list-style: decimal;
        &::marker {
          font-weight: 800;
        }

        display: list-item;
        flex-direction: column;
        margin-bottom: 2rem;

        .image-layout {
          display: flex;
          justify-content: flex-start;
        }

        img {
          margin-top: 1rem;
          box-shadow: var(--large-container-box-shadow);
        }

        .labeled-image {
          img {
            margin-top: 0rem;
          }
        }
      }
    }
  }
}
