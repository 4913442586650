.table-container {
  display: flex;
  width: 100%;
  height: fit-content;
  min-height: 52px;
  min-width: 100%;
  border-radius: var(--bubble-border-radius);
  position: relative;

  .ag-root-wrapper {
    border-radius: 4px;
  }

  .ag-root-wrapper-body.ag-layout-normal {
    height: fit-content;
    min-height: 48px;
  }

  .ag-root.ag-layout-normal {
    height: fit-content;
    min-height: 48px;
  }

  .ag-root-wrapper.ag-layout-normal {
    height: fit-content;
    min-height: 48px;
  }

  .ag-root {
    height: fit-content;
  }

  .table.hidden {
    display: none;
  }

  .ag-theme-alpine {
    border-radius: 4px;
    width: 100%;

    .ag-cell-data-changed {
      background: var(--light-blue) !important;
    }

    .table {
      font-family: URWDIN;
      font-weight: 500;
      font-size: 0.9rem;
      line-height: 1rem;
    }

    .ag-header-group-cell,
    .ag-header-cell {
      padding-left: 2px !important;
      padding-right: 2px !important;
    }

    .ag-cell {
      line-height: 0.9rem;
    }

    .ag-header {
      background-color: var(--lighter-background-gray);
    }
  }

  .ag-cell-label-container {
    justify-content: center;

    .ag-header-cell-label {
      justify-content: center;
      padding: 0;
    }
  }

  .ag-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 4px !important;
    padding-right: 4px !important;
    font-size: 0.8rem;
    line-height: 0.9rem;
  }

  .ag-row {
    border-bottom: none !important;
  }

  .ag-row-odd {
    background-color: #f7f7f7 !important;
  }

  .table-border-right {
    border-right: 1px solid #dee2e6 !important;
  }

  .center-text {
    text-align: center;
  }

  .editable {
    cursor: pointer;
  }

  .center-text .ag-header-cell-label {
    flex: none;
  }

  .table-header {
    text-transform: capitalize;
  }

  .ag-row .ag-cell:first-child {
    border-left: none !important;
  }

  .ag-pinned-left-header {
    border-right: 2px solid #b1b2b4 !important;
  }

  .ag-pinned-left-cols-container {
    border-right: 2px solid #b1b2b4 !important;
  }

  .ag-pinned-left-cols-container .ag-cell {
    border-right: none !important;
  }

  .ag-pinned-right-header {
    border-left: 2px solid #b1b2b4 !important;
  }

  .ag-pinned-right-cols-container {
    border-left: 2px solid #b1b2b4 !important;
  }

  .ag-pinned-right-cols-container .ag-cell {
    border-left: none !important;
  }

  .ag-pinned-right-cols-container .ag-cell {
    border-left: none !important;
  }

  .ag-theme-alpine .ag-cell-inline-editing {
    height: 24px;
    width: 75px;
    padding: 0 !important;

    .ag-react-container {
      height: 24px;
      width: 75px;

      .staffing-editor {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;

        input {
          width: 100%;
          height: 100%;
          padding: 0;
          text-align: center;
          border: 0;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .hour-cell-container {
    padding: 0.1rem 0 !important;
    border: none !important;
    width: 100%;
    height: 100%;

    .ag-react-container {
      width: 100%;
      height: 100%;

      .hour-cell {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
      }

      .hour-cell-active {
        background-color: var(--success-green);
      }

      .hour-cell-start {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-right: 1px solid var(--success-green);
      }

      .hour-cell-half-start {
        background-color: var(--success-green);
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-right: 1px solid var(--success-green);
        width: 50%;
        margin-left: auto;
      }

      .hour-cell-end {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-left: 1px solid var(--success-green);
      }

      .hour-cell-half-end {
        background-color: var(--success-green);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-left: 1px solid var(--success-green);
        width: 50%;
      }
    }
  }

  .ag-overlay {
    padding-top: 24px;
  }

  .icon {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
  }

  .remove-shifts-container {
    position: absolute;
    top: 1.7rem;
    left: -22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;

    .delete-button {
      width: 20px;
      height: 20px;
      padding: 0;
      margin: 0;

      &:focus {
        outline: none;
      }
    }
  }
}

.hour-options-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: auto;
  max-height: 150px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hour-option {
  padding: 4px 2px;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    background-color: #f0f0f0;
  }
}
