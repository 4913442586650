.pricing-pane {
  flex-grow: 1;
  min-height: 0px;
  overflow-y: auto;

  .open-button {
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pricing-tab {
    width: 100%;
    min-height: 100%;
    padding-bottom: 1rem;

    display: flex;
    flex-direction: column;

    .pricing-viz-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      .nav-summary {
        gap: 1rem;

        .nav-item {
          margin: 0;

          .nav-link {
            line-height: 1.2rem;
            padding-top: 0;
            padding-bottom: 0.2rem;
            font-size: 1rem;
          }
        }
      }

      .cards-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
        margin-bottom: 16px;

        // Extra space for the shadows to display correctly:
        width: calc(100% + 20px);
        margin: -10px -10px 0 -10px;

        @media screen and (max-width: 1300px) {
          flex-direction: column;
          gap: 1rem;
        }
      }

      .viz-card {
        padding: 0.8rem;
        overflow-x: auto;

        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        margin: 10px;
        border-radius: 8px;
        box-shadow: 0px 2px 8px #7c7c7c72;
        border: 0.5px solid #babfc7;

        .viz-content {
          width: 100%;
          min-width: 350px;
          margin-left: auto;
          margin-right: auto;

          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .viz-title-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .viz-title {
          align-self: flex-start;
          color: var(--font-black);
          font-weight: 700;
          text-align: center;
          font-size: 1.2rem;
          margin: 0;
        }

        .interval {
          color: var(--font-gray);
          font-size: 0.9rem;
          line-height: 1.1rem;
          font-weight: 500;
          margin: 0;
        }

        .viz-container {
          flex: 1 1 0px;
          min-height: 200px;
          max-width: 500px;
          width: 100%;
          overflow: hidden;
        }
      }

      .price-change-distribution-viz {
        .viz-content {
          max-width: 800px;
          min-width: 450px;
          display: flex;
          flex-direction: row;
          gap: 0.5rem;

          .y-label {
            color: var(--font-black);
            font-size: 15px;
            font-weight: 500;
            text-align: center;
            white-space: nowrap;
            line-height: 1.1rem;

            writing-mode: vertical-rl;
            text-orientation: mixed;
            transform: rotate(180deg);
          }
        }

        .price-change-viz-and-labels {
          width: calc(100% - 1.1rem - 0.5rem);
        }

        .viz-container {
          max-width: unset;
        }

        .labels {
          display: inline-flex;
          justify-content: space-evenly;
          gap: 0.5rem;
          padding: 0 5%;

          .label {
            width: 100%;
            color: var(--font-black);
            font-size: 14px;
            font-weight: 500;
            text-align: center;
            line-height: 1rem;

            p {
              white-space: pre-wrap;
              margin: 0;
            }
          }
        }
      }

      .summary-viz {
        --uplift-width: 250px;

        .viz-content {
          min-width: 580px;
          max-width: 800px;
        }

        .viz-container {
          flex: 1;
          min-height: 90px;
          height: 90px;
          max-width: unset;
        }

        .uplift-label {
          width: var(--uplift-width);
          align-self: flex-end;
          text-align: center;
          font-size: 1rem;
          line-height: 1.1rem;
          text-decoration: underline;
        }

        .uplift {
          margin: 0 1rem;
          width: calc(var(--uplift-width) - 2rem);
          text-align: center;
          font-size: 1.2rem;
          font-weight: 500;
          line-height: 1.4rem;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          &.uplift-positive {
            color: #0ec647;
          }

          &.uplift-negative {
            color: #c72913;
          }

          .triangle {
            margin-right: 10px;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
          }

          .triangle-up {
            border-bottom: 12px solid #0ec647;
            margin-bottom: 1px;
          }

          .triangle-down {
            border-top: 12px solid #c72913;
            margin-top: 1px;
          }
        }

        .divider {
          width: 100%;
          height: 1px;
          background-color: #babfc7;
          margin: 0.5rem 0;
        }

        .graph-container {
          .label {
            width: 85px;
            color: var(--font-black);
            font-size: 15px;
            font-weight: 500;
            text-align: right;
            line-height: 1.2rem;
            margin-top: 0.2rem;
          }
        }

        .legend {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 1.5rem;
          margin-top: 0.5rem;
          margin-right: var(--uplift-width);

          .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 0.8rem;
            line-height: 1rem;
            color: var(--font-black);

            p {
              margin: 0;
            }

            .color-box {
              width: 12px;
              height: 12px;
              border: 1px solid white;
              margin: 0 0.2rem;
            }
          }
        }
      }

      .breakdown-viz {
        .viz-content {
          flex: 1;
          min-width: 370px;

          .breakdown-selectors {
            flex-wrap: wrap;
            gap: 0.5rem;
          }
        }

        .viz-container {
          display: flex;
          overflow-y: visible;
          overflow-x: hidden;
          min-height: 170px;
          max-height: unset;
          max-width: 600px;
          height: fit-content;
        }

        .breakdown-container {
          flex: 1;
          max-height: 220px;
          overflow-y: auto;

          .labels {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 100px !important;
            max-width: 100px !important;
            margin-top: 1rem;
            flex-shrink: 0;

            .label {
              flex-shrink: 0;
              min-height: 42px;
              height: 42px !important;
              color: var(--font-black);
              font-size: 14px;
              font-weight: 500;
              text-align: center;

              display: flex;
              justify-content: center;

              p {
                margin: 0;
                line-height: 1.1rem;
                align-self: center;

                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;

                @supports (-webkit-line-clamp: 2) {
                  white-space: pre-wrap;
                  word-break: break-word;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                }
              }
            }
          }
        }
      }
    }

    .table-card {
      flex: 1;
      width: 100%;
      min-height: 300px;
      min-width: 300px;

      display: flex;
      flex-direction: column;

      .pricing-table-container {
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;

        .eo-price-rec-table-container {
          border-radius: var(--bubble-border-radius);
        }
      }
    }

    .pricing-viz-card {
      overflow: hidden;

      flex-grow: 1;
      flex-shrink: 1;
    }
  }
}
