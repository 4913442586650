.navbar img.profitrover-apps-header-logo {
  width: 300px;
}

.navbar {
  background-color: var(--dark-green);
}

#notification-center-header-icon {
  outline: none;
  border: none;
}

.notification-bell-stack:hover .notification-bell-bg {
  color: #d8d8d8;
}

.notification-bell-stack:hover .notification-bell-bg.open {
  color: #d8d8d8;
}

.notification-bell-stack:hover .notification-bell-bg + .notification-bell {
  filter: drop-shadow(0px 2px 3px #000000b0);
}

.notification-bell-stack:not(hover) .notification-bell-bg.open + .notification-bell {
  filter: drop-shadow(0px 1px 1.5px #000000a4);
}

.notification-bell-bg {
  font-size: 40px;
  color: #fff0;
}

.notification-bell-stack .notification-bell-bg.open {
  color: var(--light-cool-gray);
  font-size: 40px;
}

.notification-bell {
  color: white;
  font-size: 26px;

  filter: drop-shadow(0px 2px 3px #000000b0);
}

.notification-bell-stack .unread-notifications-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: var(--light-green);
  color: var(--font-black);
  right: 16px;
  top: -1px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  font-size: 11px;
  box-shadow: var(--large-container-box-shadow);
  border: 1px solid var(--light-green-hover);
  padding-bottom: 1px;
  font-weight: 500;
}

.notification-bell-stack .unread-notifications-icon.alerts.notification-info,
.notification-bell-stack .unread-notifications-icon.alerts {
  background-color: #c72d14;
  border: 1px solid #c72d14;
  color: var(--font-white);
}

.header-sub-text-container {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.header-sub-text-container .nav-bar-header-sub-text {
  color: white;
  font-size: 20px;
  white-space: nowrap;
}

.header-sub-text-container .vertical-separator {
  height: 29px;
  border: 1px solid #c7c7c7;
  margin-left: -10px;
  margin-right: 20px;
}

.navbar-brand {
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.header-warning-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-warning-container .header-warning {
  display: flex;
  align-items: center;
  color: white;
  font-size: 1rem;
  line-height: 1.1rem;
}

.header-warning-container .header-warning .Icon {
  color: white;
}

.navbar-nav .nav-item .nav-link {
  text-wrap: nowrap;
}
