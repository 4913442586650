/* For the locations table area */

.location-table-container {
  width: 100%;
}

.location-table-container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.location-table-container .top {
  display: flex;
  justify-content: space-between;
}

.location-table-container .ag-react-container {
  width: 100%;
}

.location-table-container .locations-summary {
  display: flex;
  align-items: baseline;
  margin-bottom: 5px;
}

.location-table-container .locations-summary > * {
  margin-bottom: 0px;
}

.location-table-container .locations-summary > h3 {
  color: var(--font-black);
  margin-right: 11px;
  font-size: 24px;
  position: 'relative';
  bottom: 2;
}

.location-table-container .locations-table {
  background: #ffffff 0% 0% no-repeat;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
}

.location-table-container .locations-table .ag-header-cell-text {
  white-space: normal;
}

.locations-table .ag-center-cols-clipper {
  min-height: unset !important;
}

.locations-table {
  font-weight: 500;
  font-size: 14px;

  .ag-row {
    cursor: pointer;
  }
}

.locations-table .ag-root-wrapper {
  border-radius: 4px;
}

.locations-table .ag-overlay-no-rows-center {
  display: none;
}

.locations-table .ag-root-wrapper-body.ag-layout-normal {
  height: unset;
}

.locations-table .ag-header-viewport {
  background-color: var(--mid-green);
}

.locations-table .ag-header-viewport .ag-header-cell-text {
  color: #ffffff;
}

.locations-table .ag-cell-value {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  line-height: 20px;
}

.locations-table .Icon {
  margin: 1px;
  padding-left: 10px;
}

.location-settings-mgmt-container .location-setting-header-container {
  font-size: 34px;
}

.location-select-dropdown div {
  width: 100%;
  height: 100%;
  box-sizing: content-box !important;
}

/* This is used to prevent rows with a span child from pushing out of view*/
.location-select-dropdown .ag-cell-wrapper {
  padding-bottom: 0px;
  width: 100%;
  height: 100%;
}

.location-select-dropdown .location-select-element {
  width: 100%;
  height: inherit;
  font-size: 14px;
  margin-bottom: 0px;
  font-weight: normal;
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #3e3e3e;
  outline: 0px;
  min-height: unset;
  border: unset;
}

.action-column-header div {
  justify-content: center;
}

.action-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: unset !important;
  padding-left: unset !important;
}

.action-button-container .ag-cell-wrapper .ag-cell-value {
  width: 100%;
  justify-content: inherit;
}

.action-button-container .ag-cell-wrapper .ag-cell-value .ag-react-container {
  width: 100% !important;
}

.location-edit-button {
  display: flex;
  justify-content: center;
  width: 100%;
}

.location-action-button:focus {
  outline: none;
}

.location-edit-cancel-container {
  display: flex;
  justify-content: space-evenly;
}

.location-edit-cancel-container button {
  margin: 2px 0 2px 0;
}

.hours-of-operation-container {
  .summary {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 0.75rem;

    h4 {
      font-size: 22px;
      font-weight: 600;
      color: var(--font-black);
      margin: 0;
    }
  }

  .checkbox {
    border: 1px solid var(--light-cool-gray);
    outline: none;
    box-shadow: 0px 1px 2px #00000029;
    border-radius: 4px;

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  .hours-of-operation-table-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .hours-of-operation-table {
      margin-top: 0.25rem;
      text-align: center;

      thead {
        font-weight: 600;
        font-size: 16px;
        color: var(--font-black);
      }

      th,
      td {
        padding: 0.25rem 0.75rem;
      }
    }

    .save-button {
      margin-top: 0.5rem;
      margin-bottom: 0.25rem;
    }

    .error-message {
      height: 24px;
      margin-top: 0.2rem;
      color: var(--error);
    }
  }

  .save-btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.hours-of-operation-input {
  position: relative;

  .hour-input {
    text-align: center;
    width: 125px;
    border: 1px solid var(--light-cool-gray);
    outline: none;
    box-shadow: 0px 1px 2px #00000029;
    padding: 8px 22px;
    padding-bottom: 12px;
    border-radius: 4px;
    transition: border-color 0.3s;
    font-size: 15px;

    &:focus {
      border-color: var(--light-green);
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  .error {
    border-color: var(--error);
  }

  .hidden {
    background-color: var(--light-cool-gray);
    color: transparent;

    &::placeholder {
      color: transparent;
    }

    &:disabled {
      background-color: #ececec;
    }
  }

  .options-container {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    z-index: 2;

    .option {
      padding: 8px;
      cursor: pointer;
      font-size: 15px;

      &:hover {
        background-color: #f0f0f0;
      }
    }

    .no-options {
      padding: 8px;
      font-size: 15px;
      pointer-events: none;
    }
  }

  .next-day {
    position: absolute;
    bottom: -4px;
    right: 0;
    left: 0;
    padding: 6px;
    font-size: 10px;
    color: var(--font-gray);
    pointer-events: none;
  }

  .clear-button {
    position: absolute;
    top: 7px;
    right: 2px;
    font-size: 16px;

    .clear-icon {
      color: var(--light-cool-gray);
    }
  }
}
