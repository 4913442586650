@mixin shadow-overflow {
  // Allows box-shadow to overflow container
  --overflow-distance: 0.75rem;
  padding-left: var(--overflow-distance);
  padding-right: var(--overflow-distance);
  margin-left: calc(-1 * var(--overflow-distance));
  margin-right: calc(-1 * var(--overflow-distance));
}

.at-a-glance-pane {
  overflow: auto;
}

.control-tooltip.control-disabled.tooltip.show {
  opacity: 0.7;
}

.at-a-glance {
  --day-gap: 2rem;
  --filters-height: 40px;

  color: var(--font-black);

  .filters-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: var(--filters-height);
    width: 100%;
    background-color: var(--background-gray);
    z-index: 1;

    .metric-label {
      font-size: 1rem;
      font-weight: 500;
      color: var(--font-black);
      line-height: 1rem;
      margin: 0;
    }
  }

  .at-a-glance-content {
    padding-top: var(--filters-height);
  }

  .top-section {
    width: 100%;
    padding: 1.75rem;
  }

  .date-control {
    color: var(--font-black);
    font-size: 1.2rem;
    &:disabled {
      opacity: 0.3;
    }
    &:focus {
      outline: 1.5px solid var(--light-green);
    }
  }

  .expand-details-btn {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-bottom: -0.75rem;

    &:not(:focus) {
      border-bottom: 1px solid var(--light-green);
    }

    &:focus {
      outline: 1.5px solid var(--light-green);
    }

    svg {
      transition: transform 0.2s;
    }

    &.show-details {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .week-list {
    overflow-x: auto;
    column-gap: var(--day-gap);
    padding-bottom: 1rem;
    margin-bottom: -1.5rem;

    @include shadow-overflow;
  }

  .total-stat {
    display: flex;
    align-items: center;

    .label {
      font-weight: 600;
      font-size: 1.3rem;
      color: var(--font-black);
    }

    .day-range {
      color: var(--font-gray);
      font-size: 0.875rem;
      line-height: 0.875rem;
    }

    .number {
      font-weight: 600;
      font-size: 1.5rem;
    }

    &:not(:last-child) {
      margin-right: 3rem;
    }
  }

  .show-me-uplift {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .uplift-potential {
      color: var(--mid-green);
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.25rem;
    }

    .link-like {
      font-size: 1.25rem;
      padding: 0;
    }
  }

  .holiday-label {
    width: 156px;
    display: flex;
    align-items: center;

    .holiday-label-text {
      color: var(--font-gray);
      font-size: 0.875rem;
      font-weight: 400;
      flex: 1;
      overflow: hidden;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .forecast-day-btn {
    box-shadow: 0px 4px 10px #30393b62;
    border: 2px solid var(--background-gray);
    border-radius: 0.25rem;

    min-width: 156px;
    width: min-content;

    &:active,
    &:focus {
      outline: 1.5px solid var(--light-green);
    }

    transition: transform 0.2s, border, box-shadow 0.25s ease-out;
    &.active {
      border: 2px solid var(--light-green);
      transition: transform 0.1s ease-out, border 0.25s ease-in, box-shadow 0.25s ease-out;
    }

    &:not(.active):hover {
      transform: scale(1.01) translateY(0.15rem);
      transition: transform 0.1s ease-out, border 0.25s ease-in, box-shadow 0.25s ease-out;
      box-shadow: 0px 5px 12px #30393b51;
    }

    --section-padding: 0.625rem;
    .upper {
      padding: var(--section-padding);
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      border-bottom: 1.5px solid #b1b3b370; // var(--light-cool-gray) + opacity
    }

    .lower {
      padding: var(--section-padding);
      padding-bottom: 0.875rem;
    }

    .date-label {
      color: var(--font-gray);
      font-size: 0.875rem;
    }

    .number-with-gauge {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      white-space: nowrap;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      .value {
        font-size: 1.125rem;
      }

      .gauge {
        width: 50px;
        height: 35px;
      }
    }

    .closed {
      font-weight: 500;
      white-space: nowrap;
      font-size: 1.125rem;
    }
  }

  --hour-cell-padding: 0.125rem 0;
  --hour-label-width: 40px;

  .hour-labels {
    margin-right: 1rem;
    margin-bottom: -0.5rem;
    flex-shrink: 0;

    .hour-label {
      text-align: center;
      color: var(--font-black);
      font-size: 1rem;
      font-weight: 400;
      padding: var(--hour-cell-padding);
    }
  }

  .hour-label-margin {
    margin-left: calc(var(--day-gap) + var(--hour-label-width));
  }

  .hour-col {
    border: 2px solid var(--background-gray);
    border-radius: 0.25rem;
    margin-top: 1rem;
    width: 100%;
    overflow: hidden;
    transition: transform 0.2s, border, box-shadow 0.25s ease-out;

    &.active {
      border: 2px solid var(--light-green);
      transition: transform 0.1s ease-out, border 0.25s ease-in, box-shadow 0.25s ease-out;
    }

    .hour-value {
      width: 100%;
      text-align: center;
      color: var(--font-black);
      font-size: 1rem;
      font-weight: 400;
      padding: var(--hour-cell-padding);
    }

    .closed {
      font-size: 1rem;
    }
  }

  .date-box {
    border: 1px solid var(--light-green);
    border-radius: 4px;
    box-shadow: 0px 3px 6px #30393b40;
    background: var(--lighter-background-gray);

    .day-of-week {
      font-weight: 500;
    }

    .day-of-month {
      font-size: 0.875rem;
      color: var(--font-gray);
    }
  }

  .location-label {
    font-size: 1.125rem;
    font-weight: 500;
  }

  .day-details-section {
    overflow-x: auto;

    .bubble {
      padding: 0.5rem 1rem;
      box-shadow: 0px 2px 5px #00000072;
      border-radius: 8px;

      flex: 1;

      &.weather-and-holidays {
        min-width: 500px;
      }

      &.breakdown {
        min-width: 700px;
      }

      .bubble-heading {
        font-size: 0.875rem;
        font-weight: 900;
        color: black;
      }
    }

    .view-top-by {
      font-size: 0.8rem;
      font-weight: 500;
      color: var(--font-gray);
      line-height: 0.8rem;
    }

    .legend {
      max-width: 175px;

      .color-box {
        width: 12px;
        min-width: 12px;
        max-width: 12px;
        height: 12px;
        min-height: 12px;
        max-height: 12px;
        border: 1px solid white;
        box-shadow: 0px 1px 3px #00000029;
      }

      .legend-label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        font-size: 0.75rem;
      }
    }

    .bar-graph-title {
      font-size: 0.75rem;
      font-weight: bold;
    }

    .divided-section:not(:last-child):after {
      content: '';
      border: 1px solid var(--light-cool-gray);
      height: 40px;
      margin: auto;
    }

    .conditions {
      font-size: 1.125rem;
      font-weight: 500;
    }

    .precipitation {
      font-size: 0.875rem;
      font-weight: 500;
    }

    .temp-type {
      font-size: 0.75rem;
      font-weight: 500;
    }

    .temp {
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1.35rem;
    }

    .holidays-and-events-label,
    .holidays-and-events-list {
      font-size: 0.875rem;
      font-weight: 500;
    }

    .holidays-and-events-label {
      white-space: nowrap;
      color: var(--font-gray);
    }

    .holidays-and-events-list {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      color: var(--darker-font-black);

      @supports (-webkit-line-clamp: 2) {
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .history-section {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    box-sizing: content-box;

    @include shadow-overflow;
  }

  .performance-review-section {
    display: flex;
    align-items: center;

    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow-x: auto;

      .graph-container {
        max-width: 800px;
        min-width: 500px;
        width: 100%;
        margin: auto;
      }

      .actuals-diff {
        width: 120px;
        text-align: center;

        .label {
          line-height: 1.1rem;
          margin-top: -1.1rem;
          margin-bottom: 0.5rem;
        }
      }

      .empty-text {
        font-size: 14px;
        font-weight: 400;
        color: var(--font-gray);
        text-align: center;
        margin: 3rem 0;
      }
    }
  }

  .pace-section {
    align-items: center;
    overflow-x: auto;

    .content {
      min-width: 550px;
      max-width: 800px;
      margin: auto;
    }

    .periods-column {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 0.3rem;
      gap: 0.4rem;

      .cell-period {
        display: flex;
        flex-direction: column;
        align-items: center;

        .period-label {
          font-size: 14px;
          line-height: 16px;
          font-weight: 500;
        }

        .period-value {
          font-size: 12px;
          line-height: 14px;
          color: var(--font-gray);
        }
      }
    }

    .column {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      .label {
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
        text-align: center;
        color: var(--font-black);
      }

      .values {
        text-align: center;
        margin-bottom: 0.5rem;
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
      }
    }

    .legend {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 0.75rem;

      .color-box {
        width: 12px;
        height: 12px;
        border: 1px solid white;
      }

      .color-line {
        width: 4px;
        height: 20px;
      }

      .legend-label {
        font-size: 0.8rem;
        line-height: 1rem;
      }

      .to-date {
        background-color: var(--light-blue);
      }
    }
  }

  .staffing-section {
    display: flex;
    flex-direction: row;
  }
}

@media screen and (max-width: 1200px) {
  .at-a-glance {
    .history-section {
      flex-direction: column;
    }
  }
}
