@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

@include media-breakpoint-up(xl) {
  main.container {
    max-width: 920px;
  }
}

@include media-breakpoint-up(lg) {
  main.container {
    max-width: 920px;
  }
}

.data-sources {
  main {
    margin-top: 4rem;
  }

  .data-source-card {
    height: 60px;
    padding: 14px 24px;

    // Allows for last component to define its own spacing
    padding-right: 0px;

    .flex-container {
      display: flex;
      align-items: center;
      height: 100%;

      img {
        height: 28px;
      }

      > div {
        flex: 1 1 0px;
      }

      .label {
        color: var(--font-black);
        font-size: 18px;
        font-weight: 500;
      }

      .name {
        color: var(--font-black);
        font-size: 12px;
        font-weight: 300;
      }

      .timestamp {
        color: var(--font-gray);
        font-size: 14px;
        text-align: center;

        flex: 2;
      }

      .actions {
        display: flex;
        justify-content: center;
      }

      .disconnect-btn {
        font-size: 0.75rem;
      }

      .settings-cog {
        max-width: 40px;
        padding-right: 16px;
      }
    }
  }

  .data-source-card:not(:last-child) {
    margin-bottom: 10px;
  }

  .help-text-container {
    margin-top: 1rem;
    font-size: 14px;
    font-style: italic;

    a {
      margin-left: 0.25rem;
      font-style: normal;
    }
  }
}
