.custom-events-container {
  width: 100%;
  text-align: center;
}

.modal-heading {
  font-weight: bold;
  text-align: center;
  font-size: 20px;
}

.checked-input {
  text-align: center;
  vertical-align: middle;
}

.footer-plus {
  font-weight: bold;
  background-color: #fff;
  cursor: pointer;
}

.event-div {
  margin-left: 7px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}
.date-picker .react-datepicker-wrapper .react-datepicker__input-container input {
  width: 100%;
}

.input {
  width: 100%;
}

.select {
  width: 100%;
  height: 30px;
}

.custom-events-button-footer {
  display: inline-flex;
  justify-content: center;
  margin: 0;
  gap: 12px;
  margin: 1rem;
}
