.manage-datasets-table {
  padding: 0px;
}

.dataset-delete-modal-message {
  margin-top: 15px;
  font-size: 12px;
}

.dataset-modal-sub-header {
  font-size: 14px;
}
