.resize-container {
  position: relative;
  overflow: visible;
  width: 0;
  flex: 1;
  height: 100%;

  .line-chart {
    // Safari layout fix
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
