/* Custom font faces */
@font-face {
  font-family: 'URWDIN';
  src: local('URWDIN-Thin'), url(./fonts/URWDIN-Thin.otf) format('opentype');
  font-weight: 100;
}

@font-face {
  font-family: 'URWDIN';
  src: local('URWDIN-XLight'), url(./fonts/URWDIN-XLight.otf) format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: 'URWDIN';
  src: local('URWDIN-Light'), url(./fonts/URWDIN-Light.otf) format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'URWDIN';
  src: local('URWDIN-Regular'), url(./fonts/URWDIN-Regular.otf) format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'URWDIN';
  src: local('URWDIN-Medium'), url(./fonts/URWDIN-Medium.otf) format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'URWDIN';
  src: local('URWDIN-Demi'), url(./fonts/URWDIN-Demi.otf) format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'URWDIN';
  src: local('URWDIN-Demi'), url(./fonts/URWDIN-Demi.otf) format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'URWDIN';
  src: local('URWDIN-Bold'), url(./fonts/URWDIN-Bold.otf) format('opentype');
  font-weight: 800;
}

@font-face {
  font-family: 'URWDIN';
  src: local('URWDIN-Black'), url(./fonts/URWDIN-Black.otf) format('opentype');
  font-weight: 900;
}

body {
  font-family: URWDIN, Arial, sans-serif;

  min-height: 100vh;
  overflow-x: hidden;
}

/* Applies to all Bootstrap tooltips (overriding the default) */
.tooltip {
  font-family: URWDIN, Arial, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Remove annoying default styling */
button,
input[type='submit'],
input[type='reset'] {
  background: none;
  border: none;
  outline: inherit;
}

a {
  color: var(--light-blue)
}
