.filters-menu {
  --normal-screen-width: 45vw;
  --small-screen-width: 66vw;

  position: fixed;
  z-index: 1001;

  box-shadow: var(--large-container-box-shadow);

  height: calc(100vh - 60px);

  width: min(var(--normal-screen-width), 550px);
  @media screen and (max-width: 1024px) {
    width: var(--small-screen-width);
  }

  left: calc(-1 * var(--normal-screen-width));
  @media screen and (max-width: 1024px) {
    left: calc(-1 * var(--small-screen-width));
  }
  &.open {
    left: 0px;

    opacity: 1;
    animation-fill-mode: both;
    transition: left 0.25s ease-out, opacity 0.2s ease-in;
  }
  &:not(.open) {
    opacity: 0;
    transition: left 0.25s ease-out, opacity 0.2s linear;
  }
}

.dark-backdrop {
  position: fixed;
  top: 0px;

  height: 100vh;
  width: 100vw;
  z-index: 1000;

  animation: fadebackground 0.3s forwards;
}

@keyframes fadebackground {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.filters-menu {
  .top-part {
    display: flex;
    flex-direction: column;

    .heading {
      display: flex;

      .reset-all-filters-button {
        height: 23px;

        font-size: 12px;
        font-weight: 300;
        color: #606060;

        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: var(--small-container-box-shadow);
        border: 1px solid #e8e8e8;
        border-radius: 4px;

        user-select: none;
      }

      > h5 {
        margin-bottom: 0px;
        font-weight: 700;
        font-size: 22px;
      }

      > *:not(:last-child) {
        margin-right: 16px;
      }
    }

    hr {
      border-color: #dedede;
      border-width: 2px;
      margin-top: 7px;
      margin-bottom: 0px;
      width: 100%;
    }
  }

  .eo-price-dim-filter-menu {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    overflow-y: hidden;

    padding-top: 13px;
    padding-bottom: 7px;
    margin-bottom: 0px;

    position: relative;

    .dimension-distributions {
      overflow: auto;
    }

    .dim-viewer-top-bar {
      display: flex;
      align-items: center;
    }

    .dim-viewer-top-bar > * {
      margin-right: 10px;
    }

    .dim-viewer-filter-search-input {
      margin: 13px 0px;

      height: 25px;
      width: 100%;

      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #d0d0d0;
      border-radius: 2px;
    }

    .dim-viewer-filter-search-input:focus-within {
      box-shadow: var(--focus-gold-shadow);
      outline: none;
    }

    .dim-viewer-filter-search-input input {
      font: 12px Arial;
    }

    hr.dim-value-separator {
      width: 100%;
      margin: 5px 0px;
    }

    hr.settings-separator {
      width: 100%;
      margin: 0 0 12px 0;
    }

    hr.focused-nav-item-separator {
      width: 100%;
      margin: 12px 0;
    }

    .dim-value-uplift-bars-container {
      flex-grow: 1;
      overflow-y: auto;

      /* These properties work alongside .eo-price-fcst-left-container > * to allow
       * the box shadow on each dimension value row to overflow horizontally instead
       * of getting clipped.
       */
      margin-left: -10px;
      padding-left: 10px;
      margin-right: -5px; /* Also applies spacing between scrollbar and uplift bars, if present */
      padding-right: 5px;

      padding-top: 3px;
      padding-bottom: 2rem;
    }

    .dim-value-uplift-bars-container hr {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .single-dim-value-row {
      display: inline-flex;

      width: 100%;
      height: 34px;

      align-items: center;
      padding-left: 9px;
      padding-right: 1px;
      padding-top: 1.5px;
      padding-bottom: 1.5px;

      border-radius: 3px;
    }

    .single-dim-value-row:not(.selected) {
      opacity: 0.8;
    }

    .single-dim-value-row:not(.selected):hover {
      box-shadow: 0px 2px 4px #00000029;
      opacity: 1;
    }

    .single-dim-value-row.selected {
      background-color: #f4f4f4;
      box-shadow: 0px 3px 6px #00000029;

      padding-right: 1px;
      padding-left: 8px;

      border: 1px solid #ededed;
      border-radius: 2px;
    }

    .single-dim-value-row:hover,
    .single-dim-value-row.selected:hover {
      background-color: var(--dark-hover-gray);

      transition: background-color 0.1s ease-in, opacity 0.1s ease-in, box-shadow 0.15s ease-in-out;
    }

    .single-dim-value-row.disabled {
      cursor: not-allowed;
      background: #f4f4f4 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #ededed;
      border-radius: 2px;
      opacity: 0.5;
    }

    .price-dim-category {
      margin-bottom: 0.5rem;
    }

    .eo-price-dim-label-and-filter-actions-row {
      margin-bottom: 0.75rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .eo-price-dim-label {
      display: flex;
    }

    .eo-price-dim-button {
      display: flex;
      margin-left: 20px;
    }

    .eo-price-dim-button:hover {
      background-color: var(--background-gray);
    }

    .eo-price-dim-preview-name {
      font-weight: 400;
    }

    .eo-price-dim-preview-name.subitem {
      font-weight: 400;
      cursor: pointer;
    }

    .eo-price-dim-preview-name.disabled {
      opacity: 0.7;
    }

    .navigation-item-focused-label {
      font-size: 18px;
      font-weight: 500;
    }

    .price-dim-category-label {
      font-weight: 700;
      font-size: 22px;
    }
  }
}

.selectors-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
}

.flex-form {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.role-form {
  .category-label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .category {
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 350px;

    .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-bottom: 0.5rem;

      .item-label {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: var(--font-gray);
        margin-bottom: 0.15rem;

        span {
          font-size: 17px;
          font-weight: 600;
        }
      }

      .input-wrapper {
        flex: 1;
        display: flex;
        border: 1px solid var(--light-cool-gray);
        border-radius: 4px;
        box-shadow: 0px 1px 4px #0000002e;
        padding: 5px;
      }

      .prefix,
      .suffix {
        font-size: 14px;
        line-height: 18px;
        color: var(--font-black);
        align-content: center;
      }

      .item-input {
        flex: 1;
        display: flex;
        border: 0px solid;
        color: var(--font-black);
        font-size: 14px;
        line-height: 18px;

        &:focus {
          outline: none;
        }
      }

      .input-container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        max-width: 180px;

        .revenue-uplift-select-theme {
          .k-theme__control {
            height: 30px;
            max-height: 30px;
            border: 1px solid var(--light-cool-gray);
          }
        }
      }

      .error {
        border: 1px solid var(--error);
      }

      .error-message {
        font-size: 12px;
        line-height: 14px;
        color: var(--error);
        margin-top: 0.25rem;
      }

      /* Override background color for autofilled inputs */
      input:-webkit-autofill,
      input:-webkit-autofill:focus {
        box-shadow: 0 0 0 40px white inset;
      }
    }
  }

  .category.full-width {
    width: 100%;
    flex-direction: column;
  }

  .radio-button-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    .item {
      margin-right: 0.3rem;
    }
  }

  .legend {
    .label {
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
      color: var(--font-black);
      margin-bottom: 0.25rem;

      span {
        font-size: 17px;
        font-weight: 600;
      }
    }
  }
}
