.pay-invoice {
  max-width: 500px;
}

.pay-invoice .labeled-bubble-container {
  padding-top: 30px;
}

.pay-invoice .explanatory-text-container {
  margin-top: 18px;
}

.pay-invoice .invoice-number .label {
  font-weight: 600;
  font-size: 14px;
}

.pay-invoice .invoice-number .value {
  color: var(--font-gray);
  font-size: 12px;
}

.pay-invoice .labeled-bubble-container .emphasis-area .due-today {
  font-size: 18px;
}
