/* Applies to all common ProfitRoverButtons */
.profitrover-button-base {
  /** 
   * The padding-top value intentionally differs from the ProfitRover Style Guide
   * to account for font rendering differences.
   */
  padding: 8px 16px 8px 16px;
  border-radius: 4px;
  max-height: 36px;

  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;

  display: flex;
  align-items: center;
  justify-content: center;
}

.profitrover-button-base:hover,
.profitrover-button-base:focus,
.profitrover-button-base:active,
.profitrover-button-base:disabled {
  transition: opacity 0.2s ease-in, background 0.1s ease-in;
}

.profitrover-add-button:hover,
.profitrover-add-button:focus,
.profitrover-add-button:active,
.profitrover-add-button:disabled {
  transition: opacity 0.2s ease-in, background 0.1s ease-in;
}

.profitrover-button-base:disabled,
.profitrover-add-button:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

button.profitrover-button-base.small-button {
  max-height: 32px;
  font-size: 14px;
}

button.profitrover-primary-button {
  background-color: var(--light-blue);
  color: var(--font-white);
  box-shadow: 0px 3px 6px #0000003e;
}

button.profitrover-primary-button:hover:not(:disabled):not(:active) {
  box-shadow: 0px 3px 6px #0000004d;
  background-color: var(--light-blue-hover);
}

button.profitrover-primary-button:focus {
  box-shadow: 0px 3px 6px var(--light-blue-shadow);
  outline: 1px solid var(--medium-cool-gray);
}

button.profitrover-primary-button:active {
  background-color: var(--light-blue-hover);
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #0000004d;
  outline: none;
}

button.profitrover-secondary-button {
  background-color: white;
  box-shadow: 0px 3px 6px #0000003d;

  border: 2px solid var(--light-blue);
}

button.profitrover-secondary-button:hover:not(:disabled):not(:active) {
  background-color: var(--background-gray);
}

button.profitrover-secondary-button:focus {
  box-shadow: 0px 3px 6px var(--light-blue-shadow);
  outline: 1px solid var(--medium-cool-gray);
}

button.profitrover-secondary-button:active {
  background-color: var(--background-gray);
  box-shadow: inset 0px 3px 6px #0000004d, 0px 3px 6px #0000003d;
  outline: none;
}

button.profitrover-cancel-button {
  background-color: white;
  box-shadow: 0px 3px 6px #0000003e;

  color: var(--font-gray);
}

button.profitrover-cancel-button:hover:not(:disabled):not(:active) {
  background-color: var(--background-gray);
}

button.profitrover-cancel-button:focus {
  box-shadow: 0px 3px 6px var(--medium-cool-gray);
  outline: 1px solid var(--medium-cool-gray);
}

button.profitrover-cancel-button:active {
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #0000003e;
  outline: none;
}

button.profitrover-destructive-button {
  background-color: white;
  box-shadow: 0px 3px 6px #0000003e;
  border: 2px solid #c44011;

  color: #c44011;
}

button.profitrover-destructive-button:hover:not(:disabled):not(:active) {
  background-color: #ebe2df;
}

button.profitrover-destructive-button:focus {
  outline: 1px solid var(--medium-cool-gray);
}

button.profitrover-destructive-button:active {
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #0000003e;
  outline: none;
}

button.profitrover-add-button {
  padding: 6px 12px;

  color: var(--font-gray);
  background-color: white;
  border: none;
  border-radius: 30px;
  box-shadow: 0px 3px 6px #0000003e;

  font-size: 14px;
  font-weight: 500;
}

button.profitrover-add-button:hover:not(:disabled):not(:active) {
  background-color: var(--background-gray);
}

button.profitrover-add-button:focus {
  box-shadow: 0px 3px 6px var(--medium-cool-gray);
  outline: 1px solid var(--medium-cool-gray);
}

button.profitrover-add-button:active {
  background-color: var(--background-gray);
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #0000003e;
  outline: none;
}

button.profitrover-add-button .alignment-container {
  display: flex;
  align-items: center;
}

button.profitrover-add-button .plus-container {
  --circle-height: 25px;

  color: white;
  background: var(--success-green);

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 22px;
  line-height: var(--circle-height);

  min-width: var(--circle-height);
  max-width: var(--circle-height);
  min-height: var(--circle-height);
  max-height: var(--circle-height);
  margin-right: 8px;

  border-radius: 50%;
}

button.profitrover-add-button .plus {
  position: relative;
  left: 0.5px;
  bottom: 0.5px;
}

.profitrover-download-select-button {
  position: relative;
  display: inline-block;
}

.download-icon {
  color: var(--font-black);
  margin-right: 10px;
}

.divider {
  display: inline-block;
  width: 1px;
  height: 24px;
  background-color: var(--font-black);
  margin: 0 12px;
  vertical-align: middle;
}

.menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 100%;
}

.menu-option {
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.menu-option:hover,
.menu-option.active {
  background-color: var(--background-gray);
}
