.header-aware-page-container {
  min-height: 100vh;
  background-color: var(--background-gray);
  overflow: auto;
}

.header-aware-page-container.fixed-height {
  height: 100vh;
  overflow: hidden;
}

/**
 * Maintenance Note:
 * The padding and breakpoint sizes here were derived from manual inspection
 * in the browser. Thus:
 * - if new components are added to the header that affect its height
 * - OR if Bootstrap's Nav is swapped out for an alternative implementation
 * ...these values may be rendered obsolete/broken and will need to be manually
 * re-adjusted.
 */
@media only screen and (min-width: 893px) {
  .header-aware-page-container.header-present {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 893px) {
  .header-aware-page-container.header-present {
    padding-top: 60px;
  }
}
