.profitrover-dashboard {
  --large-bubble-box-shadow: 0px 3px 6px #0000004e;
  --small-bubble-box-shadow: 0px 3px 6px #0000004d;
  --bubble-border-radius: 8px;

  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

.toolbar {
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 0 16px;

  .spacer {
    --spacing: 20px;

    width: 1px;
    margin: 8px var(--spacing);
    margin-left: 4px;
    border-right: 1px solid var(--light-cool-gray);
  }

  .filters-container {
    flex: 1;
    display: flex;
    overflow: hidden;
    margin-right: -6px;
    align-items: center;
    gap: 0.5rem;
  }
}

.setting-label {
  color: var(--mid-green);
  font-size: 14px;
  text-align: left;
  white-space: nowrap;
}

@keyframes tooltip-appear {
  from {
    margin-left: 10px;
    opacity: 0.6;
  }
  to {
    margin-left: 0px;
    opacity: 1;
  }
}

.dropdowns-toolbar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.filters-toolbar {
  height: 3rem;

  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  max-width: 100%;

  .nav-clickable {
    display: flex;
    flex-direction: row;
    align-items: center;
    letter-spacing: 0.18px;
    cursor: pointer;
    gap: 0.5rem;

    .nav-link {
      font-size: 18px;
      color: var(--font-black);
      font-weight: 500;
      margin: 0;
      padding: 0.4rem 2px;
    }
  }

  .open-button {
    background-color: white;
    color: var(--light-blue);

    padding-right: 0.6rem;
    padding-left: 0.6rem;

    border: 1px solid var(--light-cool-gray);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;

    font-size: 0.9rem;

    white-space: nowrap;

    &:hover {
      background-color: var(--dark-hover-gray);

      transition-property: background-color, fill, color;
      transition-duration: 0.1s;
      transition-timing-function: ease-out;
    }
  }

  .handle:disabled {
    opacity: 0.3;
  }

  .bubbles-list {
    display: flex;
    align-items: center;
    flex-grow: 1;

    min-width: 0;
    height: 100%;

    // Hide the scrollbar but allow touch-drag and middle-mouse-click scrolling
    overflow-x: auto;
    scrollbar-width: none; // Works for Firefox
    &::-webkit-scrollbar {
      display: none; // Works for Chrome, Edge, and other webkit-based browsers
    }

    scroll-behavior: smooth;
  }

  .filter-bubble:not(:last-child) {
    margin-right: 0.5rem;
  }

  .filter-bubble {
    background-color: white;
    color: var(--font-black);

    white-space: nowrap;

    &:hover {
      background-color: var(--hover-gray);
      transition-property: background-color;
    }

    height: 1.5rem;
    padding-left: 0.75rem;

    display: flex;
    align-items: center;

    border-radius: 12px;
    border: 1px solid var(--light-cool-gray);
    box-shadow: 0px 1px 1px #00000029;

    font-size: 0.9rem;

    .clear-button {
      outline: none;
      display: flex;
      transform: scale(1.2);
      color: var(--light-cool-gray);

      &:hover {
        color: var(--font-black);
        transform: scale(1.6);
        transition-property: color, transform;
        transition-duration: 0.2s;
      }
    }
  }

  .filter-bubble.disabled {
    opacity: 0.6;
  }
}

.dim-values-tooltip {
  .tooltip-inner {
    white-space: nowrap;
    width: min-content;
    max-width: 660px;
  }
}
