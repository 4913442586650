.revenue-uplift-select-theme {
  font-size: 14px;
  outline: none;

  &.warning-shadow {
    .k-theme__control {
      box-shadow: 0px 1px 6px #d89286;
      border-color: var(--error);
    }
  }

  .k-theme__control {
    max-height: 24px;
    border: 1px solid var(--light-cool-gray);
    box-shadow: 0px 1px 2px #00000029;

    &.k-theme__control--is-disabled {
      opacity: 0.6;
      background-color: white;

      pointer-events: auto; // This override is needed to set cursor: not-allowed
      cursor: not-allowed;
    }

    .k-theme__value-container {
      padding-left: 6px;
      max-height: 24px;

      .k-theme__single-value {
        color: #606060;
      }
    }

    input {
      font-size: inherit !important;
      font-family: inherit !important;
    }

    .k-theme__indicators {
      .k-theme__indicator-separator {
        display: none;
      }

      .k-theme__indicator {
        align-items: center;
        justify-content: center;
      }

      & > * {
        max-height: 8px;

        svg {
          height: 16px;
          width: 16px;
        }
      }
    }
  }

  .k-theme__menu {
    .k-theme__menu-list {
      .k-theme__option {
        padding: 0px 6px;
      }
    }
  }
}
