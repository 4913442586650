.profitrover-tab-content {
  .nav {
    /* This is generally meant to be overridden, but serves as a basic default */
    padding-left: 12px;
  }

  .nav-item {
    margin-right: 18px;
    letter-spacing: 0.18px;

    .nav-link {
      padding: 0.4rem 2px;
      padding-top: 0.6rem;

      font-size: 18px;
      color: var(--font-gray);

      white-space: nowrap;

      &.active {
        color: var(--font-black);
        font-weight: 500;
        border-bottom: 3px solid var(--font-black);
      }
    }
  }
}
