.create-location-modal-dialog {
  max-width: 920px;
}

.create-location-modal-footer {
  display: flex;
  justify-content: right;
}

.create-location-modal-header {
  width: fit-content;
  padding-bottom: 5px;
  border-bottom: 1px solid #d9d9d9;
}

.create-location-modal-container {
  .step-icon {
    margin-left: 1rem;
  }

  .header-text.step-text {
    vertical-align: bottom;
    font-weight: 500;
  }

  .location-select-table-instruction-line-1 {
    padding-right: 3rem;
    font-size: 18px;
  }

  .location-select-table-instruction-line-2 {
    margin-top: 1rem;
    margin-bottom: 5px;
    font-size: 18px;
  }

  .location-edit-table {
    background: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
  }

  .location-edit-table textarea {
    border: none;
    width: 100%;
    background-color: transparent;
    height: 26px;
    margin-bottom: 1px;
    padding-left: 5px;
    font-size: 14px;
    resize: none;
    overflow-y: hidden;
    display: block;
  }

  .location-edit-table textarea.has-no-value {
    height: 26px;
    border: 1px solid #707070;
    border-radius: 4px;
  }

  .location-edit-table textarea:focus {
    height: 26px;
    border: 1px solid #707070;
    border-radius: 4px;
    box-shadow: 0px 1px 8px #ffc500d9;
  }

  .location-edit-table textarea:focus-visible {
    outline: none;
  }

  .location-edit-button {
    width: 210px;
    height: 40px;
    background: #fec52d 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 8px #0000004e;
    border-radius: 4px;
    font: normal bold 18px/23px Arial;
    text-align: center;
    letter-spacing: 0px;
    color: black;
  }

  .location-edit-button:disabled {
    color: rgb(170, 170, 170);
  }

  .error-message-text {
    color: var(--error);
    margin-top: 10px;
  }
}
