.link-with-directional-arrow,
.arrow {
  font-size: 14px;
  font-weight: 600;
  color: var(--light-blue);

  cursor: pointer;

  display: flex;
  align-items: flex-end;
}

.link-with-directional-arrow {
  text-decoration: underline;

  margin-bottom: 3px;
}

.arrow {
  font-size: 13px;
  margin-bottom: 3px;
}
