$mobile-breakpoint-width: 800px;

.redeem-code {
  .redeem-code-container {
    display: inline-block;
    background-color: white;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    max-width: 390px;
    padding: 20px 40px;
    padding-bottom: 10px;
    box-shadow: 0px 3px 6px #00000029;

    @media screen and (max-width: $mobile-breakpoint-width) {
      max-width: 365px;
      padding: 20px 30px;
      padding-bottom: 10px;
    }
  }

  .title {
    text-align: left;
    font-size: 24px;
    font-weight: 600;
    color: #3e3e3e;
  }

  .subtitle {
    font-size: 14px;
    font-weight: 500;
    color: #848484;
    word-break: break-all;
    margin-bottom: 25px;
  }

  input:not([type='checkbox']):not([type='radio']) {
    width: 100%;
    height: 36px;

    padding: 0px 10px;
    border: 1px solid #efefef;
    border-radius: 4px;
    box-shadow: 0px 1px 4px #00000029;
    text-align: center;

    &:focus {
      box-shadow: 0px 1px 6px var(--light-green-shadow);
    }

    &:focus-visible {
      outline: none;
    }
  }

  .resend-code-button {
    font-size: 14px;
    font-weight: 500;
    color: var(--light-blue);
    text-decoration: underline;
    margin: 20px 0px;
  }

  .redeem-code-button {
    margin-bottom: 10px;
    width: 100%;
  }

  .resent-email-text,
  .registration-code-error-text {
    margin-top: 5px;
    margin-left: 3px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
  }

  .resent-email-text {
    color: var(--success-green);
  }

  .registration-code-error-text {
    color: var(--error);
  }

  .email-explanation-text {
    font-size: 12px;
    color: var(--font-gray);
    padding-top: 10px;
  }
}
