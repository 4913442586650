.price-rules {
  text-align: center;
}

#price-rules-apply-filters {
  margin-top: 1.7vw;
  margin-left: 1vw;
  margin-bottom: 1.5vw;
}

#hide-filter-p {
  color: white;
}

.price-rule-buttons {
  display: inline;
  margin: 20px;
}

.footer-add-button {
  font-weight: bold;
  background-color: #ffffff;
  cursor: pointer;
}

.footer-form-values {
  width: 100%;
  margin-top: 5px;
}

.table-column-yellow {
  background-color: rgb(254, 255, 203);
}

.table-column-green {
  background-color: rgba(0, 151, 19, 0.3);
}

.price-modal-button {
  text-align: center;
  margin-bottom: 10px;
}

.price-filters-button-footer {
  display: inline-flex;
  justify-content: center;
  padding: 24px;
}
