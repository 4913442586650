.integration-instructions {
  color: var(--font-black);

  .container {
    max-width: 850px;

    .underline-container > span {
      max-height: 50px;
    }

    .generic-form-subheader {
      margin-bottom: 20px;
    }
  }

  .profitrover-card {
    padding: 13.5px;
    padding-bottom: 24px;
    font-size: 14px;
  }
}
