.not-accessible-notice {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.not-accessible-message {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px;
  margin: 20px;

  font-size: 14px;

  background: var(--background-gray);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
}

.not-accessible-message svg {
  margin-right: 10px;
  font-size: 22px;
  color: var(--error);
}

/**
 * These styles are specific to the tooltip on a Workflow Tile in the Welcome Page
 */

#animated-tile > .tooltip-inner {
  font-size: 12px;

  background: var(--background-gray);
  color: #000;

  box-shadow: 0px 3px 6px #00000029;
  min-width: 360px;
}

#animated-tile svg {
  margin-right: 10px;
  color: var(--error);
  font-size: 16px;
}

#animated-tile.tooltip .arrow:before {
  border-bottom-color: var(--background-gray);
}
