.update-workflow-container {
  margin: 0 auto;
  padding: 65px 10px;
  max-width: 900px;
}

.update-workflow-settings-header {
  padding-top: 55px;
  display: flex;
  align-items: center;
  width: 422px;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 28px;
}

.update-workflow-container .dimensions-drop-down {
  width: 90%;
  height: 36px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
}

.update-workflow-container .price-change-frequency-selection {
  margin-top: 28px;
}

.update-workflow-container .price-change-frequency-selection span {
  margin-left: 10px;
  margin-right: 30px;
}

.update-workflow-container .reset-price-change-frequency-link {
  text-decoration: underline;
  color: var(--light-blue);
  cursor: pointer;
  margin-left: 5px !important;
}

.update-workflow-container .price-change-frequency-text-disabled {
  color: lightgray;
}

.update-workflow-container .price-change-frequency-selection span.vertical-separator {
  height: 29px;
  border: 1px solid #c7c7c7;
  margin-left: -10px;
  margin-right: 20px;
}

.generic-form-prompt-text {
  font-size: 18px;
  margin-bottom: 4px;
}

.workflow-dimensions-settings-text {
  font-size: 30px;
  color: #3e3e3e;
  font-weight: 500;
}

.edit-workflow-div {
  display: grid;
  align-items: center;
  justify-content: center;
}

.update-workflow-settings-header .profitrover-logo {
  margin-right: 15px;
  width: 70%;
}

.workflow-refresh-notification-text {
  color: #3e3e3e;
  font-size: 14px;
  font-style: italic;
  margin-top: 14px;
}

.update-workflow-container .save-button .profitrover-primary-button {
  width: 220px;
}

.update-workflow-container input[type='checkbox'] {
  accent-color: var(--light-blue);
}

.buttons-container {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  gap: 1rem;
}
