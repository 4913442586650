.eo-help-container {
  padding-left: 125px;

  .generic-form-prompt-text:first-of-type {
    margin-top: 30px;
  }

  .generic-page-header {
    margin-top: 30px;
  }

  .eo-help-radio-row {
    margin-bottom: 30px;
    min-width: 460px;
  }

  .eo-help-text-input {
    min-width: 250px;
    max-width: 300px;
    margin-bottom: 1rem;
  }

  .eo-help-text-area {
    min-width: 250px;
    max-width: 500px;
    margin-bottom: 45px;
  }
}
