.schedule-strategy-call {
  .layout-container {
    display: grid;
    grid-template-rows: 340px 1fr;

    &.narrow {
      grid-template-rows: 500px 1fr;
    }

    height: 100%;
    width: 100%;

    overflow: auto;

    --horizontal-margin: 5%;
    main {
      display: flex;
      justify-content: center;

      > button {
        margin-left: var(--horizontal-margin);
      }
    }

    .info-card {
      display: flex;
      flex-grow: 0;
      max-height: 280px;

      margin: 20px var(--horizontal-margin);
      margin-top: 0px;
      padding-left: 25px;
      padding-right: 25px;

      &.narrow {
        flex-direction: column;
        max-height: 460px;
      }

      .left {
        flex: 1;
      }

      .right {
        flex: 1;
        margin: auto;

        &.narrow {
          margin-left: 4.25rem;
        }
      }

      .icon-bubble {
        border-radius: 50%;
        border: 1px solid white;
        background-color: var(--light-blue);
        box-shadow: var(--small-container-box-shadow);

        --icon-diameter: 60px;
        min-width: var(--icon-diameter);
        max-width: var(--icon-diameter);
        min-height: var(--icon-diameter);
        max-height: var(--icon-diameter);

        display: flex;
        align-items: center;
        justify-content: center;

        margin-top: 5px;
        margin-right: -5px;
      }

      .button-area {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 14px;

        h2 {
          font-size: 2.25rem;
          margin: 0px 5px;
        }

        .button-group {
          display: flex;
          margin-top: auto;
          padding: 20px 0px;

          button:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }

      ul {
        padding: 0;
        margin: 0;

        li {
          list-style: none;

          &:not(:last-child) {
            margin-bottom: 1rem;
          }
        }
      }
    }

    .divider {
      width: 1px;
      border-right: 2px solid var(--font-gray);
      margin: 0px 1.5rem;
    }

    .image-layout {
      display: flex;
      flex-wrap: nowrap;

      img {
        max-width: 50%;
        max-height: 100%;

        object-fit: cover;
        background-size: cover;
        background-position: center;
      }
    }
  }
}
