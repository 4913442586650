.profitrover-card {
  background-color: #ffffff;

  border-radius: 4px;
  padding: 30px;

  box-shadow: 0px 3px 6px #00000029;
}

.profitrover-modal-card {
  background-color: #ffffff;

  border-radius: 4px;

  box-shadow: 0px 3px 6px #00000029;
}

.profitrover-modal-main {
  padding: 30px;
  padding-bottom: 18px;
}

.profitrover-modal-footer {
  padding: 24px 36px;
  border-radius: 0px 0px 4px 4px;
}

.profitrover-modal-footer .profitrover-button-base:not(:first-child) {
  margin-left: 6px;
}

.profitrover-modal-footer .profitrover-button-base:not(:last-child) {
  margin-right: 6px;
}
