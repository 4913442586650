.background-container {
  background: var(--light-blue);
  background: linear-gradient(70deg, var(--light-blue) 0%, var(--background-gray) 50%);

  img.profitrover-logo {
    width: 100px;
    padding-bottom: 10px;
  }

  .profitrover-text-subtitle {
    font-weight: 500;
    font-size: 22px;
    color: var(--dark-green);

    /* Enables margin-top and margin-bottom to be applied to a <span> */
    display: inline-block;

    @media screen and (max-width: 680px) {
      font-size: 14px;
      margin-top: 10px;
    }
  }
}
