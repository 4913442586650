.eo-price-fcst-filter-controls-collapse-outer-container {
  display: flex;
  align-items: center;
  margin-top: 0px;
}

.eo-price-fcst-filter-controls-collapse-inner-container {
  display: flex;
  align-items: center;
  margin-right: 3px;
}

.eo-price-fcst-filter-controls-collapse-button {
  position: absolute;
  margin-top: 50px;
  padding: 0px;
  color: white;
}

.eo-price-fcst-filter-controls-collapse-button-tooltip {
  margin-top: 10px;
}

.eo-price-fcst-filter-controls-collapse-button-tooltip .tooltip-inner {
  background-color: var(--medium-cool-gray);
  opacity: 1;
}

.eo-price-fcst-filter-controls-collapse-button-tooltip .arrow::before {
  border-left-color: var(--medium-cool-gray);
}

.eo-price-fcst-filter-controls-collapse-circle {
  position: absolute;
  width: 23px;
  height: 23px;
}

.eo-price-fcst-filter-controls-collapse-chevron {
  position: absolute;
  width: 20px !important;
  margin-top: 4px;
}

.eo-price-fcst-collapsed-left-container .rotated-label {
  font-size: 18px;
  margin-top: 100px;
  transform: rotate(270deg);
}

.eo-price-fcst-left-container {
  flex: 1;
  background: white;

  display: flex;
  flex-direction: column;

  box-shadow: var(--large-container-box-shadow);
  border-radius: 8px;

  border-right: 2px solid rgba(73, 170, 224, 0);
}

.eo-price-fcst-left-container > * {
  padding-left: 16px;
  padding-right: 10px;
}

.eo-price-fcst-graph-container {
  position: relative;
  background: white;
  flex: 1;
  min-height: 120px;
  border-radius: var(--bubble-border-radius);
  box-shadow: var(--small-bubble-box-shadow);

  /* Establish stacking context */
  z-index: 0;
}

.eo-price-fcst-graph-container .no-graph-data {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.eo-price-fcst-graph-container .icon {
  /* This z-index allows the graph settings button to float above the SVG graph container and be clickable */
  z-index: 1;

  padding: 0px 2px;
  margin: 7px 7px;
  outline: none;
}
.eo-price-fcst-graph-container .icon.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.eo-price-fcst-graph-settings-container {
  padding: 4px 15px;
  user-select: none;
}

.eo-price-fcst-graph-settings-container .top-row.settings-title {
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 5px;
}

.eo-price-fcst-graph-settings-container .setting-title {
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  padding-bottom: 5px;

  color: var(--dark-green);
}

.eo-price-fcst-graph-settings-container .bottom-row.settings-sections {
  display: flex;
}

.eo-price-fcst-graph-settings-container .col {
  max-width: 50%;
  padding: 0px;
}

.eo-price-fcst-graph-settings-container .radio-setting-selection label {
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  cursor: pointer;
}

.eo-price-fcst-graph-settings-container .radio-setting-selection label span {
  margin-left: 5px;
  padding-bottom: 1px;
}

.eo-price-fcst-right-container > :not(:last-child) {
  margin-bottom: 8px;
}

.eo-price-dim-label-and-filter-actions-row {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.eo-price-dim-label-and-filter-actions-row .eo-price-dim-preview-name {
  font-weight: 500;
  font-size: 18px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dim-value-tooltip.tooltip {
  opacity: 1;
}

.dim-value-tooltip.tooltip .arrow {
  opacity: 1;
}

.dim-value-tooltip .arrow:before {
  border-top-color: #fcfcfc;
}

.dim-value-tooltip {
  font-weight: 500;
  font-size: 14px;
}

.dim-value-tooltip .tooltip-row:not(:last-child) {
  margin-bottom: 2px;
}

/* Ensure a minimum gap between the left and right aligned text snippets */
.dim-value-tooltip .tooltip-row > div:first-of-type {
  margin-right: 15px;
}

.dim-value-tooltip .dim-value-label {
  font-weight: 600;
}

.dim-value-tooltip .tooltip-inner {
  background-color: #fcfcfc;
  color: #000000;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 1px 6px #0000003e;
  margin-left: 80px;
  min-width: max-content;
}

.dim-value-tooltip .price-tag-icon {
  margin-left: 5px;
  height: 100%;
}

.dim-value-tooltip .small-price-tag-icon .small-price-tag-content {
  padding: 1.2px 3px;
  height: 100%;
}

.dim-value-uplift-bars-container:after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 16px;

  width: calc(100% - 48px);
  height: 2em;
}

.eo-price-back-button {
  width: 30px;
  height: 25px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #a0a0a0;
  border-radius: 3px;
}

.eo-price-toggle-expansion-button {
  width: 25px;
  height: 25px;

  margin-right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #a0a0a0;
  border-radius: 3px;
}

.reset-all-filters-button:not(:disabled):hover,
.eo-price-toggle-expansion-button:not(:disabled):hover,
.eo-price-back-button:not(:disabled):hover {
  background-color: var(--background-gray);
}

.reset-all-filters-button:focus,
.eo-price-toggle-expansion-button:active,
.eo-price-back-button:active {
  outline: none;
}

.eo-price-toggle-expansion-button.hidden {
  background: none;
  border: none;
  box-shadow: none;
  cursor: unset;
}

.eo-price-filtering-indicator-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 25px;
  min-height: 23px;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
}

.eo-price-filtering-indicator-btn.hidden {
  visibility: hidden;
}

/* TODO: Move this to generic-components.css */
.profitrover-selection-checkbox {
  width: 14px;
  min-width: 14px;
  height: 15px;

  padding: 0;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid black;
  border-radius: 2px;
}

.select-all-text {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.select-all-text:hover {
  opacity: 0.8;
}

.selection-warning {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  font-weight: 400;
}

.dim-values-header-row {
  display: flex;
  font-weight: bold;
}

.dim-values-header-row span {
  display: flex;
  padding: 0px 7px;
}

.dim-values-header-row span:not(:last-of-type) {
  border-right: 2px solid #dedede;
}

.dim-values-header-row .dim-select-all {
  width: 215px;
  padding-left: 0px;
  flex-shrink: 0;
}

.eo-price-dim-filter-menu .single-dim-value-row:not(:last-child) {
  margin-bottom: 4px;
}

.single-dim-value-row {
  cursor: pointer;
}

.single-dim-value-row > :not(:last-child) {
  margin-right: 12px;
}

.single-dim-value-row .dim-value-name {
  font-size: 14px;
  font-weight: 500;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-warning .auto-upgrade a {
  color: var(--light-green);
}
