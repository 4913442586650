.price-elasticity-dashboard {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  padding-left: 300px;
  padding-top: 100px;
  width: 100%;
}

.price-elasticity-dashboard .heading-label {
  font-weight: bold;
}

.pe-download-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
