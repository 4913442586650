.hours-pane {
  flex-grow: 1;
  min-height: 0px;
  padding: 0;
  overflow: auto;

  .hours-tab {
    --summary-width: 310px;
    --card-padding: 1rem;

    width: 100%;
    min-height: 100%;
    padding-bottom: 1rem;

    display: flex;
    flex-direction: column;

    .hours-card {
      position: relative;
      flex-grow: 1;
      min-height: 500px;
      padding: 0;
      display: flex;
      flex-direction: row;
      width: 100%;
      min-width: fit-content;

      .hours-card-content {
        width: 100%;
        min-width: fit-content;
        min-height: 100%;
        display: flex;
        flex-direction: row;
        padding: 1rem;
        gap: 0.75rem;

        @media (max-width: 1810px) {
          flex-direction: column;
          gap: 2rem;
        }

        .divider {
          height: 100%;
          width: 1px;
          background: var(--light-cool-gray);
          display: flex;

          @media (max-width: 1810px) {
            width: 100%;
            height: 1px;
          }
        }

        .section {
          display: flex;
          flex-direction: column;
          align-items: center;

          .header {
            text-align: center;
            color: var(--font-black);
            text-wrap: wrap;
            word-break: break-word;

            h2 {
              font-size: 1.5rem;
              font-weight: 600;
              margin-bottom: 0.25rem;
            }

            h3 {
              font-size: 1.1rem;
              font-weight: 400;
              margin: 0;
            }
          }
        }

        .table-container {
          margin-top: 0.5rem;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          .overall-summary {
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid var(--light-cool-gray);
            border-radius: 6px;
            padding: 0.4rem;
            font-size: 14px;
            color: var(--font-gray);
            box-shadow: 0px 3px 6px #00000029;

            .uplift {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 4px;

              font-weight: 600;
              font-size: 28px;
              line-height: 28px;
              margin-top: 4px;
            }

            .percentage {
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              margin: 6px 0;
            }

            .header {
              font-size: 18px;
              font-weight: 600;
            }
          }
        }

        .positive {
          color: var(--success-green);
          .triangle {
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 12px solid var(--success-green);
          }
        }

        .negative {
          color: var(--error);

          .triangle {
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 12px solid var(--error);
          }
        }

        .updated-hour {
          font-size: 15px;
          font-weight: 600;
        }

        .hours-and-revenue-table {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;

          border: 1px solid var(--light-cool-gray);
          border-radius: 3px;

          font-size: 14px;
          text-align: center;

          box-sizing: content-box;

          .header {
            background-color: var(--mid-green);
            border-radius: 3px 3px 0 0;
            color: var(--font-white);
            font-weight: 600;
            min-height: 49px;
            position: relative;

            .arrow-button {
              --size: 19px;
              position: absolute;
              color: var(--font-white);
              padding: 0;
              font-size: 14px;
              top: 50%;
              transform: translateY(-50%);
              display: flex;
              align-items: center;
              justify-content: center;

              &:focus {
                outline: none;
              }

              &:disabled {
                cursor: not-allowed;
              }

              border: 1px solid white;
              background-color: var(--mid-green);
              color: white;
              width: var(--size);
              height: var(--size);
              border-radius: 50%;
              transition: background-color 0.2s, border-color 0.2s;

              &:hover:not(:disabled) {
                background-color: var(--light-green);
                border-color: var(--light-green);
                color: var(--mid-green);
              }

              &.left {
                margin-left: -8px;
              }

              &.right {
                margin-left: calc(8px - var(--size));
              }
            }

            .next-day {
              position: absolute;
              right: 0;
              left: 0;
              bottom: 2px;
              font-size: 9px;
              font-weight: normal;
            }
          }

          .row {
            padding: 0;

            &:nth-child(even) {
              background-color: #dde6f6;
            }
          }

          .row:not(.header) {
            height: 45px;
          }

          .col {
            padding: 0.2rem 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &:not(:last-child) {
              border-right: 1px solid var(--light-cool-gray);
            }

            &.error {
              color: var(--error);
              font-weight: 600;
            }
          }

          .col.hours {
            overflow: hidden;
          }

          .stretch {
            flex-grow: 1;
          }

          .current-hours {
            font-size: 15px;
          }

          .hour-field-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;
          }

          .updated-hour {
            position: absolute;
            top: 4px;
            left: calc(50% + 22px);
          }

          .hour-bar-container {
            width: 100%;
            display: flex;
            position: relative;

            .hour-bar {
              display: flex;
              background: var(--light-blue);
              border-radius: 3px;

              &::before {
                content: '||';
                position: absolute;
                top: 4px;
                left: -2px;
                color: var(--font-white);
                letter-spacing: -2px;
                font-weight: 300;
                font-size: 17px;
                line-height: 17px;
                transform: scaleX(0.5);
                pointer-events: none;
              }

              &::after {
                content: '||';
                position: absolute;
                top: 4px;
                right: 0px;
                color: var(--font-white);
                letter-spacing: -2px;
                font-weight: 300;
                font-size: 17px;
                line-height: 17px;
                transform: scaleX(0.5);
                pointer-events: none;
              }
            }

            .handle {
              cursor: ew-resize !important;
              width: 15px !important;
            }

            .handle-hidden {
              display: none !important;
            }

            .recommended-bar,
            .current-bar {
              position: absolute;
              display: inline-block;
              top: 0px;
              left: 0px;
              box-sizing: border-box;
              flex-shrink: 0;
              background: var(--light-green);
              border-radius: 3px;
            }

            .current-bar {
              background: var(--font-gray);
            }
          }

          .hours-of-operation-input {
            position: relative;

            .hour-input {
              width: 110px;
              border: 1px solid var(--light-cool-gray);
              outline: none;
              box-shadow: 0px 1px 2px #00000029;
              border-radius: 4px;
              transition: border-color 0.3s;
              padding: 4px 24px;
              cursor: pointer;

              &:focus {
                border-color: var(--light-green);
              }

              &:disabled {
                opacity: 0.6;
                cursor: not-allowed;
              }
            }

            .error {
              border-color: var(--error);
            }

            .hidden {
              background-color: var(--light-cool-gray);
              color: transparent;

              &::placeholder {
                color: transparent;
              }

              &:disabled {
                background-color: #ececec;
              }
            }

            .options-container {
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
            }

            .next-day {
              padding: 0;
              padding-bottom: 2px;
            }
          }
        }

        .table-bottom {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 0.75rem;

          .updated-container {
            flex: 1;
            display: flex;
            justify-content: flex-start;
          }

          .overall-container {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            font-size: 16px;
            font-weight: 600;
            color: var(--font-black);
            text-align: center;

            .overall-row {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
            }

            .overall-row.uplift {
              margin-top: 0.1rem;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 4px;
            }
          }
        }

        .reset-container {
          margin-top: 1rem;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1rem;

          font-size: 16px;
          color: var(--font-black);
        }

        .error-message {
          width: 100%;
          height: 24px;
          margin-top: 0.5rem;
          text-align: left;
          color: var(--error);
        }

        .tab-toggle-container {
          align-self: flex-start;
          display: flex;
          border: 1px solid var(--light-cool-gray);
          border-radius: 4px;
          padding: 0;

          .toggle-button {
            all: unset;

            width: 60px;
            border-radius: 4px;
            padding: 5px 0;

            cursor: pointer;

            &:disabled {
              cursor: not-allowed;
              opacity: 0.8;
            }

            p {
              text-align: center;
              color: var(--font-black);
              font-size: 16px;
              font-weight: 500;
              line-height: 1.1rem;
              margin: 0;
            }
          }

          .toggle-button-selected {
            background-color: var(--mid-green);

            p {
              color: #f7f7f7;
            }
          }

          .Icon {
            margin: 0;
          }
        }

        .bars-legend {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 1rem;

          .item {
            display: flex;
            flex-direction: row;
            gap: 0.25rem;
            align-items: center;

            .icon {
              width: 15px;
              height: 15px;
              position: relative;
            }

            .icon.current {
              background: var(--font-gray);
            }

            .icon.recommendations {
              background: var(--light-green);
            }

            .icon.new {
              background: var(--light-blue);
            }
          }
        }

        .days-section {
          flex: 1;
        }

        .details-section {
          flex: 1;

          --viz-width: 700px;

          .header {
            margin-bottom: 1.5rem;

            .selector-container {
              position: relative;

              .arrow-button {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: var(--font-black);

                &:focus {
                  outline: none;
                }

                &.left {
                  left: 0;
                }

                &.right {
                  right: 0;
                }
              }

              .revenue-uplift-select-theme {
                .k-theme__control {
                  .k-theme__value-container {
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                  }

                  .k-theme__indicators {
                    .k-theme__dropdown-indicator {
                      display: none;
                    }
                  }
                }
              }
            }
          }

          .viz-container {
            width: 100%;
            max-width: var(--viz-width);
            display: flex;
            overflow: hidden;
            height: 220px;
          }

          .hour-ticks {
            --bar-height: 10px;
            display: flex;
            align-items: center;
            position: relative;
            width: calc(var(--viz-width) - 100px);
            height: calc(2 * var(--bar-height) + 1px);
            min-height: calc(2 * var(--bar-height) + 1px);
            overflow: hidden;

            .bar {
              height: var(--bar-height);
              position: absolute;
              border-radius: 3px;

              &.new-hours {
                top: 0;
                background: var(--light-blue);
              }

              &.current {
                top: calc(var(--bar-height) + 1px);
                background: var(--font-gray);
              }

              &.recommended {
                top: calc(var(--bar-height) + 1px);
                background: var(--light-green);
              }
            }
          }

          .legend {
            margin-top: 0.75rem;
            width: var(--viz-width);
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1.5rem;
            position: relative;

            .legend-item {
              display: flex;
              align-items: center;
              gap: 6px;

              .color {
                width: 20px;
                height: 3px;
              }
            }

            .bars-graph-legend {
              position: absolute;
              top: -38px;
              left: -35px;
              text-align: right;
              .item {
                width: 76px;
                height: 10px;
                font-size: 12px;
                margin-bottom: 2px;
              }
            }
          }

          .graph-sources-table {
            margin-top: 2rem;

            .row {
              flex-wrap: nowrap;
            }

            .row.header {
              margin: 0;
            }
          }
        }
      }
    }

    input[type='checkbox'] {
      accent-color: var(--light-blue);
      cursor: pointer;
    }
  }
}
