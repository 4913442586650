.data-source-settings {
  background: var(--background-gray);

  .nav-tabs.settings-tabs {
    .nav-item {
      border: none;
      background: transparent;
      color: var(--font-black);

      padding: 0px;
      font-size: 18px;
      line-height: 20px;
    }

    .nav-item:not(:last-of-type) {
      margin-right: 26px;
    }

    .active {
      border-bottom: 2px solid black;
      color: black;
      font-weight: 700;
    }
  }

  .instructions-line-1 {
    font-size: 14px;
    margin-top: 5px;
  }

  .instructions-line-2 {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .section-header {
    font-size: 18px;
    color: (--font-black);
  }

  .config-table-heading {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }

  .config-table-bulk-action {
    font-size: 14px;
    color: var(--light-blue);
    cursor: pointer;
  }

  .confirm-settings-button {
    margin-top: 2rem;
  }
}

.config-area-container {
  display: flex;

  .config-table-heading {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    .config-table-title {
      font-size: 18px;
      font-weight: bold;
    }

    .config-table-bulk-action {
      font-size: 14px;
      color: var(--light-blue);
      cursor: pointer;
    }
  }

  .item-descriptor-add-divider {
    height: 1px;
    border: 0.5px dotted;
    margin: 10px;
  }

  .config-table-accordion-container {
    background: white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    font-size: 12px;

    .delete-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 80px;

      svg {
        cursor: pointer;
        color: var(--font-gray);
      }
    }

    .field-dotted-divider {
      border-bottom: 0.5px dotted;
      padding-bottom: 10px !important;
    }

    .dataset-accordion-field-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px;
      flex-wrap: wrap;

      .accordion-field-container-row {
        display: flex;
        flex-direction: column;
        margin-left: 2px;
        margin-right: 2px;

        .price-field-container {
          display: flex;
        }

        .price-type-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .price-type-option {
          display: flex;
          align-items: center;
        }

        .field-content-container {
          margin: 4px;
          min-width: 150px;

          .item-descriptor-container {
            min-width: 220px;
          }

          .location-selection-container {
            min-width: 220px;
          }

          .field-details-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 5px;
          }

          .field-selection-column-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 5px;
            margin: 5px;

            .field-select-text {
              font-style: italic;
            }

            .field-input-select {
              min-width: 150px;
              min-height: 35px;
            }

            .field-time-zone-text {
              display: inherit;
              margin-left: 2px;
              margin-right: 2px;
            }
          }

          .accordion-field-divider {
            height: 1px;
            border: 0.5px dotted;
            width: 200px;
          }

          .accordion-field-details-divider {
            height: 1px;
            border: 0.5px dotted;
            min-width: 300px;
          }
        }
      }
    }

    .accordion {
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: white;
    }

    .accordion__heading {
      background: white;
      max-width: 100%;
      padding: 3px 0px 0px 0px;
    }

    .accordion__button {
      background: #adadad66;
      color: #444;

      cursor: pointer;
      text-align: left;

      padding: 9px 9px;
      border: none;
      width: 100%;
      max-height: 51px;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .accordion__button > span {
      font-size: 14px;
      font-weight: bold;
    }

    .accordion__button svg {
      max-width: 100px;
    }

    .accordion__button:hover {
      background-color: #dedede66;
    }

    .accordion__button[aria-expanded='true'] {
      background: #adadad66;
    }

    .accordion-toggle-expansion-button {
      width: 25px;
      height: 25px;

      margin-left: 10px;
      margin-right: 10px;

      display: flex;
      align-items: center;
      justify-content: center;

      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #a0a0a0;
      border-radius: 3px;
    }

    .accordion-toggle-expansion-button.expanded {
      transform: rotate(270deg);
    }

    [hidden] {
      display: none;
    }

    .accordion__panel {
      padding: 20px 10px;
      animation: fadein 0.2s ease-in;
    }

    .accordion__panel ol {
      padding-left: 15px;
      font-size: 14px;
    }

    .accordion__panel ol li {
      margin-bottom: 20px;
    }

    /* -------------------------------------------------- */
    /* ---------------- Animation part ------------------ */
    /* -------------------------------------------------- */

    @keyframes fadein {
      0% {
        opacity: 0.1;
      }

      100% {
        opacity: 1;
      }
    }
  }

  .config-table-form {
    width: 65%;
    margin-right: 12px;
  }

  .dataset-samples-container {
    position: sticky;
    top: 131px;
    max-height: 430px;
    max-width: 35%;

    .dataset-samples-table-heading {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      margin-left: 12px;
    }

    .dataset-samples-table-title {
      font-size: 18px;
      font-weight: bold;
    }

    @media only screen and (max-width: 900px) {
      font-size: 0.6rem;
    }

    @media only screen and (min-width: 900px) {
      font-size: 0.8rem;
    }

    .dataset-samples-table-container {
      background: white;
      max-height: 430px;
      overflow: auto;
      margin-left: 12px;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 4px;

      table.dataset-samples-table th {
        min-width: 80px;
        font-weight: 700;
        text-align: center;
        background: #f5f6fa;
        padding: 0.5em;
        border: none;

        position: sticky;
        top: 0; /* Required for the stickiness */
      }

      table.dataset-samples-table td {
        text-align: center;
        padding: 0.5em;
        border: none;
      }

      table.dataset-samples-table td > div {
        outline: none;
      }
    }
  }

  input[type='checkbox'] {
    accent-color: var(--light-blue);
  }
}
