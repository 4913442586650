.generic-form-container {
  padding-top: 65px;
  padding-bottom: 65px;
  margin-left: 140px;
  max-width: 900px;
}

.generic-page-header {
  .underline-container {
    display: flex;
    flex-direction: column;
    width: fit-content;

    span {
      color: var(--font-black);
      font-size: 24px;
      font-weight: 700;
    }

    .underline {
      margin-top: 3px;
      border-bottom: 2px solid #d9d9d9;
      box-shadow: 0px 3px 6px #00000029;
    }
  }
}

.generic-form-subheader {
  font-size: 20px;
  font-weight: 500;
  color: var(--font-black);
}

.generic-form-section-header {
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 500;
  color: #3e3e3e;

  display: flex;
  align-items: center;
}

.generic-form-container > hr {
  margin-top: 10.5px;
  margin-bottom: 17.5px;
  background-color: #707070;
  opacity: 0.2;
}

.generic-form-prompt-text {
  margin-bottom: 15px;
  font-size: 16px;
  text-align: left;
  letter-spacing: 0px;
  color: #3e3e3e;
}

.generic-form-radio-row {
  display: flex;
  margin-bottom: 45px;
}

.generic-form-radio-row > :not(:last-child) {
  margin-right: 15px;
}

.generic-form-radio-column {
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 25px;
}

.generic-form-radio-column > :not(:last-child) {
  margin-bottom: 10px;
}

.generic-form-lg-radio {
  padding: 0px 10px;
  height: 54px;
  min-width: 120px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.generic-form-lg-radio.selected {
  border: 2px solid #ffffff;
  background: #e2e2e2 0% 0% no-repeat padding-box;
}

.generic-form-sm-radio {
  padding: 0px 10px;
  height: 28px;
  min-width: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 14px;
}

.generic-form-sm-radio.selected {
  background: #eaeaea 0% 0% no-repeat padding-box;
}

.generic-form-action-btn {
  display: block;
  padding: 0px 10px;
  min-width: 100px;
  height: 41px;
  color: black;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  border: none;
  background: #fec52d 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #0000004e;
  border-radius: 4px;

  /* Helps the page not abrubtly end at the bottom edge of the button */
  margin-bottom: 40px;
}

.generic-form-cancel-button {
  /* z-index ensures this is clickable when inside a container element */
  z-index: 1;
  position: absolute;
  top: -5px;
  right: -5px;

  outline: 0;
  display: flex;
  align-items: center;
  user-select: none;
}

.generic-form-cancel-button:hover {
  text-decoration: underline;
  cursor: pointer;
}

span.generic-form-cancel {
  height: 30px;
  width: 30px;
  background-color: #4e4e4e;
  border-radius: 50%;
  position: relative;
}

span.generic-form-cancel:after {
  content: '\d7';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 30px;
  color: #fff;
  line-height: 24px;
  text-align: center;
}

.generic-form-dropdown-select {
  padding: 0px 4px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #3e3e3e;
  min-height: 40px;
  min-width: 100px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000058;
  border-radius: 2px;
  border: 0px solid #707070;
  outline: 0px;
}

.generic-form-text-input {
  display: flex;
  align-items: center;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000058;
  border: 1px solid #3e3e3e;
  border-radius: 2px;

  box-sizing: border-box;
}

/* Moves default focus styling to wrapper div */
.generic-form-text-input:focus-within {
  border: 2px solid black;
}

.generic-form-text-input input {
  width: 100%;
  height: 100%;
  border: none;
}

.generic-form-text-input input:focus {
  border: none;
  outline: none;
}

.generic-form-textarea {
  display: flex;
  align-items: center;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000058;
  border: 1px solid #3e3e3e;
  border-radius: 2px;

  box-sizing: border-box;
}

/* Moves focus styling to wrapper div */
.generic-form-textarea:focus-within {
  border: 2px solid black !important;
}

.generic-form-textarea textarea {
  width: 100%;
  height: 100%;
  border: none;
}

.generic-form-textarea textarea:focus {
  border: none;
  outline: none;
}

.generic-form-selection-box {
  display: flex;
  align-items: center;
  padding: 0px 7px;
  font-size: 16px;
  font-weight: 500;
  color: #3e3e3e;
  min-height: 40px;
  min-width: 178px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000002f;
  border-radius: 2px;
  border: 0px solid #707070;
  outline: 0px;
}

.generic-form-edit-btn {
  color: #287fe5;
  font-size: 16px;
  margin-left: 15px;
  text-decoration: underline;
  cursor: pointer;
  outline: 0px;
}

.generic-form-custom-date-picker .input {
  padding: 5px !important;
  height: 100%;
}

.generic-form-custom-date-picker {
  margin: 0px 10px 0px 15px;
}

.generic-form-custom-date-picker .input-wrapper {
  border-radius: 2px;
  padding-left: 4px;
  border: 1px solid #a3a3a3;
}

.generic-form-custom-date-picker .input-wrapper:focus {
  box-shadow: 0px 3px 6px #00000029;
}

.generic-form-custom-date-picker .Icon {
  margin: 5px 0px 2px 5px;
}

.generic-form-container input:focus,
.generic-form-container select:focus,
.generic-form-container button:focus,
.generic-form-lg-radio:focus,
.generic-form-sm-radio:focus {
  outline: none;
}

.generic-vertical-separator {
  margin-left: 10px;
  margin-right: 10px;
}
