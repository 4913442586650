.profitrover-alpha-numeric-label {
  height: 24px;
  min-width: 24px;
  font-size: 16px;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 3px #00000067;
  border: 2px solid #f6c12c;
  border-radius: 50%;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
