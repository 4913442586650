.time-period-popover-container {
  background: white;
  box-shadow: var(--large-container-box-shadow);

  padding: 0px 0px 4px 0px;
  border-radius: 8px;

  .rdrMonthPicker select,
  .rdrYearPicker select {
    text-align: left;
  }

  .rdrDefinedRangesWrapper {
    border-top-left-radius: 8px;

    .rdrStaticRange:first-child {
      border-top-left-radius: 8px;
    }
  }
}

#time-period-picker-popover {
  max-width: unset;
}

.date-range-picker-anchor {
  background: white;
  box-shadow: 0px 1px 2px #00000029;

  white-space: nowrap;

  height: 24px;
  margin-right: 12px;
  border: 1px solid var(--light-cool-gray);
  border-radius: 4px;
  padding: 0px 6px;

  font-size: 14px;
  color: #606060;

  &:hover:not(:disabled) {
    background-color: var(--background-gray);
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:focus {
    background-color: white;
    box-shadow: 0px 1px 6px #ffc531bf;
    outline: none;
  }
}
