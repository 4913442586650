.ds-config-container {
  padding-top: 65px;
  padding-bottom: 65px;
  margin-left: 140px;
  max-width: 900px;
}

.ds-config-header {
  padding-top: 20px;
  margin-bottom: 0px;
  color: #3e3e3e;
}

.ds-config-subheader {
  font-size: 20px;
  color: #3e3e3e;
}

.ds-config-container .profitrover-alpha-numeric-label {
  margin-right: 7px;
  padding-bottom: 1.5px;
}

.ds-config-section-header {
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 500;
  color: #3e3e3e;

  display: flex;
  align-items: center;
}

.ds-config-container > hr {
  margin-top: 10.5px;
  margin-bottom: 17.5px;
  background-color: #707070;
  opacity: 0.2;
}

.ds-config-prompt-text {
  margin-bottom: 15px;
  font-size: 16px;
  text-align: left;
  letter-spacing: 0px;
  color: #3e3e3e;
}

.ds-config-radio-row {
  display: flex;
  margin-bottom: 45px;
}

.ds-config-radio-row > :not(:last-child) {
  margin-right: 15px;
}

.ds-config-radio-column {
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 25px;
}

.ds-config-radio-column > :not(:last-child) {
  margin-bottom: 10px;
}

.ds-config-lg-radio {
  padding: 0px 10px;
  height: 54px;
  min-width: 120px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ds-config-lg-radio.selected {
  border: 2px solid #ffffff;
  background: #e2e2e2 0% 0% no-repeat padding-box;
}

.ds-config-sm-radio {
  padding: 0px 10px;
  height: 28px;
  min-width: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 14px;
}

.ds-config-sm-radio.selected {
  background: #eaeaea 0% 0% no-repeat padding-box;
}

.ds-config-action-btn {
  display: block;
  padding: 0px 10px;
  min-width: 100px;
  height: 41px;
  color: black;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  border: none;
  background: #fec52d 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #0000004e;
  border-radius: 4px;

  /* Helps the page not abrubtly end at the bottom edge of the button */
  margin-bottom: 40px;
}

.ds-config-cancel-button {
  /* z-index ensures this is clickable when inside a container element */
  z-index: 1;
  position: absolute;
  top: -5px;
  right: -5px;

  outline: 0;
  display: flex;
  align-items: center;
  user-select: none;
}

.ds-config-cancel-button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.ds-config-dropdown-select {
  padding: 0px 4px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #3e3e3e;
  min-height: 40px;
  min-width: 100px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000058;
  border-radius: 2px;
  border: 0px solid #707070;
  outline: 0px;
}

.ds-config-text-input {
  height: 42px;
  min-width: 200px;
  padding: 0px 7px;
  margin-bottom: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000058;
  border: 1px solid #3e3e3e;
  border-radius: 2px;
}

.ds-config-selection-box {
  display: flex;
  align-items: center;
  padding: 0px 7px;
  font-size: 16px;
  font-weight: 500;
  color: #3e3e3e;
  min-height: 40px;
  min-width: 178px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000002f;
  border-radius: 2px;
  border: 0px solid #707070;
  outline: 0px;
}

.ds-config-edit-btn {
  color: #287fe5;
  font-size: 16px;
  margin-left: 15px;
  text-decoration: underline;
  cursor: pointer;
  outline: 0px;
}

.ds-config-container input:focus,
.ds-config-container select:focus,
.ds-config-container button:focus,
.ds-config-lg-radio:focus,
.ds-config-sm-radio:focus {
  outline: none;
}

.workflow-loading-spinner {
  height: 75vh;
  margin-left: 5px;
}

.dimension-post-error-message {
  color: var(--error);
  margin-top: 37px;
  margin-left: 20px;
}
