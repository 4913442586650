.staffing-pane {
  flex-grow: 1;
  min-height: 0px;
  overflow-y: auto;

  .staffing-tab {
    --summary-width: 310px;
    --card-padding: 1rem;

    width: 100%;
    min-height: 100%;
    padding-bottom: 1rem;

    display: flex;
    flex-direction: column;

    .staffing-card {
      position: relative;
      height: 0;
      flex-grow: 1;
      min-width: 650px;
      min-height: 500px;
      padding: 0;

      .staffing-card-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;

        // Safari fix for component sizing:
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    .summary-title {
      font-size: 1.3rem;
      font-weight: 600;
      margin: 0;
      text-wrap: wrap;
      word-break: break-word;
    }

    .subtitle {
      font-size: 1.1rem;
      font-weight: 500;
      margin: 0;
    }

    .period-summary {
      box-sizing: content-box;
      width: var(--summary-width);
      padding: var(--card-padding);
      height: calc(100% - 2 * var(--card-padding));
      display: flex;
      flex-direction: column;

      .k-theme__menu {
        z-index: 2;
      }

      .table-container {
        box-sizing: content-box;
        width: 100%;
        flex: 1;
        height: 0;
        display: flex;
        flex-direction: column;
        overflow: visible;
        margin-top: 0.75rem;

        .table {
          position: relative;
          box-sizing: content-box;
          width: 100%;
          max-height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          overflow-x: visible;
          overflow-y: auto;

          border-radius: 4px;
          box-shadow: 0px 2px 8px #b3b3b372;
          border: 0.5px solid #babfc7;
          text-align: center;
          margin: 0;
        }

        .row {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding: 0;
          cursor: pointer;

          &:focus {
            outline: 0.5px solid var(--light-green);
            outline-offset: -1px;
          }

          &:disabled {
            cursor: default;
          }
        }

        .row.active {
          outline: 2px solid var(--light-green);
          outline-offset: -2px;

          .value {
            font-weight: 600;
            color: var(--font-black);
          }
        }

        .header {
          position: sticky;
          top: 0;
          width: 100%;
          background-color: var(--lighter-background-gray);
          border-bottom: 1px solid #babfc7;
          z-index: 1;
        }

        .col {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0.2rem 0.4rem;
        }

        .date-col {
          width: 80px;
          max-width: 80px;
        }

        .label {
          margin: 0;
          font-size: 0.85rem;
          line-height: 1rem;
          font-weight: 600;
        }

        .value {
          color: var(--font-gray);
          margin-top: 0.2rem;
          font-size: 0.8rem;
          line-height: 1rem;
          white-space: nowrap;
          overflow: hidden;
        }

        .divider {
          margin: 0;
          background-color: #babfc7;
          width: 1px;
          height: 100%;
        }
      }
    }

    .divider {
      height: calc(100% - 2rem);
      margin: 1rem 0.5rem;
      width: 1px;
      background-color: var(--light-cool-gray);
    }

    .summary-filters {
      gap: 0.4rem;
      box-sizing: content-box;
      width: var(--summary-width);
    }

    .metric-button-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .plan-vs-recommended-stats {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;

      border-radius: 4px;
      box-shadow: 0px 2px 8px #b3b3b372;
      border: 0.5px solid #babfc7;

      text-align: center;
      font-size: 13px;
      line-height: 15px;
      font-weight: 500;
      color: var(--font-black);

      --column-padding: 0.25rem;

      .row {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0;

        &:not(:last-child) {
          border-bottom: 1px solid #babfc7;
        }

        &:disabled {
          cursor: default;
        }
      }

      .col {
        flex: 1;
        padding: var(--column-padding);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        white-space: wrap;
        overflow: hidden;

        &:not(:last-child) {
          border-right: 1px solid #babfc7;
        }
      }

      .col.double {
        flex: 2;
        margin-right: calc((var(--column-padding) * 2) + 1.5px);
        box-sizing: content-box;
      }

      .header {
        font-weight: 600;
        background-color: var(--lighter-background-gray);
      }

      .label {
        color: var(--font-gray);
        font-weight: 400;
      }
    }

    .day-summary {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      overflow-x: auto;
      padding: var(--card-padding);
      padding-left: 1.5rem;

      .day-stats-container {
        width: 100%;
        display: flex;

        .day-stats {
          min-width: var(--summary-width);
          width: var(--summary-width);
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          align-items: stretch;
        }
      }

      .hour-breakdown {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        position: relative;

        .button-container {
          width: 90px;
          display: flex;
          justify-content: flex-end;

          .undo-button {
            padding-right: 0.6rem;
            padding-left: 0.6rem;

            border: 1px solid var(--light-cool-gray);
            box-shadow: 0px 3px 6px #59595929;
            border-radius: 4px;

            display: flex;
            align-items: center;

            .text {
              color: var(--light-blue);
              font-size: 0.9rem;
              margin: 0;
            }

            &:disabled {
              cursor: not-allowed;
            }

            &:not(:disabled):hover {
              background-color: var(--dark-hover-gray);

              transition-property: background-color, fill, color;
              transition-duration: 0.1s;
              transition-timing-function: ease-out;
            }
          }
        }

        .metrics-container {
          display: flex;
          justify-content: center;
        }

        .viz-container {
          display: flex;
          overflow: hidden;
          height: 260px;
        }

        .chart-legend {
          display: flex;
          justify-content: center;
          gap: 1rem;

          .item {
            display: flex;
            align-items: center;
            gap: 0.25rem;
            font-size: 0.8rem;
            font-weight: 400;
            color: var(--font-gray);

            .color {
              width: 25px;
            }
          }
        }

        .table-container {
          .metric-container {
            position: absolute;
            top: 0.6rem;
            left: -190px;
            width: 180px;

            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 8px;
            font-size: 14px;
            line-height: 15px;
            color: var(--font-black);

            .metric {
              font-weight: 600;
            }
          }
        }
      }
    }

    .date-picker-container {
      margin: 0;
      width: 140px;
      max-height: 24px;
      border: 1px solid var(--light-cool-gray);
      border-radius: 4px;
      background: none;
      box-shadow: 0px 1px 2px #00000029;
      padding: 0 0.2rem;

      .date-picker {
        text-align: center;
        cursor: pointer;
      }

      .react-datepicker-wrapper {
        width: 100%;

        .react-datepicker__input-container {
          width: 100%;
          display: block;
          border: none;
          background: none;
          padding: 0 0.2rem;

          input {
            all: unset;
            width: 100%;
            font-size: 0.8rem;
            font-weight: 400;
            color: var(--font-gray);
          }
        }
      }

      .arrow-button {
        display: flex;
        align-items: center;
        padding: 0;

        &:disabled {
          opacity: 0.5;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}
