#welcome {
  padding-top: 75px;
  padding-bottom: 75px;
}

.welcome-page canvas {
  /* Make confetti appear above Bootstrap Modal's backdrop  */
  z-index: 1051 !important;
}

.row {
  padding-bottom: 10px;
}

.trial-banner {
  background-color: var(--background-gray);
  padding-top: 20px;
  padding-bottom: 15px;
  margin-left: calc(50% - 50vw);
  width: 100vw;

  animation: fadeIn 1.25s;
}

@keyframes fadeIn {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.trial-banner .container {
  padding-top: 0px !important;
}

.trial-banner .days-remaining {
  font-size: 24px;
  color: var(--font-black);
}

.trial-banner .days-remaining-value {
  font-weight: 600;
  text-decoration: underline;
}

.trial-banner .auto-upgrade {
  font-size: 18px;
  color: var(--font-black);
}

.trial-banner .auto-upgrade-date {
  font-weight: 600;
}

.trial-banner .end-trial-early-box {
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid var(--light-green-shadow);
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  display: flex;
  max-width: max-content;
}

.trial-banner .end-trial-early-button {
  display: flex;
}

.trial-banner .unlock-text {
  font-size: 18px;
  color: var(--font-black);
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.welcome-page-animated-outline {
  cursor: pointer;
  background: #ccc; /* Used as a fallback in case the image doesn't appear */
  background-size: 350%;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  width: 197px;
  height: 293px;

  padding: 0px 20px;

  font-weight: 600;
  font-size: 24px;
  color: white;
  text-shadow: 1px 2.5px #444444;

  max-width: 100%;
  line-height: 1.24;

  filter: contrast(120%);
}

.workflow-tile-header {
  width: 140px;
  position: absolute;
  left: 10px;
  top: 10px;
  filter: drop-shadow(0px 1px 1.5px #000000a4);
}

@media (max-width: 767px) {
  /* Smaller font when the image container resizes to be smaller via Bootstrap. */
  .service-image {
    font-size: 16px !important;
  }
}

#welcome li {
  text-align: left;
  padding-left: 10px;
  font-size: 14px;
  margin: 5px 0px;
  width: 100%;
}
.welcome-page-spinner {
  height: 50vh;
}

.edit-dim-icon {
  position: absolute;
  right: 5px;
  top: 1px;
}

.edit-dim-icon:hover {
  color: var(--simple-gray);
}
