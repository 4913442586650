.success-top-text {
  font-size: 24px;
  font-weight: 600;
  color: #020202;
}

.success-bottom-text {
  font-size: 18px;
  color: #020202;
}

.checkmark-container {
  padding-bottom: 25px;
  padding-top: 15px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.success-message-container {
  min-height: 280px;
  min-width: min(100%, 350px);
  max-width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
}
