.partner-instructions {
  ol {
    padding-left: 22px;

    li {
      margin-bottom: 20px;
    }
  }

  .post-integration-instructions {
    padding-left: 14px;
    font-weight: 700;
  }
}
