.settings-page-wrapper {
  min-height: 100%;
  overflow: visible;

  display: flex;
  justify-content: center;

  padding: 10px 5rem;

  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 1400px;
    margin: 0;
    padding: 0;
  }

  .settings-header {
    font-size: 28px;
    text-align: left;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .settings-subheader {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 28px;
  }

  .settings-error-message {
    color: var(--error);
    font-size: 16px;
    text-align: center;
    margin: 0;
  }

  .settings-card {
    max-width: 460px;
    width: 100%;
  }

  .settings-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .save-btn-container {
    display: flex;
    justify-content: center;
  }

  .input-container {
    margin-bottom: 16px;
    max-width: 320px;
    width: 100%;
  }

  input {
    background-color: white;
    border-radius: 4px;
    border: 1px solid #cccccc;
    font-size: 14px;
    line-height: 18px;
    padding: 4px 10px;
    color: var(--font-black);
    margin: 0;
  }

  .basic-multi-select {
    min-height: 40px;
  }

  .DateRangePicker {
    .DateRangePickerInput {
      min-height: 40px;
      border-radius: 4px;
      border: 1px solid #cccccc;
      padding: 4px 10px;
      color: var(--font-black);
    }

    .DateInput_input {
      background-color: transparent;
      border: none;
    }
  }

  .-pageSizeOptions {
    display: none;
  }

  .ReactTable .rt-noData {
    top: 54px;
    padding: 0 20px;
  }

  .settings-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    .label {
      font-size: 18px;
      font-weight: 400;
    }

    .radio-button-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      gap: 0.2rem;

      .item {
        margin-right: 0.3rem;
      }
    }

    .checkbox-container {
      display: flex;
      margin-bottom: 0.5rem;
    }
  }
}
