.login-page-container {
  height: 100vh;
  width: 100%;
  background: var(--light-blue);
  background: linear-gradient(70deg, var(--light-blue) 0%, var(--background-gray) 50%);
  text-align: center;
  padding-top: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form-container {
  display: inline-block;
  background-color: white;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  max-width: 445px;
  min-width: 280px;
  padding: 25px 20px 0px 20px;
  box-shadow: 0px 3px 6px #00000029;
  margin-top: 50px;
}

.form-signin {
  width: 100%;
  max-width: 445px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  padding: 10px;
  font-size: 16px;
}

.input-field-dynamic-label {
  font-size: 12px;
  color: #848484;
  position: absolute;
  background-color: white;
  top: -10px;
  left: 5px;
  padding: 0px 2px;
}

.form-signin input {
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0px 1px 4px #0000002e;
  padding: 5px;
  margin-bottom: 20px;
  color: var(--dark-green);
  font-size: 14px;
}

/* Override background color for autofilled inputs */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  box-shadow: 0 0 0 40px white inset;
}

.form-signin .password-visibility-icon {
  position: absolute;
  color: #848484;
  font-size: 14px;
  right: 3%;
  top: 32%;
}

.sign-in-error-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid var(--error);
  border-radius: 4px;
}

.sign-in-error-warning-box {
  display: flex;
  align-items: center;
  min-height: 60px;
  border-radius: 4px 0px 0px 4px;
  padding: 5px;
}

.sign-in-error-warning-icon {
  color: var(--error);
  width: 30px !important;
  height: 30px !important;
}

.sign-in-error-message-box {
  width: 100%;
  min-height: 60px;
  background: #b327110d 0% 0% no-repeat padding-box;
  border-left: 1px solid var(--error);
  padding: 5px;
  text-align: left;
}

img.login-profitrover-logo {
  width: 80px;
  margin-bottom: 10px;
}

img.forgot-page-profitrover-logo {
  width: 80px;
}

.form-signin-buttons {
  width: 100%;
  margin: 10px 0px;
}

.copyright-text {
  margin-top: 1rem;
  color: var(--font-gray);
}

/* TODO: CSS below this point should be either deleted or relocated */

p {
  margin-bottom: 5px;
}

label {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
}

.btn:disabled {
  background-color: grey;
  border-color: grey;
}

.InputLabel {
  padding: 6px 10px;
  margin: 0 0 10px;
}

.userSelect {
  margin: 0 0 10px;
  color: #555;
  background-color: #fff;
  border-color: #ccc;
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  width: 100%;
  max-width: 300px;
  height: 45px;
  font-size: 14px;
  padding: 6px 10px;
  box-sizing: border-box;
  outline: none;
  font-weight: 400;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  line-height: 1.3;
}

.userSelect.match {
  border-color: green;
}

.userSelect.mismatch {
  border-color: red;
}

.Icon {
  cursor: pointer;
  margin: 7px 5px 5px 5px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.reset-password-email-text {
  margin-top: 10px;
  margin-bottom: 20px;
}

.reset-password-success-text {
  font-size: 34px;
  color: var(--success-green);
  margin-top: 10px;
  margin-bottom: 20px;
}

.reset-password-error-text {
  font-size: 16px;
  color: var(--error);
  margin-bottom: 0.5rem;
}

.rebrand-banner {
  display: inline-block;
  background-color: white;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  max-width: 800px;
  min-width: 300px;
  padding: 25px 20px;
  box-shadow: 0px 3px 6px #00000029;

  display: flex;
  flex-direction: column;
  position: relative;
  margin: -15px 0;
}

.rebrand-banner .logos-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.rebrand-banner .logos-container .arrow-icon {
  color: var(--mid-green);
}

.rebrand-banner .logos-container .logo {
  width: 40%;
  object-fit: contain;
  height: 40px;
}

.rebrand-banner .rebrand-info {
  margin-top: 14px;
  font-size: 20px;
  color: var(--font-black);
}
