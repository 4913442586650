.manage-dim-val-mapping {
  width: 100%;
  max-width: 1350px;
  padding-bottom: 30px;
}

.manage-dim-val-mapping .filtering-section {
  max-width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}

.manage-dim-val-mapping .filtering-section > :not(:last-child) {
  margin-right: 15px;
}

.manage-dim-val-mapping .filter-selection-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
