/**
 * Developer Note: These colors should be kept in sync with colors.js
 */

/* Official, globally available CSS color variables (Usage: var(--light-green); ) */
:root {
  /* Primary colors */
  --light-green: #64f884;
  --light-green-hover: #8cfda4;
  --light-green-shadow: #81f89b;
  --medium-light-green: #4bcf6a;
  --mid-green: #0f4145;
  --dark-green: #072e31;

  /* Secondary colors */
  --light-blue: #0d729e;
  --light-blue-hover: #2a799c;
  --light-blue-shadow: #2c7b9d;
  --light-cool-gray: #b1b3b3;
  --medium-cool-gray: #63666a;

  /* Primary font colors */
  --font-black: #2e2e2e;
  --font-white: #ffffff;
  --font-gray: #747474;
  --long-form-font-gray: #585858;

  /* Background colors */
  --background-gray: #eaf7fd;

  /* Semantic colors */
  --success-green: #5fad56;
  --error: #b3270f;

  /* Others... */
  --simple-gray: #888888;
  --dark-hover-gray: #efefef;
}

/* Unofficial colors found in designs but not part of the official ProfitRover Design Guide */
:root {
  /* Background colors */
  --lighter-background-gray: #ececec;

  /* Primary font colors */
  --darker-font-black: #3e3e3e;
}
