.integration-setup-container {
  margin-top: 61px;
  padding-bottom: 1rem;
  width: 75%;
  max-width: 900px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.file-upload-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  // Ensures height doesn't collapse while spinner is shown
  min-height: 272px;
}

/**
 * TODO: Delete this .integration-setup-container selector when the old /integration/help page is removed
 */
.integration-setup-container {
  .underline-container {
    max-width: 900px;

    .underline {
      margin-top: -5px;
    }
  }
}

/**
 * This selector scopes its child styles to both the File Upload pages
 * as well as the File Upload Modal
 */
.file-upload-base,
/**
 * TODO: Delete this .integration-setup-container selector when the old /integration/help page is removed
 */
.integration-setup-container {
  .dataset-icon {
    height: 54px;
    margin-bottom: -8px;
    padding-left: 10px;
  }

  .header-text {
    text-align: left;
    font: normal normal normal 34px/48px;
    letter-spacing: 0px;
    color: #3e3e3e;
    opacity: 1;
  }

  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 4px;
    border-color: var(--mid-green);
    border-style: dashed;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;

    .processing-spinner {
      flex: 1;
    }
  }

  .dropzone:focus {
    border-color: #2196f3;
  }

  .dropzone.disabled {
    opacity: 0.6;
  }

  .instructions-text-container {
    text-align: left;
    margin-top: 0.5rem;
    font-size: 14px;
    letter-spacing: 0px;
  }

  .dataset-field-instructions {
    font-weight: bolder;
    margin-top: 40px;
  }

  .dataset-field-descriptions {
    display: flex;
    margin-left: -5px;

    .dataset-field-descriptor {
      padding: 2px 10px;
      margin: 5px;
      background-color: var(--mid-green);
      color: white;
      border-radius: 8px;
      font-size: 14px;
    }
  }

  .drag-and-drop-text {
    color: var(--font-black);
    font-size: 18px;
    font-weight: 500;
  }

  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .word-wrap {
    word-break: break-word;
  }

  .word-no-wrap {
    white-space: nowrap;
  }

  .supported-files-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    color: #777272;
  }

  .wrong-data-set-text {
    margin-bottom: 20px;

    .choose-another-file {
      color: var(--light-blue);
    }
  }

  .reject-dataset {
    color: var(--light-blue);

    &:hover {
      text-decoration: underline;
    }
  }

  .error-message {
    text-align: center;
    font: normal normal normal 16px/20px;
    letter-spacing: 0px;
    color: #dc3315;
    margin-top: 1rem;
  }

  .try-again {
    text-align: center;
    font: normal normal normal 14px/20px;
    letter-spacing: 0px;
    color: #797979;
  }

  .file-upload-success {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 45px 30px 30px;
    border-radius: 4px;
    background-color: #ffffff;
    color: #bdbdbd;
    outline: none;
    box-shadow: 0px 3px 6px #00000029;
  }

  .file-upload-icon {
    font-size: 72px;
    color: #ffc500;
    box-shadow: 0px 3px 6px #00000029;
    margin-bottom: 1rem;
  }

  .success-icon {
    font-size: 72px;
    color: #5fad56;
    margin-bottom: 1rem;
  }
}
