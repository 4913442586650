.order-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .order-details-content-container {
    width: 400px;
    background-color: var(--background-gray);
    padding: 15px 20px 0px 20px;

    .product-description-app-name {
      padding: 10px 0px;

      img.profitrover-logo {
        width: 75%;
      }
    }

    .billing-frequency-container {
      padding: 5px 0px 0px 0px !important;
      font-size: 14px;
      font-weight: 500;
      color: var(--font-black);

      .plan-container {
        display: block;
        position: relative;
        padding-left: 15px;
        cursor: pointer;
        user-select: none;

        input[type='radio'] {
          position: relative;
          top: 1px;

          transform: scale(1.4);

          cursor: pointer;
        }

        .row {
          display: flex;
          align-items: center;
          padding-top: 5px;

          #monthly-plan-button,
          #annual-plan-button {
            margin-right: 5px;
          }

          &.monthly-plan-text {
            padding-top: 5px;
            padding-bottom: 5px;

            .col {
              padding-left: 0px;
            }
          }
        }

        .tax-text {
          font-size: 12px;
          color: #797979;
        }

        .plan-cent-amt {
          font-size: 6px;
          font-weight: 500;
          position: relative;
          bottom: 0.4rem;
          color: var(--dark-green);
        }

        .discount-price {
          text-decoration: line-through;
          margin-right: 4px;
        }
      }
    }
  }

  .order-details-savings-container {
    width: 400px;

    .savings-per-year-text-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 0px;
      font-size: 14px;
      font-weight: 400;
      color: #fec52dfe;

      .savings-per-year-text {
        color: var(--dark-green);
        margin: 0px 5px;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .savings-per-location-text-container {
      display: flex;
      justify-content: center;

      .savings-per-location-text {
        font-size: 12px;
        font-weight: 400;
        color: var(--dark-green);
        margin: 0px 5px;
        font-style: italic;
      }
    }
  }
}
