.subscription-mgmt-container {
  font-family: URWDIN;
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: min-content 1fr;
  padding-bottom: 92px;
}

/* This ruleset ensures the UnpaidInvoiceBanner uses the same margins as the summary below it */
.spacing-container.sub-mgmt-summary-spacing {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.subscription-mgmt-container.show-banner {
  grid-template-rows: min-content min-content 1fr;
}

/* For the header area */

.subscription-mgmt-container .summary {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 16px;
  column-gap: 16px;
  grid-template-areas:
    'header header'
    'cards cards';
}

.subscription-mgmt-container .summary > h3 {
  grid-area: header;
  color: var(--font-black);
}

.subscription-mgmt-container .summary .subscription-info-card {
  min-width: 500px;
  padding-bottom: 18px;
}

.subscription-mgmt-container .subscription-info-card .product-logo {
  max-width: 255px;
}

.subscription-info-card :not(button) {
  font-weight: 500;
  font-size: 14px;
  color: var(--font-black);
}

.subscription-info-card .heading-text {
  font-size: 18px;
  color: var(--font-gray);
}

.subscription-info-card .discount-price {
  text-decoration: line-through;
  margin-right: 4px;
}

.subscription-info-card .discount-text {
  font-weight: 700;
}

.subscription-info-card .promo-label {
  color: var(--font-gray);
  font-size: 13px;
}

.subscription-info-card .first-free-text {
  color: var(--font-gray);
}

.subscription-mgmt-container .summary .subscription-info-card .card-layout {
  display: flex;
  justify-content: space-between;

  height: 100%;
}

.subscription-mgmt-container .summary .subscription-info-card .card-layout {
  /* Ensures flex children do not grow wider than the card itself */
  min-width: 0px;
}

.subscription-info-card .left-side {
  flex: 3;
}

.subscription-info-card .product-description-text {
  margin-top: 12px;
}

.subscription-info-card .right-side {
  flex: 2;

  padding-left: 20px;
}

.subscription-info-card .right-side.plan-info .days-remaining {
  color: var(--font-gray);
}

.subscription-info-card .right-side.plan-info .days-remaining-integer {
  color: var(--font-gray);
  text-decoration: underline;
  font-weight: 600;
}

.subscription-info-card .right-side.plan-info,
.subscription-info-card .left-side.billing-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* Move the text up a little bit to line up with the product logo */
  margin-top: -6px;
}

.subscription-info-card .right-side.plan-info .buttons {
  margin-top: 14px;
}

.subscription-info-card .left-side.billing-info .upgrading-text {
  color: var(--light-blue);
  font-style: italic;
}

.subscription-info-card .left-side.billing-info .payment-method-info {
  color: var(--font-gray);
  opacity: 0.8;
}

.subscription-info-card .left-side.billing-info .buttons {
  display: flex;
  margin-top: 14px;
}

.subscription-info-card .left-side.billing-info .buttons :first-child {
  margin-right: 12px;
}

.subscription-info-card .left-side.billing-info .auto-renew-disabled {
  color: var(--error);
  display: flex;
  flex-direction: column;
}

.subscription-info-card .right-side .auto-renew-disabled {
  color: var(--error);
}

.subscription-info-card .left-side.billing-info .auto-renew-disabled button {
  padding: 0px;
  text-decoration: underline;
  color: var(--light-blue);
  width: fit-content;
}

.subscription-info-card .left-side .billing-reactivation-message {
  color: #797979;
}

.subscription-info-card .right-side.active-location-info {
  text-align: right;
  display: flex;
  flex-direction: column;
}

.subscription-info-card .right-side.active-location-info > * {
  text-align: right;

  white-space: nowrap;
  overflow: visible;
  float: right;
}

.subscription-info-card .right-side.active-location-info .discount {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.subscription-info-card .right-side.active-location-info .discount-price {
  text-decoration: line-through;
  margin-right: 8px;
}

.subscription-info-card .right-side.active-location-info h4 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0px;
}

.subscription-info-card .right-side.active-location-info h4 .tax-text {
  font-size: 12px;
  color: #797979;
}

/* For the locations table area */

.subscription-mgmt-container .locations-status {
  margin-top: 1rem;
}

.subscription-mgmt-container .locations-status-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscription-mgmt-container .locations-status .top {
  display: flex;
  justify-content: space-between;
}

.subscription-mgmt-container .locations-status .locations-status-summary {
  display: flex;
  align-items: baseline;

  margin-bottom: 5px;
}

.subscription-mgmt-container .locations-status .locations-status-summary > * {
  margin-bottom: 0px;
}

.subscription-mgmt-container .locations-status .locations-status-summary > h3 {
  color: var(--font-black);
  margin-right: 11px;
}

.subscription-mgmt-container .locations-status .subscription-page-location-table {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
}

.subscription-mgmt-container .locations-status .subscription-page-location-table .ag-header-cell-text {
  white-space: normal;
}

.subscription-page-location-table .react-toggle-track {
  width: 26px;
  height: 17px;
  border: 1px solid #ffffff;
  background-color: #bebebe;
  box-shadow: 0px 1px 2px #00000040;
}

.subscription-page-location-table .react-toggle--checked .react-toggle-track {
  background-color: #5fad5665;
}

.subscription-page-location-table .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #5fad5665;
}

.subscription-page-location-table .react-toggle-thumb {
  width: 15px;
  height: 15px;
  top: 1px;
  left: 0px;
  border: 1px solid #f0f0f0;
}

.subscription-page-location-table .react-toggle--checked .react-toggle-thumb {
  left: 11px;
  background-color: #5fad56;
}

.subscription-page-location-table .react-toggle--focus .react-toggle-thumb {
  box-shadow: none;
}

.subscription-page-location-table .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: none;
}

.subscription-page-location-table .react-toggle-track-check {
  display: none;
}

.subscription-page-location-table .react-toggle-track-x {
  display: none;
}

.subscription-page-location-table .rt-tr.-even {
  background-color: #f7f7f7;
}

.subscription-page-location-table .rt-td {
  border-right: none;
}

.subscription-page-location-table {
  font-weight: 500;
  font-size: 14px;
}

.subscription-page-location-table .ag-root-wrapper {
  border-radius: 4px;
}
.subscription-page-location-table .ag-overlay-no-rows-center {
  display: none;
}

.subscription-page-location-table .ag-root-wrapper-body.ag-layout-normal {
  height: unset;
}

.subscription-page-location-table .ag-header-viewport {
  background-color: var(--mid-green);
}
.subscription-page-location-table .ag-header-viewport .ag-header-cell-text {
  color: #ffffff;
}

.subscription-page-location-table .toggle-header .ag-header-cell-label {
  justify-content: center;
}

.subscription-page-location-table .toggle-cell .ag-react-container {
  display: flex;
  justify-content: center;
}

.subscription-page-location-table .ag-cell.toggle-cell {
  display: flex;
  justify-content: center;
}

.subscription-page-location-table .ag-cell-value {
  display: flex;
  align-items: center;

  /** 
   * IMPORTANT DEVELOPER NOTE:
   * These are the dials you can use to adjust
   * row and text spacing with autoHeight on
   */
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  line-height: 20px;
}

.subscription-page-location-table .ag-row.pending-activation {
  background-color: #dfefdd;
}

.subscription-page-location-table .ag-row.pending-deactivation {
  background-color: #f4c3c3;
}

.subscription-page-location-table .unchangeable {
  background-color: #d7d7d7;
  opacity: 0.4;
}

/* This removes the extra whitespace from the bottom of the table when there are < 3 rows */
.subscription-page-location-table .ag-center-cols-clipper {
  min-height: unset !important;
}

.subscription-mgmt-container .end-trial-early-box {
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid var(--light-green-shadow);
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.subscription-mgmt-container .trial-has-expired {
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid var(--error);
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.subscription-mgmt-container .end-trial-early-box .unlock-text {
  font-family: URWDIN;
  font-size: 15px;
  color: var(--font-black);
  display: flex;
  align-items: center;
}
