.workflow-description-text {
  width: 300px;
  height: 42px;
  padding: 0px 7px;
  margin-bottom: 20px;
}

/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: button;
  margin: 0;
}

.edit-workflow-div .page-input {
  margin: 0px 6px;
}

.date-range-options-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.date-range-options-container .simple-radio-button {
  display: flex;
  align-items: center;
}

.date-range-options-container .simple-radio-button input[type='radio'] {
  margin-right: 6px;
}

.date-range-options-container .simple-radio-button:not(:last-child) {
  margin-bottom: 10px;
}

.save-buttons {
  display: flex;
}

.save-buttons button:not(:last-child) {
  margin-right: 15px;
}

.create-new-analysis-button {
  background: #ccc 0% 0% no-repeat padding-box;
}

.create-new-analysis-button:hover {
  border: 2px solid #f7f7f7;
}

.create-new-analysis-button:focus {
  border: 3px solid #f7f7f7;
}

.create-new-analysis-selection-tile-outline {
  width: 94px;
  height: 94px;
  background: var(--light-green-hover);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-new-analysis-circle-outline {
  color: white;
  font-size: 65px;
  filter: drop-shadow(0px 3px 6px #00000029);
}

.create-new-analysis-text {
  color: #000;
  text-shadow: none;
  margin-top: 30px;
  font-size: 14px;
}

.workflow-header {
  border-bottom: 1px solid #d9d9d9;
  width: fit-content;
  padding-bottom: 5px;
}

.workflow-section {
  margin-top: 30px;
  width: fit-content;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 5px;
}
