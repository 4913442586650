.workflow-progress-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-right: 10px;
  --size: 16px;

  .finished-check {
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    border: 1px solid var(--success-green);
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      font-size: calc(var(--size) * 0.6);
      color: var(--success-green);
    }
  }

  .loader {
    width: var(--size);
    height: var(--size);
    border-width: 1px;
  }

  .progress-stage-label {
    font-size: 16px;
  }

  .refresh-button {
    color: var(--light-blue);
    padding: 0 0.5rem;
    border: 1px solid var(--light-cool-gray);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    margin-left: 10px;

    .icon {
      color: var(--font-black);
      margin-right: 4px;
    }
  }
}
