@import '../../../sass/variables';

.guided-setup-progress-bar-container {
  width: 100%;
  border-bottom: 2px solid var(--light-cool-gray);
  box-shadow: 0px 3px 6px #00000029;
  height: $guided-setup-progress-bar-height;
  min-width: min-content;

  .guided-setup-progress-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    padding: 0px 1.5rem;

    .general-setup-text {
      font-size: 24px;
      font-weight: 500;
      color: var(--font-gray);
      white-space: nowrap;
    }

    .vertical-separator {
      font-size: 48px;
      font-weight: 100;
      color: var(--font-gray);
    }

    .tracked-step-label {
      font-size: 14px;
      color: var(--light-cool-gray);
      white-space: nowrap;

      &.active {
        color: black;
      }
    }

    .completed-check {
      margin: 0px 5px 0px 5px;
    }

    .horizontal-step-separator {
      margin: 0px 10px 2px 10px;
      display: inline-block;
      height: 1px;
      width: 50px;
      border-bottom: 3px solid var(--mid-green);
    }
  }
}
