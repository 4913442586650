#homepage {
  color: #14acc2;  

  }

  .welcome-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .welcome-image {
    padding-top: 50px;
  }

  .welcome-message {
    text-align: center;   
  }
  .display-4{
    font-size: 34px;
  }
