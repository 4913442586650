#product,#location {
    position: absolute;
    width: 200px;
    height: auto;
    padding: 10px;
}

#product, #location p {
    margin: 0;
    font-size: 18px;
    line-height: 20px;
}