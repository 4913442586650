.ds-config-dim-selection-container {
  position: relative;
  display: inline-block;
  min-width: 200px;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #0000005C;
} 

.ds-config-dim-selection-container label {
  font-size: 14px;
  margin-bottom: 2px;
} 

.ds-config-dim-selection-prompt {
  font-size: 16px;
  text-align: left;
  letter-spacing: 0px;
  color: #3E3E3E;
}

.ds-config-dim-selection-container ul {
  list-style-type: none;
  margin: 5px 0px;
  padding: 0px;
  padding-right: 20px;
  max-height: 300px;
  overflow-y: auto;
}

.ds-config-dim-selection-container ul li {
  font-size: 14px;
  font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;

  padding: 0;
  color: #3E3E3E;
  letter-spacing: 0px;
  cursor: pointer;
}

hr.dim-selection-divider {
  max-width: 150px;
  margin: 0.5rem 0px 0.25rem 0px;
}

.ds-config-dim-selection-create-new-dim {
  font-size: 14px;
  background: none;
  border: none;
}

/* TODO: Extract this styling to be reusable */
.dim-selection-reselect {
  margin-left: 15px;
  color: #287FE5;
  cursor: pointer;
  outline: 0px;
  font-size: 16px;
}

.dim-selection-reselect:hover {
  text-decoration: underline;
}

.dim-selection-cancel {
  cursor: pointer;
  outline: 0px;
  font-size: 16px;
}

.dim-selection-cancel:hover {
  text-decoration: underline;
}