.product-description {
  max-width: 400px;
  padding: 15px 25px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;
  height: max-content;

  p,
  li {
    font-size: 14px;
  }

  .highlight-container {
    width: 100%;
    font-size: 24px;
    font-weight: bold;

    border: 2px solid var(--light-green);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 3px rgb(0 0 0 / 25%);
    margin-top: 5px;
    margin-bottom: 9px;
    color: var(--dark-green);
  }

  --pricing-text-spacing: 4px;

  .pricing-text {
    font-weight: 500;
    margin-bottom: var(--pricing-text-spacing);
  }

  .cost-per-location {
    margin-bottom: var(--pricing-text-spacing);

    display: flex;
    flex-direction: column;
    align-items: center;

    font-weight: 700;

    .row {
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .dollars {
      font-size: 20px;
    }
    .cents {
      font-size: 14px;
      margin-left: -2px;
    }
    .slash {
      font-size: 20px;
      font-weight: 700;
    }
    .per {
      font-size: 20px;
    }
    .coupon {
      font-size: 15px;
      font-weight: 500;
    }
    .strikethrough-price {
      text-decoration: line-through;
      margin-right: 8px;
    }
  }

  .what-you-are-getting {
    font-weight: 500;

    h6 {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 2px;
    }

    ul {
      list-style: none; /* Remove default bullets */
      padding-inline-start: 0;
      margin-bottom: 2px;

      li::before {
        content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: var(--light-green); /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        font-size: 15px;

        width: 12px;
      }
    }

    .learn-more-link {
      font-size: 12px;
      font-weight: 400;
      text-decoration: underline;
    }
  }

  .product-description-bottom-section-title {
    font-size: 14px;
    font-weight: 600;
    color: var(--dark-green);
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .product-description-bottom-section-link {
    font-size: 12px;
    color: var(--light-blue);
    text-decoration: underline;
    display: block;
  }

  .product-industries-supported {
    display: flex;
    align-items: center;

    padding-left: 0px;
    font-size: 14px;
    font-weight: 500;
    color: var(--dark-green);
    margin: 12px 0px;

    .product-industries-supported-checkbox {
      font-size: 16px;
      margin-right: 8px;
      color: #5fad56;
    }
  }

  .profitrover-logo {
    width: 30%;
  }
}
