@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.guided-setup-welcome {
  height: 100%;

  background: var(--light-blue);
  background: linear-gradient(70deg, var(--light-blue) 0%, var(--background-gray) 50%);

  overflow: auto;

  .layout {
    display: flex;
    height: 100%;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }

    .welcome {
      flex: 1;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-up(md) {
        margin-top: 3rem;
        margin-bottom: 3rem;
      }

      margin: auto;

      .cut-card {
        width: 90%;
        min-width: 640px;
        max-width: 800px;

        background-color: white;
        box-shadow: var(--large-container-box-shadow);

        @include media-breakpoint-down(sm) {
          min-width: 0px;
          height: 100%;
          width: 100%;
          border-radius: 0px;
        }

        padding: 2rem;

        @include media-breakpoint-up(xl) {
          padding: 2.5rem;
        }

        border-radius: 24px;
      }

      .heading {
        @include media-breakpoint-up(sm) {
          white-space: nowrap;
        }
      }

      .subtitle {
        font-size: 1.5rem;
      }

      .setup-text {
        font-size: 1rem;
      }

      .icon-area {
        height: min-content;
        flex: 1;
      }

      ol {
        flex: 3;

        li {
          margin-bottom: 0.5rem;
        }

        li::marker {
          font-weight: bold;
          font-size: 1.25rem;
        }
      }
    }

    .benefits {
      flex: 1;
      width: 100%;

      @include media-breakpoint-up(xl) {
        max-width: 50%;
      }

      .card {
        height: 100%;
        padding-left: 4rem;
        padding-right: 4rem;
        border-radius: 8px;
        overflow: auto;

        @include media-breakpoint-down(sm) {
          border-radius: 0;
          border: none;
          box-shadow: none;
        }
      }

      .img-container {
        margin-top: 5vh;
        margin-bottom: 5vh;

        @media screen and (min-width: 1400px) {
          margin-top: 6rem;
          margin-bottom: 5rem;
        }

        @media screen and (max-height: 798px) {
          margin-top: 2.75rem;
          margin-bottom: 2.75rem;
        }

        img {
          transform: translateX(-50px) perspective(24cm) skewX(3deg) rotate3d(0, 0, 1, 357deg) rotate3d(0, 1, 0, 324deg)
            rotate3d(1, 0, 0, 22deg) scale(1.15);
          border-radius: 8px;
          box-shadow: 10px 8px 7px #00000038;

          height: 200px;
          position: relative;
          left: 1.8rem;

          @include media-breakpoint-between(sm, lg) {
            height: 235px;
            left: 0rem;
          }

          @media (min-width: 1350px) {
            left: 1rem;
            height: 300px;
          }

          @media screen and (max-height: 798.98px) {
            height: 200px;
          }
        }
      }

      .heading {
        color: var(--mid-green);
        font-size: 1.25rem;
        font-weight: 800;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          margin: 0;
          padding: 0;
          margin-bottom: 0.5rem;

          font-size: 0.9rem;
        }
      }
    }
  }
}
