.reactivate-subscription-banner {
  border: 2px solid var(--light-green);
  box-shadow: var(--large-container-box-shadow);
  display: flex;
  align-items: space-between;
  padding: 12px 22px;
  padding-right: 0px;
  background: white;

  .reactivation-message {
    padding-right: 25px;
    flex-grow: 1;
    font-size: 14px;
  }

  .hide-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100px;
    min-width: 100px;

    // "Dividing line"
    border-left: 1px solid black;
  }
}
