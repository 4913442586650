.data-source-logo-heading {
  height: 50px;
  width: 100%;

  background: #adadad55;

  display: flex;
  align-items: center;

  margin-bottom: 20px;

  .logo {
    margin-left: 10px;
  }
}
