/**
 * This file specifies the "default ProfitRover theme" for React Table v6
 */

.profitrover-react-table {
  --table-border-radius: 4px;
  border-radius: var(--table-border-radius);

  .rt-table {
    border-radius: var(--table-border-radius);

    .rt-tbody {
      border-radius: var(--table-border-radius);

      .rt-tr-group {
        border-radius: var(--table-border-radius);
      }
    }
  }

  font-size: 14px;

  .rt-thead .rt-th,
  .rt-tbody .rt-td {
    display: flex;
    align-items: center;

    padding-left: 18px;
    padding-right: 18px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .rt-thead .rt-th {
    font-weight: 600;
  }

  .rt-tbody .rt-td {
    font-weight: 500;
    color: var(--font-gray);
  }

  .centered {
    justify-content: center;
  }

  .centered.left-align,
  .left-align {
    justify-content: flex-start;
  }

  .rt-thead {
    color: white;
    background: var(--mid-green);
  }

  --icon-spacing: 6px;

  img:not(:first-child),
  svg:not(:first-child) {
    margin-left: var(--icon-spacing);
  }

  img:not(:last-child),
  svg:not(:last-child) {
    margin-right: var(--icon-spacing);
  }

  img:hover,
  svg:hover {
    cursor: pointer;
    color: var(--font-black);
  }
}
