.back-to-subscription-link {
  text-decoration: underline;
  color: var(--light-blue);
  cursor: pointer;
}

.change-payment-method-link button {
  font-size: 12px;
  color: var(--light-blue);
  cursor: pointer;
}

.payment-method-mgmt-container {
  display: grid;
  grid-template-rows: 75px 400px;
  grid-auto-rows: minmax(600px, auto);
  row-gap: 32px;

  width: 100%;
  max-width: 1200px;
  padding: 0px 10px;
}

.payment-method-mgmt-container .summary {
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 16px;
  column-gap: 16px;

  grid-template-areas:
    'header header'
    'cards cards';
}

.manage-payment-method-card .left-side {
  flex: 3;
}

.manage-payment-method-card .right-side {
  flex: 2;

  padding-left: 20px;
}

.manage-payment-method-card .right-side.payment-method-info,
.manage-payment-method-card .left-side.subscription-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.manage-payment-method-card .left-side.subscription-info .no-active-plan {
  margin-top: 16px;
}

.manage-payment-method-card-header {
  font-size: 18px;
  font-weight: 600;
  color: #797979;
}

.manage-payment-method-card-subheader {
  font-size: 18px;
  font-weight: 450;
  color: #797979;
  margin-top: 15px;
}

.manage-payment-method-card-text {
  font-size: 14px;
}

.manage-payment-method-discount-price {
  text-decoration: line-through;
  margin-right: 4px;
}

.payment-method-mgmt-container .manage-payment-method-card .product-logo {
  max-width: 255px;
}

.manage-payment-method-card-inner-box {
  background: #f8f8f8;
  box-shadow: var(--large-container-box-shadow);
  border-radius: 4px;
  padding: 10px;
  margin: 10px 0 10px 0;
  display: flex;
}

.card-brand-logo {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.payment-method-info {
  flex: 50%;
}

.change-payment-method-modal .user-payment-disclaimer {
  background-color: #ebebeb;
  padding: 7px 4px;
  font-size: 12px;
  color: var(--dark-green);
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.change-payment-method-modal .user-payment-disclaimer-icon {
  font-size: 14px;
  margin: 0px 5px;
  color: var(--light-green);
}

.change-payment-method-modal .user-payment-disclaimer-text {
  display: flex;
  align-items: center;
  margin-right: 3px;
}

.change-payment-method-modal .user-payment-title {
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  color: #3e3e3e;
  margin-bottom: 0px;
}

.change-payment-method-modal .user-payment-no-charge-note {
  text-align: left;
  font-size: 14px;
  color: var(--dark-green);
  margin-bottom: 5px;
}

.change-payment-method-modal .user-payment-disclaimer {
  background-color: #ebebeb;
  padding: 7px 4px;
  font-size: 12px;
  color: var(--dark-green);
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.change-payment-method-modal .user-payment-disclaimer-icon {
  font-size: 19px;
  margin-right: 5px;
  color: var(--light-green);
}

.change-payment-method-modal .user-payment-disclaimer-text {
  display: flex;
  align-items: center;
  margin-right: 3px;
}

.change-payment-method-modal .modal-header {
  border-bottom: none;
  padding: 40px 40px 0px 40px;
}

.change-payment-method-modal .modal-header .modal-title {
  font-size: 24px;
  font-weight: 600;
}

.change-payment-method-modal .modal-body {
  padding: 0px 40px 0px 40px;
}

.change-payment-method-modal .modal-footer {
  background-color: var(--background-gray);
  border-top: none;
  padding: 20px 40px;
}

.change-payment-method-modal .payment-details-section-title {
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  color: var(--dark-green);
  margin-bottom: 10px;
}

.change-payment-method-modal input,
.change-payment-method-modal .StripeElement {
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0px 1px 4px #0000002e;
  padding: 5px;
  color: var(--dark-green);
  font-size: 14px;
}

.change-payment-method-modal .StripeElement--focus {
  box-shadow: 0px 1px 6px var(--light-green-shadow);
}

.change-payment-method-modal .StripeElement {
  padding: 8.6px;
}

.change-payment-method-modal input:focus {
  box-shadow: 0px 1px 6px var(--light-green-shadow);
}

.change-payment-method-modal label {
  position: relative;
}

.change-payment-method-modal input:focus-visible {
  outline: none;
}

.change-payment-method-modal .payment-details-name {
  width: 100%;
  margin-bottom: 20px;
  color: var(--font-gray);
  font-family: Arial;
  padding: 7px;
}

.change-payment-method-modal .user-payment-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.change-payment-method-modal .payment-details-cardnumber-label {
  width: 100%;
}

.change-payment-method-modal .payment-details-cvc-label {
  width: 47%;
}

.change-payment-method-modal .payment-details-expiration-label {
  width: 47%;
}

.change-payment-method-modal .billing-info-country-label {
  width: 100%;
}

.change-payment-method-modal .billing-info-country,
.change-payment-method-modal .billing-info-region {
  border: 1px solid #cccccc;
  margin-bottom: 20px;
  font-family: Arial;
}

.change-payment-method-modal .billing-info-country *,
.change-payment-method-modal .billing-info-region * {
  color: var(--font-gray);
}

.change-payment-method-modal .billing-info-country:focus-within,
.change-payment-method-modal .billing-info-region:focus-within {
  box-shadow: 0px 1px 6px var(--light-green-shadow);
}

.change-payment-method-modal .billing-info-address1-label {
  width: 100%;
}

.change-payment-method-modal .billing-info-address1 {
  width: 100%;
  margin-bottom: 20px;
  color: var(--font-gray);
  font-family: Arial;
  padding: 7px;
}

.change-payment-method-modal .billing-info-address2-label {
  width: 100%;
}

.change-payment-method-modal .billing-info-address2 {
  width: 100%;
  margin-bottom: 20px;
  color: var(--font-gray);
  font-family: Arial;
  padding: 7px;
}

.change-payment-method-modal .billing-info-city-label {
  width: 30%;
}

.change-payment-method-modal .billing-info-city {
  width: 100%;
  color: var(--font-gray);
  font-family: Arial;
  padding: 7px;
}

.change-payment-method-modal .billing-info-region-label {
  width: 30%;
}

.change-payment-method-modal .billing-info-region {
  width: 100%;
  color: var(--font-gray);
  font-family: Arial;
  padding: 7px;
}

.change-payment-method-modal .billing-info-zip-label {
  width: 30%;
}

.change-payment-method-modal .billing-info-zip {
  width: 100%;
  color: var(--font-gray);
  font-family: Arial;
  padding: 7px;
}

.change-payment-method-modal .billing-info-company-name-label {
  width: 100%;
}

.change-payment-method-modal .billing-info-company-name {
  width: 100%;
  margin-bottom: 20px;
  color: var(--font-gray);
  font-family: Arial;
  padding: 7px;
}

.change-payment-method-modal .input-field-dynamic-label {
  font-size: 12px;
  color: #848484;
  position: absolute;
  background-color: white;
  top: -10px;
  left: 5px;
  padding: 0px 2px;
}

.change-payment-method-modal .modal-footer button {
  padding-top: 6px;
}

.change-payment-method-modal .save-button {
  box-shadow: 0px 2px 8px #0000004e;
  min-width: 168px;
}

.change-payment-method-modal .button-icon {
  padding-right: 5px;
}

.change-payment-method-modal .cancel-button {
  border: none;
  box-shadow: 0px 2px 6px #0000004e;
  color: var(--font-gray);
}

.change-payment-method-modal .payment-update-notifications {
  color: red;
}
