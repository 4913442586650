@keyframes fade-in {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 1;
  }
}

.generic-error-boundary-page {
  height: 100%;
  background: var(--background-gray);

  .message-bubble {
    background-color: white;
    border: 2px solid var(--error);

    max-width: 60vw;
    min-height: 200px;
    border-radius: 6px;

    display: flex;
    flex-direction: column;

    padding: 30px;
    padding-bottom: 20px;

    box-shadow: var(--large-container-box-shadow);

    animation-name: fade-in;
    animation-duration: 0.8s;

    &:hover {
      transition-property: opacity;
      transition-duration: 0.4s;
    }

    button:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.error-with-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .error-description {
    margin: 2rem 0;
    font-size: 1.8rem;
    text-align: center;
    max-width: 800px;
  }

  .error-description-secondary {
    margin-top: -0.8rem;
    font-size: 1.3rem;
    text-align: center;
  }

  .img {
    width: 280px;
    height: 280px;
    object-fit: contain;
  }

  .link {
    cursor: pointer;
    color: var(--light-blue);

    &:hover {
      color: #0056b3;
      text-decoration: underline;
    }
  }
}

.popcorn-screen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  --container-width: 70%;

  .error-description {
    margin: 1.5rem 0;
    font-size: 1.6rem;
    text-align: center;
    max-width: 800px;
  }

  .img {
    width: 180px;
    height: 180px;
    object-fit: contain;
  }

  .animation {
    animation: popcorn-animation 2s infinite;
  }

  @keyframes popcorn-animation {
    0% {
      transform: scale(1) rotate(0);
    }
    25% {
      transform: scale(1.04) rotate(-4.5deg);
    }
    50% {
      transform: scale(0.96) rotate(0);
    }
    75% {
      transform: scale(1.04) rotate(4.5deg);
    }
    100% {
      transform: scale(1) rotate(0);
    }
  }

  .progress-stage-loaders {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: var(--container-width);
    gap: 1rem;
    padding: 0 10%;

    .progress-stage-loader-container {
      position: relative;
      --size: 50px;

      .loading-stage {
        width: var(--size);
        height: var(--size);
      }

      .completion-pct {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
      }

      .finished-stage {
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        border: 2px solid var(--success-green);
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          color: var(--success-green);
          font-size: calc(var(--size) / 2);
        }
      }

      .pending-stage {
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        border: 2px solid var(--font-black);
      }
    }

    .progress-stage-divider {
      flex: 1;
      display: flex;
      height: 2px;
      background-color: var(--font-black);
    }
  }

  .progress-stage-labels {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: var(--container-width);
    gap: 1rem;
    margin-top: 0.5rem;

    .progress-stage-label {
      font-size: 1.5rem;
      font-weight: 500;
      text-align: center;
    }
  }

  .progress-steps {
    display: flex;
    flex-direction: column;
    width: var(--container-width);
    gap: 0.1rem;
    margin-top: 0.5rem;

    .progress-step-container {
      --size: 16px;
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      align-items: center;

      .loading-step {
        width: var(--size);
        height: var(--size);
        border-width: 1px;
      }

      .finised-step {
        font-size: var(--size);
        color: var(--success-green);
      }

      .pending-step {
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        border: 1px solid var(--font-black);
      }

      .progress-step-label {
        font-size: 16px;
      }
    }
  }
}
