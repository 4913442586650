.unpaid-invoice-banner {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 14px;

  display: flex;
  justify-content: center;

  background-color: #b3270f1a;

  animation: fadeIn 1.25s;

  @keyframes fadeIn {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  .spacing-container {
    font-size: 18px;

    .heading {
      color: var(--error);
    }

    .remark {
      margin: 10px 0px;
    }

    .emphasis-area {
      max-width: max-content;
      display: flex;
      align-items: center;

      background-color: white;
      border: 2px solid var(--error);
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 4px;

      padding: 11px 17px;

      .unpaid-invoice-message {
        margin-right: 10px;
      }
    }
  }
}
