.new-shopify-registration {
  .profitrover-modal-card {
    min-width: 300px;
  }

  input[type='email'],
  input[type='password'],
  input[type='text'] {
    min-width: 240px;
    width: 100%;
    height: 36px;
    padding-left: 10px;
    border: 1px solid #efefef;
    border-radius: 4px;
    box-shadow: 0px 1px 4px #00000029;

    &:focus {
      box-shadow: 0px 1px 6px #f6c50cd8;
    }

    &:focus-visible {
      outline: none;
    }

    &:focus::placeholder {
      color: transparent;
    }
  }

  button {
    width: 100%;
  }
}

.error-text {
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  color: var(--error);
}
