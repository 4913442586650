$mobile-breakpoint-width: 800px;

.password-visibility-icon {
  position: absolute;
  color: #848484;
  font-size: 14px;
  right: 3%;
  top: 32%;

  &:hover {
    cursor: pointer;
  }
}

.request-code {
  display: flex;
  flex-direction: column;
  align-items: center;

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 410px;

    @media screen and (max-width: $mobile-breakpoint-width) {
      max-width: 365px;
    }

    .create-account-container {
      background-color: white;
      border: 1px solid #e3e3e3;
      border-radius: 8px;
      padding: 15px 40px;
      padding-bottom: 30px;
      box-shadow: 0px 3px 6px #00000029;

      @media screen and (max-width: $mobile-breakpoint-width) {
        padding: 15px 30px;
        padding-bottom: 25px;
      }

      .title {
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        color: var(--font-black);
        margin-bottom: 15px;
      }

      .input-field {
        position: relative;
      }

      input:not([type='checkbox']):not([type='radio']) {
        width: 100%;
        height: 36px;
        padding-left: 10px;
        border: 1px solid #efefef;
        border-radius: 4px;
        box-shadow: 0px 1px 4px #00000029;

        &:focus {
          box-shadow: 0px 1px 6px var(--light-green-shadow);
        }

        &:focus-visible {
          outline: none;
        }

        &:focus::placeholder {
          color: transparent;
        }
      }

      .input-field-dynamic-label {
        position: absolute;
        background-color: white;
        top: -12px;
        left: 5px;
        padding: 0px 5px;
      }

      .agree-checkbox-container {
        font-size: 14px;
        display: block;
        position: relative;
        padding-top: 4px;
        padding-left: 25px;
        margin-bottom: 22px;
        cursor: pointer;
        font-weight: 500;
        color: var(--dark-green);
        user-select: none;

        #agree {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        .checkmark {
          margin-top: 5px;
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          border: 2px solid var(--light-blue);
          border-radius: 2px;
        }

        input:checked ~ .checkmark {
          background-color: var(--light-blue);
        }

        input:checked ~ .checkmark:after {
          display: block;
        }

        .checkmark:after {
          left: 5px;
          top: 2px;
          width: 6px;
          height: 10px;
          border: solid white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          content: '';
          position: absolute;
          display: none;
        }
      }

      .send-code-button {
        width: 100%;
      }

      .email-explanation-text {
        font-size: 12px;
        font-weight: 500;
        color: var(--font-gray);
        padding-top: 10px;
      }

      .error-text {
        margin-top: 5px;
        margin-left: 3px;
        font-size: 12px;
        font-weight: 500;
        color: var(--error);
      }
    }

    .no-hassle-box {
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;

      .no-hassle-box-content-container {
        width: 100%;
        background-color: white;
        border-radius: 8px 8px 8px 8px;
        padding: 10px 20px 10px 20px;
        text-align: center;
        box-shadow: 0px 3px 6px #00000029;

        .no-hassle-top-line {
          color: var(--font-black);
          font-size: 18px;
          font-weight: 700;
        }

        .no-hassle-bottom-line {
          color: var(--light-blue);
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
}
