.eo-price-rec-table-container {
  flex: 1;
  display: flex;
  width: 100%;
  min-height: 165px;
  border-radius: var(--bubble-border-radius);
}

.eo-price-rec-table-container {
  .price-rec-table.hidden {
    display: none;
  }
}

.eo-price-rec-table-container .ag-theme-alpine {
  border-radius: 4px;
  width: 100%;
}

.eo-price-rec-table-container .ag-theme-alpine .ag-cell-data-changed {
  /* Overrides theme setting */
  background: var(--light-blue) !important;
}

.eo-price-rec-table-container .price-rec-table-group-header {
  border-right: 1px solid black;
}

.eo-price-rec-table-container .ag-theme-alpine {
  flex-grow: 1;
}

.price-rec-table-group-header .ag-header-group-cell-label {
  flex-direction: column;
  align-items: baseline;
}

.ag-theme-alpine.price-rec-table {
  font-family: URWDIN;
  font-weight: 500;
}

.price-rec-table-header-icon {
  width: 20px;
  margin-right: 4px;
}

.eo-price-rec-table-container .ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.eo-price-rec-table-container .price-rec-approved-price {
  overflow: visible;
}

.eo-price-rec-table-container .price-rec-approved-price .price-rec-approved-price-container {
  margin-top: 5px;
}

.eo-price-rec-table-container .ag-cell-label-container .ag-header-cell-label {
  justify-content: left;
  padding-left: 8px;
}

.eo-price-rec-table-container .graph-total-triangle {
  margin: 0px 5px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.eo-price-rec-table-container .triangle-up {
  border-bottom: 12px solid #0ec647;
  margin-bottom: 1px;
}

.eo-price-rec-table-container .triangle-down {
  border-top: 12px solid #c72913;
  margin-top: 1px;
}

.eo-price-rec-table-container .ag-root-wrapper {
  border-radius: 4px;
}

.eo-price-rec-table-container .ag-cell-label-container {
  justify-content: center;
}

.eo-price-rec-table-container .ag-cell {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.eo-price-rec-table-container .ag-row {
  border-bottom: none !important;
}

.eo-price-rec-table-container .ag-row-odd {
  background-color: #f7f7f7 !important;
}

.eo-price-rec-table-container .ag-header-group-cell-label {
  justify-content: center;
}

.eo-price-rec-table-container .ag-header-group-cell-with-group {
  background-color: var(--mid-green);
  color: white;
}

.eo-price-rec-table-container .border-right {
  border-right: 1px solid #00000029;
}

.eo-price-rec-table-container .center-text {
  text-align: center;
}

.eo-price-rec-table-container .price-rec-table-header {
  text-transform: capitalize;
}

.eo-price-rec-table-container .center-text .ag-header-cell-label {
  flex: none;
}

.eo-price-rec-table-container .ag-row .ag-cell:first-child {
  border-left: none !important;
}

.eo-price-rec-table-container .ag-pinned-left-header {
  border-right: 2px solid #b1b2b4 !important;
}

.eo-price-rec-table-container .ag-pinned-left-cols-container {
  border-right: 2px solid #b1b2b4 !important;
}

.eo-price-rec-table-container .ag-pinned-left-cols-container .ag-cell {
  border-right: none !important;
}

.eo-price-rec-table-container .ag-pinned-right-header {
  border-left: 2px solid #b1b2b4 !important;
}

.eo-price-rec-table-container .ag-pinned-right-cols-container {
  border-left: 2px solid #b1b2b4 !important;
}

.eo-price-rec-table-container .ag-pinned-right-cols-container .ag-cell {
  border-left: none !important;
}

.eo-price-rec-table-container .ag-pinned-right-cols-container .ag-cell {
  border-left: none !important;
}

.eo-price-rec-table-container .price-rec-chg-pct {
  display: flex;
  justify-content: center;
}

.eo-price-rec-table-container .price-rec-gain-total {
  display: flex;
  align-items: center;
  justify-content: center;
}

.eo-price-rec-table-container .price-rec-chng-tag .small-price-tag-content {
  height: 22px;
}

.eo-price-rec-table-container .price-rec-chg-pct .ag-react-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled-approved-price-select-tooltip .tooltip-inner {
  text-align: left;
}

.pricing-table-container {
  position: relative;
  width: 100%;
  overflow-x: auto;

  .selectors-container {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    gap: 1rem;

    .arrow-button {
      height: 100%;
      display: flex;
      align-items: center;

      &:disabled {
        opacity: 0.5;
      }

      &:focus {
        outline: none;
      }
    }

    .detail-button-container {
      display: flex;
      border: 1px solid var(--light-cool-gray);
      border-radius: 4px;
      padding: 0;

      .table-button {
        all: unset;

        width: 110px;
        border-radius: 4px;
        padding: 5px 0;

        cursor: pointer;

        &:disabled {
          cursor: not-allowed;
          opacity: 0.8;
        }

        p {
          text-align: center;
          color: var(--font-black);
          font-size: 16px;
          font-weight: 500;
          line-height: 1.1rem;
          margin: 0;
        }
      }

      .table-button-selected {
        background-color: var(--mid-green);

        p {
          color: #f7f7f7;
        }
      }
    }
  }
}

.date-range-picker-anchor {
  margin: 0;

  .react-datepicker-wrapper {
    width: 100%;
    border: none;
    background: none;

    .react-datepicker__input-container {
      width: 100%;
      display: block;
      border: none;
      background: none;

      input {
        all: unset;
        width: 100%;
      }
    }
  }
}
