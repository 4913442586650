.anomaly-detection-dashboard-container {
  --base-padding: 36px;

  height: 100%;

  display: grid;
  grid-auto-columns: 1fr;
  grid-template-rows: 45px 1fr;

  .nav.toolbar {
    background-color: white;
    padding-left: var(--base-padding);
    padding-right: var(--base-padding);

    .updates-in-progress {
      font-size: 14px;
      font-weight: 500;
    }

    .process-changes-button {
      position: relative;

      .pending-changes-counter {
        color: white;
        background-color: var(--light-blue);

        position: absolute;
        right: -12px;
        top: -6px;

        // Reference: https://stackoverflow.com/questions/4861224/how-to-use-css-to-surround-a-number-with-a-circle/32587571#32587571
        display: inline-block;
        border-radius: 50%;
        font-size: 13px;
        min-width: 24px;

        &:before,
        &:after {
          content: '\200B';
          display: inline-block;
          line-height: 0px;
          padding-top: 50%;
          padding-bottom: 50%;
        }

        &:before {
          padding-left: 3px;
        }
        &:after {
          padding-right: 3px;
        }
      }
    }
  }

  .anomaly-list {
    min-width: min-content;
    overflow-y: auto;

    padding-left: var(--base-padding);
    padding-right: var(--base-padding);
    padding-top: 6px;
    padding-bottom: 40px;
  }
}

/**
 * Usage of this mixin ensures layout/spacing is the same for the column headers and the
 * contents of each Accordion's top-most "summary" line
 */
@mixin columnar-layout {
  display: grid;
  grid-template-columns: 70px minmax(300px, 1fr) repeat(4, 120px);
  grid-template-rows: 1fr;
}

.anomaly-list {
  --medium: 500;

  .column-headers {
    @include columnar-layout();

    color: var(--font-black);
    letter-spacing: 0.18px;
    font-weight: var(--medium);
    font-size: 16px;

    > div {
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
  }

  .accordion:not(:last-of-type) {
    margin-bottom: 15px;
  }

  .accordion {
    background: white;

    border: 1px solid var(--light-cool-gray);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;

    .top-line {
      min-height: 55px;

      @include columnar-layout();

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .actions {
        $button-width: 20px;
        $button-height: 20px;
        $button-border-radius: 4px;

        display: flex;

        @mixin palette-button {
          background-color: white;

          width: $button-width;
          height: $button-height;
          border-radius: $button-border-radius;

          display: flex;
          align-items: center;
          justify-content: center;

          transition: all 0.25s ease-in-out;
        }

        .actions-menu-button {
          margin-right: 0.25rem;

          @include palette-button();

          &.open {
            background-color: var(--medium-cool-gray);
            box-shadow: 0px 3px 6px #00000029;
          }

          &:hover {
            background-color: var(--light-cool-gray);
          }
        }

        .expand-button {
          border: 1px solid #707070;
          border-radius: $button-border-radius;
          box-shadow: 0px 3px 6px #00000029;

          @include palette-button();

          &.expanded {
            box-shadow: 3px 0px 6px #00000029;
            transform: rotate(90deg);
          }
        }

        .star-button {
          svg {
            font-size: 18px;
            color: white;

            stroke: black;
            stroke-opacity: 1;
            stroke-width: 16px;
          }
        }
      }

      .segment-list {
        justify-content: flex-start;

        .dim-value-label-text {
          font-size: 14px;
          font-weight: var(--medium);

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .score,
      .metric {
        font-size: 16px;
        font-weight: var(--medium);
      }

      .score {
        color: var(--error);
      }

      .metric {
        color: var(--font-black);
      }
    }

    --left-edge-padding: 1rem;
    .filters-area {
      background-color: #dff5f555;
      border-top: 1px solid var(--light-cool-gray);
      margin-bottom: 0.75rem;

      .filters-expansion-handle {
        padding-left: var(--left-edge-padding);

        .label {
          color: var(--medium-cool-gray);
          font-size: 0.875rem;
          font-weight: 700;
        }
      }

      .filters-full-list {
        font-size: 0.75rem;
        letter-spacing: 0.12px;
        color: var(--medium-cool-gray);
        padding-left: var(--left-edge-padding);
        padding-right: var(--left-edge-padding);
      }
    }

    .expanded-view-tabs {
      padding-left: var(--left-edge-padding);
      padding-right: var(--base-padding);

      border-bottom: 1px solid var(--font-gray);

      .nav-item {
        margin-right: 18px;
        letter-spacing: 0.18px;

        .nav-link {
          padding: 0.1rem 2px;
          padding-top: 0.1rem;

          font-size: 14px;
          color: var(--font-gray);

          &.active {
            color: var(--font-black);
            font-weight: 500;
            border-bottom: 3px solid var(--font-black);
          }

          .tab-icon {
            margin-right: 6px;
            margin-bottom: -0.5px;
          }
        }
      }
    }

    section.expanded-view {
      // Approximately "10 rows of data"
      --expanded-height: 500px;

      // The same height must be set on two different elements for browser animation performance
      min-height: var(--expanded-height);

      .anomaly-detail-table {
        height: var(--expanded-height);
        width: 100%;

        padding-bottom: 3px;

        .ag-row:not(.reviewed) {
          background-color: #f3f3f3;
          font-weight: bold;
        }

        .ag-row.reviewed {
          background-color: white;
          font-weight: normal;
        }

        .actions-palette {
          .ag-react-container {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            input[type='checkbox'] {
              transform: scale(1.18);
            }

            .icons {
              padding-left: 8px;
              margin-top: 1px;

              display: flex;
              align-items: center;

              &.disabled {
                opacity: 0.4;
              }

              button {
                outline: none;
                padding: 1px 3px;

                .reviewed-icon,
                .excluded-icon {
                  font-size: 16px;
                  color: transparent;

                  &.excluded {
                    color: var(--error);

                    path {
                      stroke: transparent;
                      stroke-width: 12px;
                    }
                  }

                  path {
                    stroke: var(--medium-cool-gray);
                    stroke-width: 22px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .graphs-view {
      // This value is hand-picked to ensure up to 6 "comparison rows" can be seen before scrolling kicks in
      max-height: 680px;

      .title {
        color: var(--font-gray);
      }

      .actuals-vs-expected-legend {
        font-size: 14px;
        color: var(--font-gray);

        display: flex;
        align-items: center;
      }

      .graph-container {
        border: 1px solid var(--light-cool-gray);
        border-radius: 8px;
        box-shadow: 0px 3px 6px #00000029;
      }
    }
  }
}

.refresh-results-tooltip {
  .tooltip-inner {
    min-width: 500px;
    padding-left: 12px;
    padding-right: 6px;
    text-align: left;
  }
}

.updates-in-progress-tooltip {
  .tooltip-inner {
    min-width: 350px;
    text-align: left;
  }
}
