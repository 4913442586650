.inventory-pane {
  flex-grow: 1;
  min-height: 0px;
  overflow-y: auto;
  overflow-x: hidden;

  .inventory-tab {
    width: 100%;
    min-height: 100%;
    padding-bottom: 1rem;

    display: flex;
    flex-direction: column;

    .inventory-card {
      position: relative;
      flex-grow: 1;
      min-height: 500px;
      padding: 0;
      display: flex;
      flex-direction: row;
      width: 100%;
      min-width: fit-content;

      .inventory-card-content {
        width: 100%;
        min-width: fit-content;
        min-height: 100%;
        display: flex;
        flex-direction: row;
        padding: 2rem;
        gap: 0.75rem;

        .inventory-products {
          display: flex;
          flex: 1;
          flex-direction: column;
          gap: 2rem;

          .charts-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 0.5rem;

            .pie-chart-container {
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              align-items: center;
            }

            .bar-chart-container {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
          }

          .legend-container {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            justify-content: center;
          }

          .tab-content {
            display: flex;
            flex-direction: column;
            gap: 1rem;
          }

          .nav-container {
            gap: 1rem;
            align-items: center;

            .nav-item {
              margin: 0;

              .nav-link {
                line-height: 1.2rem;
                padding-top: 0;
                padding-bottom: 0.2rem;
                font-size: 18px;

                &.active-a {
                  color: var(--medium-light-green);
                  border-bottom: 2px solid var(--medium-light-green);
                }

                &.active-b {
                  color: var(--light-blue);
                  border-bottom: 2px solid var(--light-blue);
                }

                &.active-c {
                  color: var(--medium-cool-gray);
                  border-bottom: 2px solid var(--medium-cool-gray);
                }

                &.inactive-a {
                  color: var(--medium-light-green);
                }

                &.inactive-b {
                  color: var(--light-blue);
                }

                &.inactive-c {
                  color: var(--medium-cool-gray);
                }
              }
            }
          }
        }
      }
    }
  }
}

.product-table-container {
  flex: 1;
  display: flex;
  width: 100%;
  min-height: 180px;
  border-radius: var(--bubble-border-radius);

  .ag-theme-alpine {
    flex-grow: 1;

    .price-rec-table {
      font-family: URWDIN;
      font-weight: 500;
    }
    .ag-header-cell {
      padding-left: 2px !important;
      padding-right: 2px !important;
    }
  }

  .dimension-values {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .center-text {
    text-align: center;
    padding: 0px !important;
  }
  .ag-row-odd {
    background-color: #f7f7f7 !important;
  }
  .ag-row {
    border-bottom: none !important;
  }
  .ag-root-wrapper {
    border-radius: 4px;
  }
  .ag-header-cell-label {
    justify-content: center;
  }
}

.header-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;

  span {
    white-space: normal;
    word-break: break-word;
    overflow-wrap: anywhere;
  }
}
