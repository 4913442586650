.impersonate-user {
  .select-spacing {
    padding-bottom: 30px;
  }

  .select-customer {
    text-align: left;
    width: 500px;
  }

  .select-user {
    text-align: left;
    width: 500px;
  }

  .error-message {
    color: red;
    font-style: italic;
    padding-top: 20px;
  }
}
