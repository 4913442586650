.manage-plan-container {
  width: 620px;
}

.manage-plan-container * {
  font-size: 14px;
}

.manage-plan-container .summary {
  margin-top: 10px;
}

.manage-plan-container .manage-plan-header {
  font-size: 24px;
  font-weight: 500;
  border-bottom: 1px solid #d9d9d9;
  width: fit-content;
  padding-bottom: 5px;
}

.manage-plan-container .profitrover-logo {
  width: 255px;
  padding-left: 25px;
}

.manage-plan-container .days-remaining-container {
  padding-left: 25px;
  margin-top: 12px;
}

.manage-plan-container .start-my-plan-banner-container {
  padding-left: 25px;
  padding-right: 11px;
}

.manage-plan-container .selected-plan {
  padding-left: 25px;
  font-size: 18px;
  font-weight: 500;
  color: var(--font-black);
  margin-top: 10px;
}

.manage-plan-container .pending-plan-change-container {
  margin: 0px 15px -10px 25px;
  border-bottom: 1px solid #d9d9d9;
  padding-top: 20px;
  padding-bottom: 10px;
}

.manage-plan-container .cancel-pending-plan-change-button {
  margin-top: 5px;
}

.manage-plan-container .days-remaining {
  font-size: 18px;
  color: var(--font-black);
}

.manage-plan-container .days-remaining-value {
  font-size: 18px;
  font-weight: 600;
  text-decoration: underline;
}

.manage-plan-container .end-trial-early-box {
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid var(--light-green-shadow);
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.manage-plan-container .end-trial-early-button {
  display: flex;
}

.manage-plan-container .unlock-text {
  font-size: 14px;
  color: var(--font-black);
  display: flex;
  align-items: center;
}

.manage-plan-container .plans-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.manage-plan-container .plans-container.pending-plan-change {
  pointer-events: none;
}

/* Customize the label (the container) */
.manage-plan-container .plans-container .container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  width: 50%;
}

/* Hide the browser's default radio button */
.manage-plan-container .plans-container .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.manage-plan-container .plans-container .checkmark {
  position: absolute;
  top: 64px;
  left: 0;
  height: 26px;
  width: 26px;
  background-color: #ffffff;
  border: 1px solid var(--light-cool-gray);
  border-radius: 50%;
  box-shadow: var(--large-container-box-shadow);
}

/* On mouse-over, add a grey background color */
.manage-plan-container .plans-container .container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.manage-plan-container .plans-container .container input:checked ~ .checkmark {
  background-color: #ffffff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.manage-plan-container .plans-container .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.manage-plan-container .plans-container .container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.manage-plan-container .plans-container .container .checkmark:after {
  top: 3px;
  left: 3px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: var(--light-blue);
}

.manage-plan-container .plans-container .plan-container {
  height: 160px;
  width: 100%;
  border-radius: 4px;
  box-shadow: var(--large-container-box-shadow);
  padding: 20px;
}

.manage-plan-container .plans-container .plan-title {
  font-size: 16px;
  font-weight: 500;
}

.manage-plan-container .plans-container .annual-plan-container {
  border: 3px solid var(--light-green-shadow);
}

.manage-plan-container .plans-container .annual-plan-container .price-tag-icon {
  height: 37px;
  position: absolute;
  top: -15px;
  right: -30px;
}

.manage-plan-container .plans-container .annual-plan-container .price-tag-icon.price-tag-icon-plan-change {
  top: 10px;
}

.modal-content .plans-container .annual-plan-container img.price-tag-icon {
  height: 37px;
  position: absolute;
  top: 150px;
  left: 235px;
}

.manage-plan-container .plans-container .monthly-plan-container {
  border: 3px solid var(--light-blue);
}

.manage-plan-container .plans-container .monthly-plan-container .plan-savings-container {
  padding-top: 5px;
}

.manage-plan-container .plans-container .plan-container .plan-name {
  font-size: 18px;
  font-weight: 500;
  color: #797979;
}

.manage-plan-container .plans-container .plan-container .plan-price {
  font-weight: 500;
  color: var(--font-black);
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 5px;
}

.manage-plan-container .plans-container .plan-container .plan-price .discount-price {
  text-decoration: line-through;
  margin-right: 4px;
}

.manage-plan-container .plans-container .plan-container .savings-per-year-text-container {
  margin-top: 5px;
  font-weight: 500;
  color: #fec52dfe;
  display: flex;
  align-items: center;
  justify-content: center;
}

.manage-plan-container .plans-container .plan-container .savings-per-year-text-container p {
  color: #000000;
  margin: 0px 5px;
}

.manage-plan-container .plans-container .plan-container .savings-per-location-text-container {
  color: var(--dark-green);
  font-size: 12px;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
}

.manage-plan-container .plans-container .monthly-plan-container .plan-savings-container {
  color: var(--font-black);
}

.manage-plan-container .react-toggle-track {
  width: 26px;
  height: 17px;
  border: 1px solid #ffffff;
  background-color: #bebebe;
  box-shadow: 0px 1px 2px #00000040;
}

.manage-plan-container .react-toggle--checked .react-toggle-track {
  background-color: #5fad5665;
}

.manage-plan-container .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #5fad5665;
}

.manage-plan-container .react-toggle-thumb {
  width: 15px;
  height: 15px;
  top: 1px;
  left: 0px;
  border: 1px solid #f0f0f0;
}

.manage-plan-container .react-toggle--checked .react-toggle-thumb {
  left: 11px;
  background-color: #5fad56;
}

.manage-plan-container .react-toggle--focus .react-toggle-thumb {
  box-shadow: none;
}

.manage-plan-container .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: none;
}

.manage-plan-container .react-toggle-track-check {
  display: none;
}

.manage-plan-container .react-toggle-track-x {
  display: none;
}

.manage-plan-container .plan-details {
  padding: 0px 30px;
}

.manage-plan-container .plan-details-title {
  font-size: 18px;
  font-weight: 500;
  color: var(--font-black);
  padding: 10px 0px;
}

.manage-plan-container .auto-renew-container {
  padding: 10px 0px;
}

.manage-plan-container .auto-renew-toggle {
  display: flex;
  align-items: center;
}

.manage-plan-container .auto-renew-text {
  color: var(--font-black);
  margin-right: 15px;
}

.manage-plan-container .auto-renew-details,
.manage-plan-container .auto-renew-contact-us {
  color: var(--font-gray);
}

.manage-plan-container .auto-renew-contact-us a {
  text-decoration: underline;
}

.manage-plan-container .plan-ends-container {
  display: flex;
}

.manage-plan-container .plan-ends-date {
  margin-left: 10px;
  color: var(--font-gray);
}

.manage-plan-modal {
  width: 100%;
}

.manage-plan-modal * {
  font-size: 14px;
}

.manage-plan-modal.confirmed {
  text-align: center;
}

.manage-plan-modal .modal-dialog {
  width: 584px;
  max-width: 584px;
}

.manage-plan-modal .modal-body {
  padding: 0px 20px 20px 20px;
}

.manage-plan-modal .modal-title {
  font-size: 24px;
  font-weight: 500;
  color: var(--font-black);
  margin-bottom: 2rem;
}

.manage-plan-modal .modal-header {
  border-bottom: none;
  padding: 20px 30px 5px 30px;
}

.manage-plan-modal .modal-scheduled-date {
  font-size: 18px;
  color: var(--font-black);
  margin-bottom: 20px;
}

.manage-plan-modal .modal-scheduled-date-trial {
  padding-left: 10px;
  color: var(--font-black);
}

.manage-plan-modal .modal-scheduled-date-trial p,
.manage-plan-modal .modal-scheduled-date-trial b {
  font-size: 18px;
}

.manage-plan-modal .plans-container {
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
}

.manage-plan-modal .plan-card {
  width: 225px;
}

.manage-plan-modal .plan-card .monthly-plan-container {
  border-color: var(--light-blue);
}

.manage-plan-modal .plan-card-title {
  color: var(--font-gray);
  font-weight: 500;
  font-size: 18px;
}

.manage-plan-modal .plan-arrow {
  font-size: 20px;
}

.manage-plan-modal .modal-details-text {
  padding: 12px 0px 0px 0px;
  color: var(--font-black);
}

.manage-plan-modal .modal-footer {
  background-color: var(--background-gray);
  border-top: none;
}

.plan-switch-confirmed-modal .modal-dialog {
  min-width: 584px;
}

.plan-switch-confirmed-modal .modal-body {
  display: flex;
  justify-content: center;
  height: 294px;
}

.plan-switch-confirmed-modal .modal-header {
  border-bottom: none;
  display: flex;
  justify-content: center;
}

.plan-switch-cancel-modal * {
  font-size: 14px;
}

.plan-switch-cancel-modal .profitrover-modal-header,
.manage-plan-modal .profitrover-modal-header {
  padding: 36px 36px 0px 36px;
}

.plan-switch-cancel-modal .profitrover-modal-title,
.manage-plan-modal .profitrover-modal-title {
  font-size: 24px;
  color: var(--font-black);
}

.plan-switch-cancel-modal .profitrover-modal-main,
.manage-plan-modal .profitrover-modal-main {
  padding: 18px 36px 24px 36px;
  color: var(--font-black);
}

.plan-switch-cancel-modal .profitrover-modal-footer,
.manage-plan-modal .profitrover-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.auto-renew-modal * {
  color: var(--font-black);
  font-weight: 500;
}

.auto-renew-modal .profitrover-modal-header {
  padding: 30px 30px 0px 30px;
}

.auto-renew-modal .profitrover-modal-title {
  font-size: 24px;
}

.auto-renew-modal .profitrover-modal-main {
  padding-top: 20px;
  font-size: 14px;
}

.auto-renew-modal .profitrover-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.auto-renew-modal .submit-error {
  color: #c44011;
}

.toggle-auto-renew-disabled-tooltip .tooltip-inner {
  text-align: start;
  min-width: 600px;
}
