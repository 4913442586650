.checkout-header-bar {
  background: var(--mid-green);
  width: 100vw;
  padding-top: 10px;
  padding-bottom: 10px;
}

.checkout-header-bar .header-container {
  background: var(--mid-green);
  padding-top: 0px !important;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.checkout-header-bar .header-container.end-trial-early-header {
  max-width: 950px;
}

.checkout-header-bar .profitrover-apps-header-logo {
  width: 300px;
}

.checkout-header-bar .checkout-text {
  color: #ffffff;
  font-size: 18px;
  margin-left: 20px;
}

.checkout-subscription-changes {
  min-height: 100vh;
  width: 100vw;

  padding-top: 25px;
  padding-left: max(15vw, 60px);
  padding-right: max(15vw, 60px);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkout-subscription-changes .section-layout,
.checkout-subscription-changes .page-title {
  width: 100%;
  max-width: 1200px;
}

.checkout-subscription-changes .page-title {
  align-self: flex-start;
  margin-left: auto;
  margin-right: auto;
}

.page-title .page-title-box {
  border-bottom: 1px solid #d9d9d9;
  box-shadow: 0px 3px 6px -5px #00000029;
}

.checkout-subscription-changes .section-layout {
  padding-top: 30px;
  width: 100%;

  display: grid;
  grid-template-rows: auto;
  grid-template-columns: minmax(420px, 480px) minmax(350px, 450px);
  grid-row-gap: 18px;
  grid-column-gap: 25px;
  grid-template-areas:
    'bubble bubble'
    'expl expl';

  font-weight: 500;
}

.checkout-subscription-changes .payment-failed-message {
  color: var(--error);

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  margin-bottom: 20px;
}

.checkout-subscription-changes .payment-failed-notice {
  font-size: 18px;
}

.checkout-subscription-changes .payment-failed-message .link-text {
  color: var(--light-blue);
  text-decoration: underline;
}

.checkout-subscription-changes .payment-failed-message .confirm-payment-method-text {
  font-size: 14px;
}

.checkout-subscription-changes .fa-lock {
  margin-right: 8px;
  font-size: 20px;
}

.checkout-subscription-changes button .fa-lock {
  margin-right: 6px;
  margin-bottom: 1px;
}

.checkout-subscription-changes .labeled-bubble-container {
  grid-area: auto / auto / span 1 / span 1;
}

.checkout-subscription-changes .labeled-bubble-container .left-main {
  padding-left: 61px;
  padding-right: 61px;
}

.checkout-subscription-changes .labeled-bubble-container .payment-method-section {
  background: #f8f8f8;
  padding: 13px 14px;
  margin-top: 13px;

  font-size: 14px;
  color: var(--font-gray);

  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}

.checkout-subscription-changes .labeled-bubble-container .payment-method-section.missing-payment {
  justify-content: center !important;
}

.checkout-subscription-changes .labeled-bubble-container .payment-method-section.payment-failed {
  border: 1px solid var(--error);
  box-shadow: 0px 3px 6px #b3270f98;
}

.checkout-subscription-changes .labeled-bubble-container .payment-method-section .payment-method-add-text {
  font-size: 16px;
  color: var(--light-blue);
  text-decoration: underline;
  font-weight: bold;
  outline: unset;
}

.checkout-subscription-changes .labeled-bubble-container .change-payment-method-text {
  color: var(--light-blue);
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 16px;
  outline: unset;
}

.checkout-subscription-changes .labeled-bubble-container .right-main {
  padding-left: 33px;
  padding-right: 45px;
}

.checkout-subscription-changes .labeled-bubble-container .right-main p {
  font-size: 14px;
}

.checkout-subscription-changes .labeled-bubble-container .right-main .line-item-changes {
  border-left: 2px solid var(--mid-green);

  margin-left: -10px;
  padding-left: 8px; /* padding = margin + border-width */
}

.checkout-subscription-changes .labeled-bubble-container .right-main .plus-tax-text {
  font-size: 12px;
  position: relative;
  left: 0px;
}

.checkout-subscription-changes .ssl-text {
  font-weight: 400;
}

.checkout-subscription-changes .labeled-bubble-container .locations-count:first-of-type {
  margin-top: 16px;
}

.checkout-subscription-changes .labeled-bubble-container .locations-count {
  margin-bottom: 0px;
  font-size: 14px;
}

.checkout-subscription-changes .labeled-bubble-container .billing-per-location:first-of-type {
  margin-top: 20px;
}

.checkout-subscription-changes .labeled-bubble-container .billing-per-location {
  margin-bottom: 0px;
  font-size: 14px;
  color: var(--font-gray);
}

.checkout-subscription-changes .labeled-bubble-container p {
  font-size: 12px;
}

.checkout-subscription-changes .labeled-bubble-container .prorated-period,
.checkout-subscription-changes .labeled-bubble-container .sales-tax,
.checkout-subscription-changes .labeled-bubble-container .next-billing-details,
.checkout-subscription-changes .labeled-bubble-container .right-main .plus-tax-text {
  color: var(--font-gray);
}

.checkout-subscription-changes .labeled-bubble-container .padded-container {
  padding-left: 12px;
  padding-right: 12px;
}

.checkout-subscription-changes .labeled-bubble-container hr {
  margin: 5px 0px;
}

.checkout-subscription-changes .labeled-bubble-container .next-billing-details .next-billing-amount {
  color: black;
}

.checkout-subscription-changes .labeled-bubble-container .next-billing-details .discount-price {
  text-decoration: line-through;
  margin-right: 8px;
}

.checkout-subscription-changes .labeled-bubble-container .right-main .line-item-changes {
  border-left: 2px solid var(--mid-green);

  margin-left: -10px;
  padding-left: 8px; /* padding = margin + border-width */
}
.checkout-subscription-changes .labeled-bubble-container .right-main .line-item-free {
  font-size: 14px;
}
.checkout-subscription-changes .labeled-bubble-container .right-main .plus-tax-text {
  font-size: 12px;
  position: relative;
  left: 0px;
}

.checkout-subscription-changes .labeled-bubble-container .next-billing-details .right-main .change-in-billing-amount p {
  font-size: 12px;
}

.checkout-subscription-changes .labeled-bubble-container .emphasis-area {
  border: 2px solid var(--light-green);
  border-radius: 4px;
  box-shadow: 0px 3px 6px #00000029;

  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.checkout-subscription-changes .labeled-bubble-container .emphasis-area > * {
  flex: 1;
}

.checkout-subscription-changes .labeled-bubble-container .emphasis-area h5 {
  margin-bottom: 0px;
}

.explanatory-text-container {
  grid-area: expl;

  font-size: 14px;
}

.explanatory-text-container p {
  margin-bottom: 25px;
}

.subscription-changes-modal .modal-content {
  min-width: 600px;
}

.subscription-changes-modal .modal-content .modal-title {
  margin-bottom: 10px;
}

.checkout-subscription-changes.monthly-to-annual .credit-applied {
  color: #5fad56;
}

.checkout-subscription-changes.monthly-to-annual .due-today {
  border-top: 1px solid #d9d9d9;
  padding-top: 3px;
  margin-top: 3px;
}

.checkout-subscription-changes.monthly-to-annual .due-today p {
  font-size: 18px;
}

.checkout-subscription-changes.monthly-to-annual .credit-applied-left {
  display: flex;
}

.checkout-subscription-changes.monthly-to-annual .credit-applied-left .credit-tooltip-icon {
  color: #ffffff;
  background-color: #000000;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  font-size: 8px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.tooltip.show.credit-applied-tooltip {
  opacity: 1;
  margin-top: 15px;
}

.credit-applied-tooltip .arrow {
  margin-left: 5px;
}

.credit-applied-tooltip .arrow:before {
  border-bottom-color: #bebebe;
}

.credit-applied-tooltip .arrow:after {
  content: '';
  position: absolute;
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  transform: translateY(1px);
  border-color: transparent;
  border-style: solid;
  border-bottom-color: var(--background-gray);
}

.credit-applied-tooltip .tooltip-inner {
  background-color: var(--background-gray);
  color: var(--font-black);
  font-size: 14px;
  line-height: 18px;
  border: 1px solid #bebebe;
  max-width: 360px;
}

.profitrover-modal-main .order-details-container {
  padding: 0px;
}

.checkout-subscription-changes .order-details-content-container {
  border: none;
}

.checkout-subscription-changes .order-details-savings-container {
  background-color: var(--background-gray);
  border: none;
}

.order-details-container .billing-frequency-container {
  padding: 0px;
}

.checkout-subscription-changes .order-details-container .plan-container .row {
  padding-top: 0px;
}

.checkout-subscription-changes .savings-per-location-text {
  font-size: 12px;
  font-style: italic;
  text-align: center;
  color: var(--dark-green);
}

.checkout-subscription-changes.monthly-to-annual .explanatory-text-container {
  margin-top: 40px;
}

.payment-success-message {
  font-weight: 500;
}

.payment-success-message .payment-success-title {
  margin-bottom: 15px;
}

.payment-success-message .success-item {
  display: flex;
  margin-bottom: 8px;
  font-size: 14px;
}

.payment-success-message .success-item-label {
  flex: 1;
  margin-right: 20px;
  color: var(--font-black);
}

.payment-success-message .success-item-value {
  flex: 2;
  color: var(--font-gray);
}

.payment-success-message .bottom-note {
  font-size: 14px;
  color: var(--font-gray);
}

.payment-success-message .bottom-note .payment-email {
  word-break: break-all;
  color: var(--font-black);
}
