input.create-dim-name-input {
  width: 100%;
  border-radius: 4px;
  outline: none;
  border: 1px solid lightgray;
  border-width: 1px;
}

.create-dim-button {
  margin-top: 10px;
  width: 200px;
}

.create-dim-text:hover {
  text-decoration: underline;
}

h3.dim-settings-header {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.dim-settings-table-area {
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.create-dim-selection-area {
  flex: 1;
  margin-bottom: 10px;
  padding: 5px;
}
